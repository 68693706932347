<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      {{ selected }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="
          // selected = option;
          open = false;
          $emit('InputSelect', option);
        "
      >
        {{ option }}
      </div>
    </div>
    <button @click.prevent="open = !open">
      <svg
        width="12"
        height="7"
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.09149 6.99976C5.85876 6.99976 5.62604 6.90176 5.44876 6.70676L0.90331 1.70676C0.547855 1.31576 0.547855 0.683762 0.90331 0.292762C1.25876 -0.0982383 1.83331 -0.0982383 2.18876 0.292762L6.09149 4.58576L9.99422 0.292762C10.3497 -0.0982383 10.9242 -0.0982383 11.2797 0.292762C11.6351 0.683762 11.6351 1.31576 11.2797 1.70676L6.73422 6.70676C6.55695 6.90176 6.32422 6.99976 6.09149 6.99976Z"
          fill="#6D6D6D"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import { waitListStoreFront } from "@/services/waitListStoreFront";
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      store: waitListStoreFront(),
      // selected: this.default
      //   ? this.default
      //   : this.options.length > 0
      //   ? this.options[0]
      //   : null,
      open: false,
    };
  },
  computed: {
    selected() {
      return `Inline ${this.store.NotifyBtnProductPageInline.slice(6)}`;
    },
  },
  mounted() {
    this.$emit("InputSelect", this.selected);
  },
};
</script>

<style scoped>
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 45px;
  line-height: 45px;
}

.custom-select .selected {
  background-color: #fbfbfb;
  border-radius: 6px;
  border: 1px solid #d8d8d8;
  color: #202223;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .selected.open {
  border: 1px solid #d8d8d8;
  border-radius: 6px 6px 0px 0px;
}

.custom-select .items {
  /* color: #fff; */
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  border-right: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  position: absolute;
  background-color: #fbfbfb;
  left: 0;
  right: 0;
  z-index: 1;
}

.custom-select .items div {
  /* color: #fff; */
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #ccc;
}

.selectHide {
  display: none;
}

button {
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 42px;
  height: 43px;
  border-left: 1px solid #d8d8d8;
  border-radius: 0px 8px 8px 0px;
  outline: none;
}
</style>
