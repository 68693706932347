<template>
  <div>
    <div class="body">
      <div class="left-content">
        <div class="border">
          <div v-if="currentMode === 'notification-email'" class="header">
            <h6>Message after subscribing</h6>
            <p>
              Notification email is sent to users when products back in-stock.
            </p>
          </div>
          <div v-else class="header">
            <h6>Message of Subscription Success</h6>
            <p>Subscription message is sent to users after they subscribe.</p>
          </div>

          <hr />

          <h4 class="accordion-header">Email Header:</h4>
          <hr />
          <base-accordion>
            <template #title>
              <span>Store Logo</span>
            </template>
            <template #content>
              <div
                style="z-index: 232323"
                class="content-logo"
                @dragenter.prevent
                @dragleave.prevent
                @dragover.prevent
                @drop.prevent="pickFile"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20ZM10.5858 16.5858L18.5858 8.58579C19.3668 7.80474 20.6332 7.80474 21.4142 8.58579L29.4142 16.5858C30.1953 17.3668 30.1953 18.6332 29.4142 19.4142C28.6332 20.1953 27.3668 20.1953 26.5858 19.4142L22 14.8284V30C22 31.1046 21.1046 32 20 32C18.8954 32 18 31.1046 18 30V14.8284L13.4142 19.4142C12.6332 20.1953 11.3668 20.1953 10.5858 19.4142C9.80474 18.6332 9.80474 17.3668 10.5858 16.5858Z"
                    fill="#1575BF"
                  />
                </svg>
                <div class="upload-actions" style="display: flex; gap: 20px">
                  <label for="file-upload" class="upload-btn">
                    <input
                      ref="imgLogo"
                      required
                      accept="image/png, image/gif, image/jpeg"
                      id="file-upload"
                      style="display: none"
                      type="file"
                      @input="pickFile"
                      @change="formTouched"
                    />
                    Add file
                  </label>
                  <base-button
                    @click="cancelUploadImage"
                    style="background-color: lightcoral"
                    >Cancel</base-button
                  >
                  <base-button
                    @click="confirmUploadImage"
                    style="background-color: #1575bf"
                    >Upload</base-button
                  >
                </div>
                <p v-if="largeImage" style="font-size: 16px; color: red">
                  image size has to be less than 500 kb
                </p>
                <p v-if="previewImage">{{ imageName }}</p>
                <p v-else style="font-size: 16px">
                  File has to be .jpg, .jpeg, .png, .gif
                </p>
                <p>or drop files to upload</p>
                <p v-if="imageUploading" class="loading">
                  <span style="color: #1575bf">
                    Please wait image is uploading
                  </span>
                </p>
                <p v-if="imageError">
                  <span style="color: lightcoral">
                    Please Upload the file again.
                  </span>
                </p>
              </div>
            </template>
          </base-accordion>

          <hr />
          <hr />
          <hr />
          <hr />

          <h4 class="accordion-header">Email Body:</h4>
          <hr />
          <div
            style="
              padding: 20px;
              display: flex;
              flex-direction: column;
              gap: 5px;
            "
          >
            <h4>Select body editing option:</h4>
            <Dropdown
              optionLabel="label"
              option-value="value"
              v-model="currentMode"
              :options="editModes"
              style="width: 100%"
            />
          </div>
          <hr />
          <template v-if="currentMode === 'notification-email'">
            <base-accordion>
              <template #title>
                <span>Color Styles</span>
              </template>
              <template #content>
                <div class="content-color-styles">
                  <color-input
                    v-model="primaryColor"
                    @input-changed="formTouched"
                    >Button color</color-input
                  >
                </div>
              </template>
            </base-accordion>
            <hr />
            <base-accordion>
              <template #title>
                <span>Message Styles</span>
              </template>
              <template #content>
                <div class="message-style-content">
                  <label-input
                    v-model="instockMailSubject"
                    @input-changed="formTouched"
                    >Instock Mail Subject</label-input
                  >
                  <div class="email-desc">
                    <label for="email-desc">Email description</label>
                    <textarea
                      @change="formTouched"
                      v-model="emailDescription"
                      name="email-desc"
                      id="email-desc"
                      cols="10"
                      rows="10"
                      maxlength="90"
                    ></textarea>
                  </div>
                  <div class="checkbox-container">
                    <input
                      @change="formTouched"
                      type="checkbox"
                      id="discount-code"
                      v-model="discountCodeCheck"
                    />
                    <label for="discount-code">Discount code</label>
                  </div>
                  <label-input
                    v-model="discountDescription"
                    @input-changed="formTouched"
                    >Discount description</label-input
                  >
                  <div class="checkbox-container">
                    <input
                      @change="formTouched"
                      type="checkbox"
                      id="product-price"
                      v-model="productPriceCheck"
                    />
                    <label for="product-price">Product price</label>
                  </div>
                </div>
              </template>
            </base-accordion>
            <hr />
            <base-accordion>
              <template #title>
                <span>Content Styles</span>
              </template>
              <template #content>
                <div class="content-styles grid_2x2">
                  <label-input
                    v-model="selectedFontFamily"
                    @input-changed="formTouched"
                    >Font Family</label-input
                  >
                  <div>
                    <pixels-input
                      maxValue="40"
                      minValue="0"
                      v-model="emailTitleFontSize"
                      @input-changed="formTouched"
                      >Email title font size</pixels-input
                    >
                    <p
                      v-if="emailTitleFontSize === '40'"
                      style="color: #6d6d6d"
                    >
                      max value is "40"
                    </p>
                    <p v-if="emailTitleFontSize === '0'" style="color: #6d6d6d">
                      min value is "0"
                    </p>
                  </div>
                  <div>
                    <pixels-input
                      maxValue="30"
                      minValue="0"
                      v-model="emailDescriptionFontSize"
                      @input-changed="formTouched"
                      >Email description font size</pixels-input
                    >
                    <p
                      v-if="emailDescriptionFontSize === '30'"
                      style="color: #6d6d6d"
                    >
                      max value is "30"
                    </p>
                    <p
                      v-if="emailDescriptionFontSize === '0'"
                      style="color: #6d6d6d"
                    >
                      min value is "0"
                    </p>
                  </div>
                  <div>
                    <pixels-input
                      maxValue="30"
                      minValue="0"
                      v-model="discountFontSize"
                      @input-changed="formTouched"
                      >Discount font size</pixels-input
                    >
                    <p v-if="discountFontSize === '30'" style="color: #6d6d6d">
                      max value is "30"
                    </p>
                    <p v-if="discountFontSize === '0'" style="color: #6d6d6d">
                      min value is "0"
                    </p>
                  </div>
                </div>
              </template>
            </base-accordion>
            <hr />
            <base-accordion>
              <template #title>
                <span>Buttons</span>
              </template>
              <template #content>
                <div class="content-buttons">
                  <label-input
                    v-model="visitProductLabel"
                    @input-changed="formTouched"
                    >"Visit product" label</label-input
                  >
                </div>
              </template>
            </base-accordion>
          </template>

          <template v-else>
            <base-accordion>
              <template #title><span>Success Subscription Mail</span></template>
              <template #content>
                <div class="success-mail-section-content">
                  <label-input
                    @input-changed="formTouched"
                    maxLength="100"
                    v-model="subject"
                    >Subject</label-input
                  >
                  <div>
                    <label for="mess-code">Message</label>
                    <textarea
                      @change="formTouched"
                      v-model="message"
                      name="mess-code"
                      id="mess-code"
                      cols="10"
                      rows="10"
                    ></textarea>
                  </div>
                </div>
              </template>
            </base-accordion>
          </template>

          <hr />
          <hr />
          <hr />
          <hr />

          <h4 class="accordion-header">Email Footer:</h4>
          <hr />
          <base-accordion>
            <template #title>
              <span>Social Networks</span>
            </template>
            <template #content>
              <div class="content-social">
                <label-input
                  v-model="socialNetworkTitle"
                  @input-changed="formTouched"
                  >Social networks title</label-input
                >
                <div class="grid_2x2">
                  <label-input
                    maxLength="1000"
                    v-model="instagram"
                    @input-changed="formTouched"
                    >instagram</label-input
                  >
                  <label-input
                    v-model="twitter"
                    @input-changed="formTouched"
                    maxLength="1000"
                    >twitter</label-input
                  >
                  <label-input
                    v-model="youtube"
                    @input-changed="formTouched"
                    maxLength="1000"
                    >youtube</label-input
                  >
                  <label-input
                    v-model="facebook"
                    @input-changed="formTouched"
                    maxLength="1000"
                    >facebook</label-input
                  >
                  <label-input
                    v-model="telegram"
                    @input-changed="formTouched"
                    maxLength="1000"
                    >telegram</label-input
                  >
                  <label-input
                    v-model="linkedin"
                    @input-changed="formTouched"
                    maxLength="1000"
                    >linkedin</label-input
                  >
                  <label-input
                    v-model="pinterest"
                    @input-changed="formTouched"
                    maxLength="1000"
                    >pinterest</label-input
                  >
                </div>
              </div>
            </template>
          </base-accordion>
        </div>

        <div class="actions">
          <base-button
            class="flat red-text small"
            type="reset"
            @click="resetEmailTemplate"
          >
            Reset
          </base-button>
          <base-button
            @click="showTestEmailModal"
            class="flat small"
            style="color: #202223"
            >Send Test Email</base-button
          >
          <base-button
            class="small"
            @click="saveEmailTemplate"
            :disabled="imageUploading"
            :class="imageUploading && 'image-upload'"
            >Save</base-button
          >
        </div>
      </div>
      <div class="right-content" :style="{ fontFamily: selectedFontFamily }">
        <div
          style="
            border: 1px solid #d8d8d8;
            border-radius: 12px;
            overflow: hidden;
            padding: 35px;
            padding-left: 32px;
            padding-bottom: 34px;
            display: flex;
            flex-direction: column;
            align-items: center;
          "
        >
          <div class="logo-preview">
            <p v-if="!previewImage">Store logo</p>
            <div
              v-else
              class="imagePreviewWrapper"
              :style="{ 'background-image': `url(${previewImage})` }"
            ></div>
          </div>

          <div
            v-if="currentMode === 'notification-email'"
            class="notification-email"
          >
            <div style="width: 100%; margin-bottom: 35px">
              <h4
                class="mail-subject"
                :style="{ fontSize: emailTitleFontSize + 'px' }"
              >
                {{ instockMailSubject }}
              </h4>
              <p
                class="get-it"
                :style="{ fontSize: emailDescriptionFontSize + 'px' }"
              >
                {{ emailDescription }}
              </p>
              <p
                v-if="discountCodeCheck"
                :style="{ fontSize: discountFontSize + 'px' }"
              >
                {{ discountDescription }}
              </p>
            </div>

            <div class="blue-shoe">
              <img src="../../../assets/images/blue-shoe.png" alt="blue shoe" />
            </div>
            <div class="product-details">
              <p>{product name}</p>
              <p v-if="productPriceCheck">$120</p>
            </div>
            <button
              class="btn-visit"
              :style="{ backgroundColor: primaryColor }"
            >
              {{ visitProductLabel }}
            </button>
          </div>

          <div v-else class="success-sub">
            <h4>Welcome to the {store_name},</h4>
            <p>
              {{ successSubMessage }}
            </p>
          </div>

          <div class="social-media">
            <p>{{ socialNetworkTitle }}</p>
            <p
              v-if="
                !instagram.trim() &&
                !twitter.trim() &&
                !youtube.trim() &&
                !facebook.trim() &&
                !telegram.trim() &&
                !linkedin.trim() &&
                !pinterest.trim()
              "
              style="text-align: center; width: 200px; color: royalblue"
            >
              Please add links in social media sections to make icons appear
              here
            </p>
            <div v-else class="social-icons">
              <img
                v-if="instagram.trim()"
                src="@/assets/images/apps/notification_email/Instagram.png"
                alt="instagram logo"
              />
              <img
                v-if="twitter.trim()"
                src="@/assets/images/apps/notification_email/Twitter.png"
                alt="twitter logo"
              />
              <img
                v-if="youtube.trim()"
                src="@/assets/images/apps/notification_email/Youtube.png"
                alt="youtube logo"
              />
              <img
                v-if="facebook.trim()"
                src="https://media.publit.io/file/facebook-p5.png"
                alt="youtube logo"
                style="padding: 10px"
              />
              <img
                v-if="pinterest.trim()"
                src="https://media.publit.io/file/pinterest-N.png"
                alt="youtube logo"
                style="padding: 12px"
              />
              <img
                v-if="linkedin.trim()"
                src="https://media.publit.io/file/linkedin-p.png"
                alt="youtube logo"
                style="padding: 12px"
              />
              <img
                v-if="telegram.trim()"
                src="https://media.publit.io/file/telegram-T.png"
                alt="youtube logo"
                style="padding: 12px"
              />
            </div>
          </div>

          <p class="footer">
            You are receiving this email because you requested a back in stock
            notification on {store_name}.
          </p>
        </div>
      </div>
    </div>
    <!-- Send Test Email Modal -->
    <send-test-email-modal
      v-if="testEmailModalVisible"
      @hide-modal="hideTestEmailModal"
      @send-email="submitTestEmailData"
    ></send-test-email-modal>
    <!-- Alert Message -->
    <transition name="fade">
      <success-message v-show="showAlert"> Done! </success-message>
    </transition>
  </div>
</template>

<script>
let headersV = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${sessionStorage.getItem("auth_waitlist")}`,
};
import BaseAccordion from "@/components/UI/BaseAccordion.vue";
import SendTestEmailModal from "@/components/SendTestEmailModal.vue";
import Dropdown from "primevue/dropdown";

export default {
  props: [
    "htmlCode",
    "resetEmailTemplate",
    "saveEmailTemplate",
    "successSubMessage",
    "successSubSubject",
  ],
  emits: ["unsavedChanges", "htmlTabUpdate", "input", "inputUpdated"],
  mounted() {
    this.getLogoData();
  },
  watch: {
    htmlCode(value) {
      if (value) {
        this.syncData(value);
      }
    },
    // Next functions send data up to parent whenever a variable change
    primaryColor(value) {
      this.dataUpdated(value, "buttonColor");
    },
    instockMailSubject(value) {
      this.dataUpdated(value, "instockMailSubject");
    },
    emailDescription(value) {
      this.dataUpdated(value, "emailDescription");
    },
    selectedFontFamily(value) {
      this.dataUpdated(value, "fontFamily");
    },
    emailTitleFontSize(value) {
      this.dataUpdated(value, "emailTitleFontSize");
    },
    emailDescriptionFontSize(value) {
      this.dataUpdated(value, "emailDescriptionFontSize");
    },
    visitProductLabel(value) {
      this.dataUpdated(value, "visitProductLabel");
    },
    socialNetworkTitle(value) {
      this.dataUpdated(value, "socialNetworkTitle");
    },
    instagram(value) {
      this.dataUpdated(value, "instagram");
    },
    youtube(value) {
      this.dataUpdated(value, "youtube");
    },
    twitter(value) {
      this.dataUpdated(value, "twitter");
    },
    telegram(value) {
      this.dataUpdated(value, "telegram");
    },
    facebook(value) {
      this.dataUpdated(value, "facebook");
    },
    linkedin(value) {
      this.dataUpdated(value, "linkedin");
    },
    pinterest(value) {
      this.dataUpdated(value, "pinterest");
    },
    productPriceCheck(value) {
      this.dataUpdated(value, "productPriceVisible");
    },
    discountCodeCheck(value) {
      this.dataUpdated(value, "discountCodeVisible");
    },
    discountDescription(value) {
      this.dataUpdated(value, "discountDescription");
    },
    discountFontSize(value) {
      this.dataUpdated(value, "discountFontSize");
    },
    successSubMessage(value) {
      this.message = value;
    },
    successSubSubject(value) {
      this.subject = value;
    },
    message(value) {
      this.dataUpdated(value, "succesSubscribtionMessage");
    },
    subject(value) {
      this.dataUpdated(value, "succesSubscribtionSubject");
    },
  },
  data() {
    return {
      // this modes are editing modes whether the user can edit notification email or succes sub. email
      currentMode: "notification-email",
      editModes: [
        { label: "Notification Email", value: "notification-email" },
        { label: "Success Subscription", value: "success-subscription" },
      ],
      headers: headersV,
      imageUploading: false,
      largeImage: false,
      imageError: false,
      previewImage: null,
      imageName: null,

      //General data
      selectedFontFamily: "Montserrat",
      // Button data
      primaryColor: "#1575BF",
      visitProductLabel: "View item",
      // title and descritpion data
      instockMailSubject: "These items are available now!",
      emailDescription: "Get it now before it goes out of stock again!",
      emailTitleFontSize: 20,
      emailDescriptionFontSize: 16,
      //This data will be handled in the future ISA
      discountDescription: "Use code 1234 for 10% OFF discount",
      discountCodeCheck: false,
      productPriceCheck: true,
      discountFontSize: 16,
      //Social media data
      socialNetworkTitle: "Follow us on:",
      instagram: "",
      twitter: "",
      youtube: "",
      telegram: "",
      facebook: "",
      linkedin: "",
      pinterest: "",

      //succes sucbsribtion email
      message: "",
      subject: "",

      testEmailModalVisible: false,
      showAlert: false,
    };
  },

  methods: {
    showTestEmailModal() {
      this.testEmailModalVisible = true;
    },
    hideTestEmailModal() {
      this.testEmailModalVisible = false;
    },
    submitTestEmailData() {
      this.showAlert = true;
      setTimeout(this.hideAlert, 1500);
      this.hideTestEmailModal();
    },
    hideAlert() {
      this.showAlert = false;
    },
    formTouched() {
      this.$emit("unsavedChanges", true);
    },
    dataUpdated(value, inputName) {
      this.$emit("inputUpdated", value, inputName);
    },
    pickFile(event) {
      event.preventDefault();
      this.largeImage = false;
      this.imageError = false;
      let file = null;

      const files = event.dataTransfer?.files || event.currentTarget.files;

      if (files.length > 0) {
        file = files;
      } else {
        this.imageError = true;
        this.imageUploading = false;
        return;
      }

      if (this.imageUploading) {
        return;
      }

      let imageFileRegex = new RegExp(
        /[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/
      );

      // formats guard
      if (!imageFileRegex.test(file[0]?.name)) {
        return;
      }

      // size guard
      if (file[0]?.size > 500000) {
        this.largeImage = true;
        return;
      }
      if (file && file.length > 0 && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.imageName = file[0].name;
      }
    },
    confirmUploadImage() {
      if (this.previewImage && this.imageName) {
        this.imageUploading = true;
        //Upload file to database after the file is loaded
        fetch("https://waitlist-api.numinix.com/api/upload-logo", {
          method: "POST",
          headers: headersV,
          body: JSON.stringify({
            logo_image: this.previewImage,
            image_name: this.imageName,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            this.imageUploading = false;
            if (data.status === "success") {
              // alert("image uploaded successfully");
            } else {
              this.cancelUploadImage();
              console.log(data);
              this.imageError = true;
              console.log("Error");
            }
          })
          .catch((err) => console.log(err));
      } else {
        this.cancelUploadImage();
        this.imageError = true;
      }
    },
    cancelUploadImage() {
      if (this.imageName) {
        this.imageUploading = false;
        this.largeImage = false;
        this.imageError = false;
        this.previewImage = null;
        this.imageName = null;
      }
      this.getLogoData();
    },
    getLogoData() {
      fetch("https://waitlist-api.numinix.com/api/call-logo", {
        method: "get",
        headers: headersV,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            this.previewImage = data.payload;
          }
        });
    },
    syncData(value) {
      //a function responsible for extract data from backend html template and assign it to customize tab
      let parser = new DOMParser();
      let dom = parser.parseFromString(value, "text/html");

      if (dom.querySelector("#btn-visit")) {
        this.primaryColor = this.rgbToHex(
          dom.querySelector("#btn-visit").style.backgroundColor
        );
        this.visitProductLabel = dom
          .querySelector("#btn-visit")
          .textContent.trim();
      }
      if (dom.querySelector("#body")) {
        this.selectedFontFamily = dom.querySelector("#body").style.fontFamily;
      }
      if (dom.querySelector("#title")) {
        this.instockMailSubject = dom
          .querySelector("#title")
          .querySelector("h4")
          .textContent.trim();
        this.emailDescription = dom
          .querySelector("#title")
          .querySelector("p")
          .textContent.trim();
        this.emailTitleFontSize = this.pxToString(
          dom.querySelector("#title").querySelector("h4").style.fontSize
        );
        this.emailDescriptionFontSize = this.pxToString(
          dom.querySelector("#title").querySelector("p").style.fontSize
        );
      }
      if (dom.querySelector("#footer")) {
        this.socialNetworkTitle = dom
          .querySelector("#footer")
          .querySelector("p")
          .textContent.trim();
      }
      if (dom.querySelector("#youtube")) {
        this.youtube = dom.querySelector("#youtube").getAttribute("href");
      }
      if (dom.querySelector("#instagram")) {
        this.instagram = dom.querySelector("#instagram").getAttribute("href");
      }
      if (dom.querySelector("#twitter")) {
        this.twitter = dom.querySelector("#twitter").getAttribute("href");
      }
      if (dom.querySelector("#linkedin")) {
        this.linkedin = dom.querySelector("#linkedin").getAttribute("href");
      }
      if (dom.querySelector("#telegram")) {
        this.telegram = dom.querySelector("#telegram").getAttribute("href");
      }
      if (dom.querySelector("#pinterest")) {
        this.pinterest = dom.querySelector("#pinterest").getAttribute("href");
      }
      if (dom.querySelector("#facebook")) {
        this.facebook = dom.querySelector("#facebook").getAttribute("href");
      }

      if (dom.querySelector("#product_price")) {
        if (dom.querySelector("#product_price").style.display === "none") {
          this.productPriceCheck = false;
        } else {
          this.productPriceCheck = true;
        }
      }
      if (dom.querySelector("#discount")) {
        if (dom.querySelector("#discount").style.display === "none") {
          this.discountCodeCheck = false;
        } else {
          this.discountCodeCheck = true;
        }
        this.discountDescription = dom
          .querySelector("#discount")
          .textContent.trim();
        this.discountFontSize = this.pxToString(
          dom.querySelector("#discount").style.fontSize
        );
      }
    },
    rgbToHex(rgb) {
      if (rgb === "initial" || rgb === "") {
        return "";
      }
      let [, r, g, b] = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)/);
      const hex = ((r << 16) | (g << 8) | b).toString(16);
      const result = "#" + Array(6 - hex.length + 1).join("0") + hex;
      return result.toUpperCase();
    },
    pxToString(px) {
      return px.replace("px", "");
    },
  },
  components: {
    BaseAccordion,
    SendTestEmailModal,
    Dropdown,
  },
};
</script>

<style lang="scss" scoped>
.body {
  display: grid;
  grid-template-columns: 0.45fr 0.55fr;
  grid-column-gap: 35px;
  padding-top: 29px;
  padding-bottom: 29px;
}
/* General classes */
.grid_2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 14px;
}

.grid_2x2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 14px;
  grid-row-gap: 12px;
}

.info-text {
  font-size: 12px;
  line-height: 16px;
  color: #6d7175;
  margin-top: 3px;
}

div.left-content div.border,
div.right-content {
  // border: 1px solid #d8d8d8;
  border-radius: 12px;
  overflow: hidden;
}

.flat {
  background: #fff;
  border: 1px solid #cecece;
  color: #1575bf;
}

.accordion-header {
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
}

/* Left Content Styling */

.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 27px 10px 21px 21px;
}

.header h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

.header p {
  color: #6d6d6d;
  font-weight: 500;
}
.content-logo {
  margin-top: 2px;
  margin-bottom: 27px;
  padding-top: 35px;
  padding-bottom: 38px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  border: 1px dashed #d8d8d8;
  border-radius: 6px;
}

.upload-btn {
  cursor: pointer;
  padding: 8px 15px;
  border: 1px solid #1575bf;
  border-radius: 8px;
  outline: none;
}
.upload-btn:hover {
  background-color: #d8d8d8;
}

.imagePreviewWrapper {
  width: 70%;
  height: 50px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.image-upload {
  cursor: wait;
}

/* Color styles section */
.content-color-styles {
  padding-top: 2px;
  padding-bottom: 27px;
}

/* Message style section */
.message-style-content {
  padding-top: 2px;
  padding-bottom: 36px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.email-desc label {
  display: block;
  margin-bottom: 3px;
}
.email-desc textarea {
  padding: 8px 13px;
  width: 100%;
  height: 162px;
  background: #fbfbfb;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  outline: none;
  resize: none;
}

.checkbox-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 9px;
}

.checkbox-container input {
  cursor: pointer;
  margin-top: 3px;
}

/* Content Styles Section */

.content-styles {
  padding-top: 2px;
  padding-bottom: 39px;
}

/* Buttons Section */

.content-buttons {
  padding-top: 2px;
  padding-bottom: 36px;
}
/* 'Success Subscription Mail' section style */
.success-mail-section-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 2px;
  padding-bottom: 31px;
}
.success-mail-section-content label {
  display: block;
  margin-bottom: 3px;
}
.success-mail-section-content textarea {
  padding: 8px 13px;
  width: 100%;
  height: 162px;
  background: #fbfbfb;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  outline: none;
  resize: none;
}
/* Social Media Section */

.content-social {
  padding-top: 2px;
  padding-bottom: 34px;
}

.content-social:first-child {
  margin-bottom: 17px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 11px;
  padding: 0 18px;
  margin-top: 33px;
  margin-bottom: 62px;
}

/*************************************************** */
/* Right Content */
.right-content {
  .logo-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #999ea4;
    border-radius: 16px;
    width: 233px;
    height: 74px;
    margin-bottom: 59px;

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .notification-email {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .success-sub {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 40px;
    h4 {
      font-size: 20px;
      font-weight: 600;
    }
    p {
      font-size: 14px;
      text-align: left;
      margin-bottom: 20px;
    }
  }
  .product-details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 65px;
  }

  .mail-subject {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
  }
  .get-it {
    max-height: 300px;
    font-size: 16px;
  }

  .blue-shoe {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5% 20%;
    border: 1px solid #cecece;
    border-radius: 12px;
    margin-bottom: 15px;
  }
  .btn-visit {
    height: 52px;
    width: 100%;
    border-radius: 8px;
    background: #1575bf;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    outline: none;
    margin-bottom: 38px;
  }

  .social-media {
    padding: 0 8px;
    width: 128px;
    height: 75px;
    display: flex;
    flex-direction: column;
    gap: 19px;
    justify-content: center;
    align-items: center;
    margin-bottom: 45px;
  }
  .social-icons {
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }

  .footer {
    text-align: center;
    max-width: 516px;
  }
}
.loading:after {
  content: " ...";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

@media only screen and (max-width: 1050px) {
  .upload-actions {
    flex-direction: column;
  }
}
@media only screen and (max-width: 800px) {
  .body {
    grid-template-columns: 0.9fr;
    justify-content: center;
    row-gap: 30px;
  }
  div.right-content {
    order: -1;
  }

  .upload-actions {
    flex-direction: row;
  }
}
</style>
