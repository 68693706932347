<template>
  <div id="table-customers">
    <div class="search-bar" style="position: relative">
      <div class="input-container" v-tooltip.left="'Type text to be searched'">
        <i class="fa fa-search icon"></i>
        <input
          type="text"
          placeholder="Search by product name"
          v-model="filters.products_name.value"
          @input="onFilter"
          v-if="searchBy === 'products_name'"
        />
        <input
          type="text"
          placeholder="Search by email"
          v-model="filters.user_email.value"
          @input="onFilter"
          v-if="searchBy === 'user_email'"
        />
      </div>
      <Dropdown
        @change="dropdownChangeHandler"
        v-model="searchBy"
        :options="searchTerms"
        optionLabel="name"
        optionValue="value"
        style="width: 250px; border: 1px solid #d8d8d8; border-radius: 8px"
      ></Dropdown>
      <div
        style="
          padding-left: 20px;
          border-left: 1px solid rgb(229, 231, 235);
          margin-left: auto;
        "
      >
        <base-button
          class="flat"
          @click="refreshTable"
          v-tooltip.left="'Refresh Table'"
        >
          <i class="pi pi-refresh" style="font-size: 14px"></i>
        </base-button>
      </div>
    </div>
    <hr />
    <DataTable
      class="request-table"
      :loading="loading"
      :lazy="true"
      :totalRecords="total"
      @page="onPage($event)"
      @sort="onPage($event)"
      @filter="onPage($event)"
      :value="requestsData"
      v-model:selection="selectedProducts"
      dataKey="row_identifier"
      :paginator="total > 10"
      :rows="10"
      paginatorTemplate="`FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink`"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      responsiveLayout="scroll"
      ref="dataTable"
    >
      <Column
        selectionMode="multiple"
        headerStyle="width: 3em"
        style="z-index: 999; position: relative"
      ></Column>
      <Column
        field="products_name"
        header="Product"
        :sortable="true"
        style="min-width: 300px"
      >
        <template #body="{ data }">
          <div class="product-container">
            <img v-if="data.image" :src="`${data.image}`" alt="product" />
            <img
              v-else
              :src="`https://cdn.lexmodo.com/adm_assets/img/default-product-img.png`"
              alt="product image"
            />

            <div class="product-details">
              <p>{{ data.products_name?.slice(0, 50) }}</p>
              <p>{{ data.variant_value?.slice(0, 50) }}</p>
            </div>
          </div>
        </template>
      </Column>

      <Column field="variant_sku" header="SKU" id="sku" :sortable="true">
        <template #body="{ data }">
          <span class="cell">{{ data.variant_sku?.slice(0, 10) }}</span>
        </template>
      </Column>
      <Column field="product_vendor" header="Vendor" :sortable="true">
        <template #body="{ data }">
          <span class="cell">{{ data.product_vendor?.slice(0, 30) }}</span>
        </template>
      </Column>
      <Column field="user_email" header="Email" :sortable="true">
        <template #body="{ data }">
          <span class="cell">{{ data.user_email }}</span>
        </template>
      </Column>
      <Column field="status" header="Product Status" :sortable="true">
        <template #body="{ data }">
          <span
            class="product-status-cell"
            :class="
              data.status === 'Active'
                ? 'product-status-cell-active'
                : 'product-status-cell-inactive'
            "
          >
            {{ data.status }}
          </span>
        </template>
      </Column>
      <Column field="created_at" header="Request Time" :sortable="true">
        <template #body="{ data }">
          <span class="cell">{{
            tablesServices.getDateDiff(data.created_at)
          }}</span>
        </template>
      </Column>
      <Column
        field="request_status"
        header="Request Status"
        :sortable="true"
        style="white-space: nowrap"
      >
        <template #body="{ data }">
          <span
            class="request-status-cell"
            :class="
              data.request_status === 'Awaiting stock'
                ? 'request-status-cell-active'
                : 'request-status-cell-inactive'
            "
            >{{ data.request_status }}</span
          >
        </template>
      </Column>
      <template #empty>
        <empty-mails></empty-mails>
      </template>
    </DataTable>
    <div class="deleteBar" ref="deleteBar" v-if="selectedProducts.length > 0">
      <button @click="deleteSelected">Delete Selected</button>
      <p>{{ selectedProducts?.length }} selected</p>
    </div>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import tablesServices from "@/services/tablesServices";

export default {
  computed: {
    isFirstPage() {
      return this.paginator ? this.paginator.getPage() === 0 : true;
    },
    isLastPage() {
      return this.paginator
        ? this.paginator.getPage() === this.paginator.getPageCount() - 1
        : true;
    },
  },
  components: {
    Dropdown,
  },

  mounted() {
    this.paginator = this.$refs.dataTable.paginator;

    this.selectedProducts = [];
    this.$emit("routeName", "Requests");
    this.loadLazyData();
  },
  data() {
    return {
      paginator: null,
      tablesServices: tablesServices,
      searchBy: "products_name",
      searchTerms: [
        { name: "Search By Prodcut Name", value: "products_name" },
        { name: "Search By Prodcut User Email", value: "user_email" },
      ],
      lazyParams: {
        first: 0,
        sortField: "created_at",
        sortOrder: 0,
        filters: this.filters,
      },
      filters: {
        user_email: { value: "", matchMode: "contains" },
        products_name: { value: "", matchMode: "contains" },
      },
      total: 0,
      error: false,
      loading: true,
      requestsData: [],
      selectedProducts: [],
    };
  },
  methods: {
    refreshTable() {
      this.requestsData = [];
      this.loadLazyData();
    },
    dropdownChangeHandler() {
      this.filters.products_name.value = "";
      this.filters.user_email.value = "";
      this.loadLazyData();
    },
    deleteSelected() {
      this.$confirm.require({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          tablesServices.deleteSelectedRecordsByRowId(
            this.selectedProducts,
            this,
            "selectedProducts",
            this.loadLazyData
          );
        },
        reject: () => {
          //callback to execute when user rejects the action
          this.selectedProducts = [];
        },
      });
    },
    loadLazyData() {
      tablesServices.getAllRequests(this.lazyParams).then((data) => {
        this.loading = false;
        if (data.status === "success") {
          this.total = data.payload.total;
          this.requestsData = data.payload.data;
        } else {
          this.error = true;
        }
      });
    },
    onPage(data) {
      this.lazyParams = data;
      this.lazyParams.filters = this.filters;
      this.loadLazyData();
    },
    onFilter() {
      this.lazyParams.filters = this.filters;
      this.loadLazyData();
    },
  },
};
</script>

<style lang="scss" scoped>
/* Table styles */
#table-customers {
  position: relative;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  border: 1px solid #edeeef;
  overflow: hidden !important;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.p-datatable {
  :deep(.p-datatable-thead > tr > th) {
    color: #202223;
    background: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
}
.p-component {
  font-family: "Plus Jakarta Sans", sans-serif;
}

.request-table {
  :deep(.p-checkbox-box) {
    width: 18px;
    height: 18px;
  }
  /* Paginator styles */
  :deep(div.p-paginator) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  :deep(button.p-paginator-prev),
  :deep(button.p-paginator-next),
  :deep(button.p-paginator-first),
  :deep(button.p-paginator-last) {
    width: 40px;
    height: 40px;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
  }
  :deep(span.p-paginator-current) {
    cursor: default;
    font-weight: 400;
    font-size: 14px;
    color: #202223;
  }
}

//Style delete bar

.deleteBar {
  position: absolute;
  padding-left: 68px;
  top: 0;
  right: 0;
  width: 100%;
  height: 70px;
  display: flex;
  gap: 24px;
  align-items: center;
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.deleteBar button {
  padding: 5.5px 21px;
  cursor: pointer;
  border: 1px solid #cecece;
  border-radius: 8px;
  outline: none;
  color: #c83e25;
}

.deleteBar button:hover {
  background-color: #f6f6f6;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.6s;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
/* Table column styling */
.product-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.product-container img {
  font-size: 10px;
  height: 40px;
}

.product-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #202223;
}

.product-details p:first-child {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.product-details p:last-child {
  font-size: 12px;
  line-height: 16px;
}

.cell {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #202223;
}

.product-status-cell {
  text-transform: capitalize;
  padding: 2px 10px;
  border-radius: 100px;
  font-size: 13px;
  line-height: 20px;
}

.product-status-cell-active {
  background: #cefff2;
  color: #00795b;
}
.product-status-cell-inactive {
  background: lightpink;
  color: red;
}
.request-status-cell {
  padding: 2px 10px;
  border-radius: 100px;
  font-size: 13px;
  line-height: 20px;
}

.request-status-cell-active {
  background: #9fd5ff;
  color: #004982;
}
.request-status-cell-inactive {
  background: lightpink;
  color: red;
}

/* search bar styling */
.search-bar {
  padding: 13px 30px;
  display: flex;
  gap: 12px;
}

.input-container {
  flex-grow: 1;
}

.input-container i {
  position: absolute;
  font-size: 15px;
}

.icon {
  padding: 20px;
  padding-top: 14px;
  min-width: 40px;
  color: #6d6d6d;
}

.search-bar input {
  width: 100%;
  height: 45px;
  padding: 8px 15px 8px 48px;
  background: #fbfbfb;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
}

.search-bar input:focus {
  outline: #6d6d6d;
}

.btn {
  display: flex;
  gap: 6.5px;
  justify-content: center;
  align-items: center;
  padding: 10.5px 16px;
  background: #ffffff;
  border: 1px solid #cecece;
  border-radius: 8px;
}

.btn:focus {
  outline: #4a4a4a;
}

.sort-menu {
  position: absolute;
  right: 28px;
  top: 70px;
  z-index: 1111;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 12px;
  background: #ffffff;
  box-shadow: 9px 6px 24px -2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
.sort-title {
  color: #6d6d6d;
}
.radio-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  gap: 9px;
}
:deep(span.p-dropdown-label.p-inputtext) {
  color: #202223;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
</style>
