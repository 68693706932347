<template>
  <div>
    <label :for="id">
      <slot></slot>
    </label>
    <input
      :maxlength="maxLength || '50'"
      :type="inputType || 'text'"
      :id="id"
      :name="id"
      :value="modelValue || +inputValue || ''"
      :style="{ height: inputHeight + 'px' }"
      :pattern="inputPattern || null"
      :placeholder="placeholder"
      ref="pass"
      @input="$emit('update:modelValue', $event.target.value)"
      @change="$emit('inputChanged')"
    />
    <p v-if="unit">{{ unit }}</p>
    <img
      v-if="inputType === 'password'"
      src="../../assets/images/password-show.png"
      alt=""
      @click="showPassword"
    />
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue", "inputChanged"],
  props: [
    "modelValue",
    "id",
    "inputType",
    "inputValue",
    "unit",
    "inputHeight",
    "inputPattern",
    "maxLength",
    "placeholder",
  ],
  methods: {
    showPassword() {
      let inputKind = this.$refs.pass.type;
      if (inputKind === "password") {
        this.$refs.pass.type = "text";
        this.$refs.pass.style.marginRight = "50px";
      }
      if (inputKind === "text") {
        this.$refs.pass.type = "password";
        this.$refs.pass.style.marginRight = "20px";
      }
    },
  },
};
</script>

<style scoped>
div {
  position: relative;
}

p {
  position: absolute;
  bottom: 10.5px;
  right: 24px;
}

img {
  cursor: pointer;
  position: absolute;
  bottom: 13px;
  right: 24px;
}

label {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 3px;
}

input {
  padding-left: 20px;
  padding-right: 50px;
  height: 45px;
  width: 100%;
  background-color: #fbfbfb;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #202223;
}

input:focus {
  outline: 1px solid #1575bf;
}
</style>
