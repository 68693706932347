<template>
  <div class="row-boxs row-boxs-in">
    <div class="box">
      <div class="main-box">
        <IconsActionsSvg />
        <div class="details">
          <h2 class="number">
            {{ totalActions }}
          </h2>
          <p>{{ paragraph }}</p>
        </div>
      </div>
      <div class="bottom-title">{{ title }}</div>
    </div>
  </div>
</template>

<script>
import IconsActionsSvg from "./IconsActionsSvg.vue";
export default {
  components: { IconsActionsSvg },
  props: {
    totalActions: {
      type: Number,
      required: true,
    },
    title: {
      required: false,
    },
    paragraph: {
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
.main-box {
  display: flex;
  align-items: center;
  gap: 25px;
  padding: 26px 32px;
  background: #e3fff8;
  border-top-right-radius: 9px;
  min-height: 140px;
  border-top-left-radius: 9px;
  justify-content: center;
  text-align: center;
}
.row-boxs-in {
  grid-template-columns: 1fr !important;
}

.row-boxs .box {
  width: 100%;
  border-bottom: 3px solid #09a59a;
  box-shadow: 0 5px 19px #e3fff8;
  border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
}
.bottom-title {
  text-align: center;
  padding: 12px;
  background: #c8f7eb;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  h2 {
    font-size: 36px;
    color: #202223;
    font-weight: 600;
  }
  p {
    color: #6d6d6d;
  }
}
</style>
