<template>
  <div class="cotnainer">
    <slot></slot>
  </div>
</template>

<style scoped>
.cotnainer {
  background: #ffffff;
  border: 1px solid #edeeef;
  box-shadow: 0px 10px 13px -2px rgba(106, 115, 121, 0.05);
  border-radius: 12px;
}
</style>
