<template>
  <section>
    <div class="stats-bar">
      <div class="stats-card customers">
        <p class="stats-card-title">Total Customers</p>
        <div class="stats-card-body">
          <p>{{ statsData.totalCustomers }}</p>
          <img src="@/assets/images/apps/dashboard/customers.svg" alt="" />
        </div>
        <div class="stats-card-footer"></div>
      </div>
      <div class="stats-card orders">
        <p class="stats-card-title">Total Products</p>
        <div class="stats-card-body">
          <p>{{ statsData.totalProducts }}</p>
          <img src="@/assets/images/apps/dashboard/orders.svg" alt="" />
        </div>
        <div class="stats-card-footer"></div>
      </div>
      <div class="stats-card amount">
        <p class="stats-card-title">Total Order Amount</p>
        <div class="stats-card-body">
          <p style="margin-right: 10px">{{ statsData.totalOrderAmount }}</p>
          <img src="@/assets/images/apps/dashboard/amount.svg" alt="" />
        </div>
        <div class="stats-card-footer"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    formatDate(fromToDate) {
      let firstDayFormatted =
        new Date(Date.parse(fromToDate[0])).getTime() / 1000;
      let lastDayFormatted =
        new Date(Date.parse(fromToDate[1])).getTime() / 1000;
      return { from_date: firstDayFormatted, to_date: lastDayFormatted };
    },
  },
  props: ["statsData"],
};
</script>

<style lang="scss" scoped>
section {
  padding: 27px 0 37px;
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.stats-bar {
  display: flex;
  gap: 17px;
}

.stats-card {
  padding-top: 21px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 295px;
  height: 132px;
  gap: 20px;
  border-radius: 8px;

  .stats-card-title {
    padding-left: 17px;
    padding-right: 17px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  }
  .stats-card-body {
    padding-left: 17px;
    padding-right: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-weight: 700;
      font-size: 36px;
      line-height: 40px;
    }
  }

  &.customers .stats-card-footer {
    height: 16px;
    background-color: #f9cd9a;
  }
  &.orders .stats-card-footer {
    height: 16px;
    background-color: #cfb8ee;
  }
  &.amount .stats-card-footer {
    height: 16px;
    background-color: #a4d4fa;
  }
}

.customers {
  background-color: #ffe5c7;
  border-bottom: 3px solid #ee962f;
  box-shadow: 0px 5px 19px #ffe5c7;
}

.orders {
  background: #e4d0ff;
  border-bottom: 3px solid #9057dd;
  box-shadow: 0px 5px 19px #e4d0ff;
}

.amount {
  background: #d5edff;
  border-bottom: 3px solid #1575bf;
  box-shadow: 0px 5px 19px #d5edff;
}

@media only screen and (max-width: 1100px) {
  .stats-bar {
    display: grid;
    gap: 17px;
  }
  .amount {
    grid-area: 2 / 1 / 3 / 3;
  }
}

@media only screen and (max-width: 720px) {
  .stats-bar {
    display: flex;
    flex-direction: column;
    gap: 17px;
  }
}
</style>
