/* eslint-disable */
import { defineStore } from "pinia";
let headersV = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${sessionStorage.getItem("auth_waitlist")}`,
};

function rgbToHex(rgb) {
  if (!rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)/)) {
    return "#ffffff";
  }
  let [, r, g, b] = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)/);
  const hex = ((r << 16) | (g << 8) | b).toString(16);
  const result = "#" + Array(6 - hex.length + 1).join("0") + hex;
  return result.toUpperCase();
}
function pxToString(px) {
  return px.replace("px", "");
}

function getHeader() {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("auth_waitlist")}`,
  };
}

export const waitListStoreFront = defineStore("store", {
  state: () => {
    return {
      unsavedChanges: false,
      error: false,
      NotifyBtnProductPageInline: "",

      //Notify Tab data
      NotifyBtnTextColor: "",
      NotifyBtnBgColor: "",
      NotifyBtnBorderColor: "",
      NotifyBtnBorderRadius: 0,
      NotifyBtnBorderSize: 0,
      NotifyBtnButtonContent: "",

      //Subscriber Form Tab data
      NotifyFormTitleContent: "",
      NotifyFormEmailLabel: "",
      NotifyFormEmailPlaceholder: "",
      NotifyFormWrongEmailError: "Incorrect email format.",
      NotifyFormButtonContent: "",
      NotifyFormTextColorBtn: "",
      NotifyFormBgColorBtn: "",
      NotifyFormBorderColorBtn: "",
      NotifyFormBorderRadiusBtn: 8,
      NotifyFormBorderSizeBtn: 0,

      //Subscriber Message Data
      alreadySubMessage: "",
      toastBgColor: "",
      toastTextColor: "",

      //Advance Tab Data
      hide_subscriber_form_logged_users: false,
      hide_subscriber_form_non_logged_users: false,
      show_notify_me_button: false,
    };
  },
  getters: {
    inlineButton() {
      return `
      <button
        type="button"
        id="inline-button"
        style="
            cursor: pointer;
            width: 311px;
            height: 45px;
            margin-bottom: 20px;
            color: ${this.NotifyBtnTextColor};
            background-color: ${this.NotifyBtnBgColor};
            border-radius: ${this.NotifyBtnBorderRadius}px;
            border: ${this.NotifyBtnBorderSize}px solid ${this.NotifyBtnBorderColor};
            ">${this.NotifyBtnButtonContent}
      </button>
      <div
        id="form-container"
        style="
          overflow: hidden;
          display: flex;
          display: none;
          justify-content: center;
          align-items: center;
          position: fixed;
          z-index: 3;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.35);
          margin: 0;
          margin-bottom: 15px;
          padding: 0;
          box-sizing: border-box;
          color: #202223;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        "
      >
        <div
          id="inline-form"
          style="
            padding: 45px 23px 33px;
            background-color: #fff;
            width: 376px;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            z-index: 4;
            display: flex;
            border: 1px solid #d8d8d8;
          "
        >
          <h6
            style="
              margin: 0;
              margin-bottom: 17px;
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
            "
          >
            ${this.NotifyFormTitleContent}
          </h6>
          <label style="margin-bottom: 3px" for="email">${this.NotifyFormEmailLabel}</label>
          <div style="position: relative;">
            <svg
              style="
                position: absolute;
                top: 50%;
                left: 20px;
                transform: translateY(-50%);
              "
              width="20"
              height="15"
              viewBox="0 0 20 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 2.82443V13C0 13.8284 0.671573 14.5 1.5 14.5H18.5C19.3284 14.5 20 13.8284 20 13V2.82433L10.5038 8.36379C10.1924 8.54542 9.80741 8.54542 9.49605 8.36379L0 2.82443Z"
                fill="#575959"
              />
              <path
                d="M19.4433 0.833666C19.1856 0.625 18.8574 0.5 18.5 0.5H1.5C1.14256 0.5 0.814324 0.625022 0.556631 0.833722L9.99992 6.34231L19.4433 0.833666Z"
                fill="#575959"
              />
            </svg>
            <input
              name="email"
              type="email"
              id="email"
              style="
                padding-left: 58px;
                width: 100%;
                font-family: 'inherit';
                height: 45px;
                color: 'inherit';
                border-radius: 8px;
                border: 1px solid #d8d8d8;
                background-color: #fbfbfb;
                outline: none;
              "
              placeholder="${this.NotifyFormEmailPlaceholder}"
            />
            <input id="hidden-variant-id" type="hidden" />
          </div>
          <p
            id="wrong-message"
            style="
              color: rgb(176, 0, 32);
              font-size: 12px;
              margin-top: 3px;
              margin-bottom: 12px;
              visibility: hidden;
            "
          >
            ${this.NotifyFormWrongEmailError}
          </p>
          <p
            class="waitlist-loading"
            id="waitlist-loading-message"
            style="
              font-size: 12px;
              margin-top: 3px;
              margin-bottom: 12px;
              display: none;
            "
          >
            Sending email
          </p>
          <button
            type="button"
            id="submit-waitlist-customer"
            style="
              cursor: pointer;
              height: 45px;
              font-weight: 600;
              outline: none;
              color: ${this.NotifyFormTextColorBtn};
              background-color: ${this.NotifyFormBgColorBtn};
              border-radius: ${this.NotifyFormBorderRadiusBtn}px;
              border: ${this.NotifyFormBorderSizeBtn}px solid ${this.NotifyFormBorderColorBtn};
            "
          >
            ${this.NotifyFormButtonContent}
          </button>
        </div>
      </div>
      <div
        id="success-message"
        style="
          color: ${this.toastTextColor};
          background-color: ${this.toastBgColor};
          z-index: 300;
          overflow: hidden;
          position: fixed;
          top: 120px;
          right: 19px;
          padding-left: 20px;
          padding-top: 16.5px;
          padding-bottom: 16.5px;
          padding-right: 20px;
          display: flex;
          display: none;
          align-items: center;
          justify-content: flex-start;
          gap: 18px;
          max-width: 600px;
          min-height: 57px;
          border: 1px solid #07d09e;
          box-shadow: 9px 6px 24px -2px rgba(0, 0, 0, 0.06);
          border-radius: 8px;
          box-sizing: border-box;
        "
      >
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 10.5C0 16.014 4.486 20.5 10 20.5C15.514 20.5 20 16.014 20 10.5C20 4.986 15.514 0.5 10 0.5C4.486 0.5 0 4.986 0 10.5ZM15.2071 8.70711C15.5976 8.31658 15.5976 7.68342 15.2071 7.29289C14.8166 6.90237 14.1834 6.90237 13.7929 7.29289L9 12.0858L6.70711 9.79289C6.31658 9.40237 5.68342 9.40237 5.29289 9.79289C4.90237 10.1834 4.90237 10.8166 5.29289 11.2071L8.29289 14.2071C8.68342 14.5976 9.31658 14.5976 9.70711 14.2071L15.2071 8.70711Z"
            fill="#07D09E"
          />
        </svg>
        <p style="margin: 0">${this.alreadySubMessage}</p>
      </div>
      <style>
      *:disabled {
        cursor: not-allowed !important;
        background-color: #e7e7e7 !important;
      }
      input#email:focus {
        box-shadow: 0 0 5px rgba(81, 203, 238, 1);
        border: 1px solid rgba(81, 203, 238, 1);
      }
      .waitlist-loading:after {
        content: " ...";
        animation: dots 1s steps(5, end) infinite;
      }
      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: white;
          text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
        }
      }
      </style>
        `;
    },
    inlineForm() {
      return (
        this.inlineButton +
        ` <style>
          #inline-button {
            display: none;
          }
          #form-container {
            display: flex !important;
            background-color: rgba(255, 255, 255, 0) !important;
            justify-content: flex-start !important;
            align-items: flex-start !important;
            position: relative !important;
          }
          </style>
        `
      );
    },
  },
  actions: {
    getWidgetData() {
      /** This method should do the following:
       *  1- call the API get method to get inline_button_html,inline_form_html,status.
       *  2- pass inline_button_html the dom parser.
       *  3- extract values needed from parsed document.
       *  4- assign those values to the varables created in the store.
       *  5- this method should be called whenever any of widgets tab is mounted.
       * **/
      this.unsavedChanges = false;
      fetch(
        "https://waitlist-api.numinix.com/api/preview-status-product-form",
        {
          method: "GET",
          headers: getHeader(),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            //Advance tab values
            this.hide_subscriber_form_non_logged_users = Boolean(
              +data.payload[0].hide_subscriber_form_non_logged_users
            );
            this.hide_subscriber_form_logged_users = Boolean(
              +data.payload[0].hide_subscriber_form_logged_users
            );
            this.show_notify_me_button = Boolean(
              +data.payload[0].show_notify_me_button
            );

            // Notify/SubForm/SubMessage
            let parser = new DOMParser();
            let dom = parser.parseFromString(
              data.payload[0].inline_button_html,
              "text/html"
            );

            // NotifyMe Tab
            this.NotifyBtnTextColor = rgbToHex(
              dom.querySelector("#inline-button")?.style.color
            );
            this.NotifyBtnBgColor = rgbToHex(
              dom.querySelector("#inline-button")?.style.backgroundColor
            );
            this.NotifyBtnBorderColor = rgbToHex(
              dom.querySelector("#inline-button")?.style.borderColor
            );
            this.NotifyBtnBorderRadius = pxToString(
              dom.querySelector("#inline-button")?.style.borderRadius
            );
            this.NotifyBtnBorderSize = pxToString(
              dom.querySelector("#inline-button")?.style.borderWidth
            );
            this.NotifyBtnButtonContent = dom
              .querySelector("#inline-button")
              .textContent.trim();

            this.NotifyBtnProductPageInline = data.payload[0].status.trim();
            //Subscriber Form
            let form = dom.querySelector("#inline-form");
            let btn = form.querySelector("button");
            let btnStyle = btn.style;
            let emailTitle = form.querySelector("h6");
            let emailLabel = form.querySelector("label");
            let inputPlaceholder = form.querySelector("input").placeholder;
            let wrongEmailError =
              dom.querySelector("#wrong-message").textContent;

            this.NotifyFormButtonContent = btn.textContent.trim();
            this.NotifyFormTextColorBtn = rgbToHex(btnStyle?.color);
            this.NotifyFormBgColorBtn = rgbToHex(btnStyle?.backgroundColor);
            this.NotifyFormBorderColorBtn = rgbToHex(btnStyle?.borderColor);
            this.NotifyFormBorderRadiusBtn = pxToString(btnStyle?.borderRadius);
            this.NotifyFormBorderSizeBtn = pxToString(btnStyle?.borderWidth);

            this.NotifyFormTitleContent = emailTitle.textContent.trim();
            this.NotifyFormEmailLabel = emailLabel.textContent.trim();
            this.NotifyFormEmailPlaceholder = inputPlaceholder.trim();
            this.NotifyFormWrongEmailError = wrongEmailError.trim();

            //Subscriber Message
            let messageContainer = dom.querySelector("#success-message");

            this.alreadySubMessage = messageContainer
              .querySelector("p")
              .textContent.trim();
            this.toastBgColor = rgbToHex(
              messageContainer.style.backgroundColor
            );
            this.toastTextColor = rgbToHex(messageContainer.style.color);
            this.unsavedChanges = false;
          }
        })
        .catch((err) => console.log(err));
    },
    submitWidgetData() {
      if (this.NotifyBtnBorderSize > 10) {
        this.NotifyBtnBorderSize = 10;
      }
      if (this.NotifyFormBorderSizeBtn > 10) {
        this.NotifyFormBorderSizeBtn = 10;
      }
      if (this.NotifyBtnBorderSize < 0) {
        this.NotifyBtnBorderSize = 0;
      }
      if (this.NotifyFormBorderSizeBtn < 0) {
        this.NotifyFormBorderSizeBtn = 0;
      }
      /** This method should do the following:
       *  1- call the API post method.
       *  2- attach this variables to the parameters: (inline_button_html, inline_form_html, status).
       *  3- this method should be called whenever the user click submit from any tab inside widget component.
       *  4- so the whole data will be sent and retrieved.
       *  **/
      let sentObject = {
        inline_button_js: `
        // Variable declarations
        var btnWaitlistVue = document.querySelector("#inline-button");
        var formContainerWaitlistVue = document.querySelector("#form-container");
        var formWaitlistVue = document.querySelector("#inline-form");
        var formSubmitBtnWaitlistVue = document.querySelector(
          "#submit-waitlist-customer"
        );
        var emailInputWaitlistVue = formWaitlistVue.querySelector("input");
        var successMessageWaitlistVue = document.querySelector("#success-message");
        var wrongMessageWaitlistVue = document.querySelector("#wrong-message");
        var wrongContentWaitlistVue = wrongMessageWaitlistVue.textContent;
        var loadingMessageWaitlistVue = document.querySelector("#waitlist-loading-message");
        var variantIdWaitlistVue = document.querySelector("#hidden-variant-id").value;
        var productIdWaitlistVue = document.querySelector(
          "input[name='CartAdd[0].CartAddProductID']"
        ).value;
        var storeIdWaitlistVue = document
          .querySelector("script[id='waitlist-script']")
          .getAttribute("store_id");

        // Eventlisteners
        if (btnWaitlistVue) {
          btnWaitlistVue.addEventListener("click", showForm);
          formContainerWaitlistVue.addEventListener("click", hideForm);
        }
        formWaitlistVue.addEventListener("click", function (event) {
          event.stopPropagation();
        });
        formSubmitBtnWaitlistVue.addEventListener("click", function (event) {
          if (emailInputWaitlistVue.value) {
            addCustomer(emailInputWaitlistVue.value);
          } else {
            wrongMessageWaitlistVue.style.visibility = "visible";
            wrongMessageWaitlistVue.textContent = "Email field is required.";
            emailInputWaitlistVue.focus();
          }
        });
        emailInputWaitlistVue.addEventListener("input", function (event) {
          let inputValue = event.target.value.trim();
          wrongMessageWaitlistVue.style.visibility = "hidden";
          if (!validateEmail(inputValue) && inputValue !== "") {
            wrongMessageWaitlistVue.textContent = wrongContentWaitlistVue;
            wrongMessageWaitlistVue.style.visibility = "visible";
            formSubmitBtnWaitlistVue.disabled = true;
          } else {
            wrongMessageWaitlistVue.style.visibility = "hidden";
            formSubmitBtnWaitlistVue.disabled = false;
          }
        });
        //Create custom event for the hidden input changes this event will be triggered from inside the admin template
        const hiddenInputChangeEvent = new Event("hiddenInputChange");
        document
          .querySelector("#hidden-variant-id")
          .addEventListener("hiddenInputChange", function (event) {
            variantIdWaitlistVue = event.target.value;
          });

        // Functions declarations
        function showForm() {
          document
            .querySelectorAll(".product")
            .forEach((element) => (element.style.zIndex = -1));
          if (formContainerWaitlistVue) formContainerWaitlistVue.style.display = "flex";
        }
        function hideForm() {
          document
            .querySelectorAll(".product")
            .forEach((element) => (element.style.zIndex = "auto"));
          if (formContainerWaitlistVue) formContainerWaitlistVue.style.display = "none";
        }
        function showSuccess() {
          successMessageWaitlistVue.style.display = "flex";
        }
        function hideSuccess() {
          successMessageWaitlistVue.style.display = "none";
        }
        function validateEmail(email) {
          const emailRegex = /^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$/;
          return String(email).toLowerCase().match(emailRegex);
        }
        function addCustomer(email) {
          // product_id and product_variant should be dynamic here
          let sentData = {
            store_id: storeIdWaitlistVue,
            user_email: email,
            product_id: productIdWaitlistVue,
            product_variant: variantIdWaitlistVue,
          };

          //No need for bearer token here as we are sending store_id inside the sent data.
          let headersV = {
            Accept: "application/json",
            "Content-Type": "application/json",
          };
          // Show loading message/disable submit btn
          loadingMessageWaitlistVue.style.display = "block";
          formSubmitBtnWaitlistVue.disabled = true;
          wrongMessageWaitlistVue.style.display = "none";
          //Store id must be dynamic here
          fetch("https://waitlist-api.numinix.com/api/addToWaitList", {
            method: "POST",
            headers: headersV,
            body: JSON.stringify(sentData),
          })
            .then((response) => response.json())
            .then((data) => {
              loadingMessageWaitlistVue.style.display = "none";
              wrongMessageWaitlistVue.style.display = "block";
              formSubmitBtnWaitlistVue.disabled = false;
              if (data.status === "success") {
                showSuccess();
                setTimeout(hideSuccess, 1500);
                emailInputWaitlistVue.value = "";
                hideForm();
              } else {
                console.log("Error on submition!");
              }
            })
            .catch((err) => console.log(err));
        }
        `,
        status: this.NotifyBtnProductPageInline,
        inline_button_html: `<section>${this.inlineButton}</section>`,
        inline_form_html: `<section>${this.inlineForm}</section>`,
        hide_subscriber_form_non_logged_users: `${+this
          .hide_subscriber_form_non_logged_users}`,
        hide_subscriber_form_logged_users: `${+this
          .hide_subscriber_form_logged_users}`,
        show_notify_me_button: `${+this.show_notify_me_button}`,
      };
      this.unsavedChanges = false;

      fetch(`https://waitlist-api.numinix.com/api/save-status-product-form`, {
        method: "post",
        headers: getHeader(),
        body: JSON.stringify(sentObject),
      })
        .then((response) => response.json())
        .catch((err) => console.log(err));
    },
  },
});
