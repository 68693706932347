export class authLogin {
  constructor() {}

  startApp() {
    if (this.urlParams.has("code")) {
      this.fetchToCode();
    }
    // start working login auth
    if (this.urlParams.has("widgets")) {
      // if login widgets in main app this is way
      return this.loginWidgets();
    } else {
      // if login in main app this is way
      return this.login();
    }
  }

  headersV = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("auth_waitlist")}`,
  };

  urlParams = new URLSearchParams(window.location.search);
  session_token = this.urlParams.get("session_token");
  object = {
    session_token: this.urlParams.get("session_token"),
  };

  // Start  Login widgets
  loginWidgets() {
    return fetch("https://waitlist-api.numinix.com/api/auth/login", {
      method: "post",
      headers: this.headersV,
      body: JSON.stringify(this.object),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        sessionStorage.setItem("auth_waitlist", data.meta.token);
        if (!data.meta.redirect) {
          let product_widget = {
            product_id: this.urlParams.get("pid"),
            variant_id: this.urlParams.get("vid"),
            product_widget: new URLSearchParams(window.location.search).has(
              "widgets"
            ),
          };
          return { ...data, product_widget: product_widget };
        } else {
          let product_widget = {
            product_widget: false,
          };
          window.location.replace(
            `/widgets?product_widget=${encodeURIComponent(
              JSON.stringify(product_widget)
            )}`
          );
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  login() {
    // post session token
    if (this.urlParams.has("session_token"))
      return fetch("https://waitlist-api.numinix.com/api/auth/login", {
        method: "post",
        headers: this.headersV,
        body: JSON.stringify(this.object),
      })
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          sessionStorage.setItem("auth_waitlist", data.meta.token);
          if (data.meta.redirect) {
            window.parent.location.href = data.meta.authUrl;
          } else {
            return data;
          }
        })
        .catch((data) => {
          console.log(data);
        });
    else if (this.urlParams.has("deploy.php")) console.log("fd");
    else {
      if (sessionStorage.getItem("auth_waitlist") === null) {
        window.parent.location.href =
          "https://admin.lexmodo.com/plugins/waitlist-notification";
      }
    }
  }

  fetchToCode() {
    // eslint-disable-next-line no-unused-vars
    let objectCode = {
      code: this.urlParams.get("code"),
      scope: this.urlParams.get("scope"),
      state: this.urlParams.get("state"),
    };
    fetch("https://waitlist-api.numinix.com/api/auth/code", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("auth_waitlist")}`,
      },
      body: JSON.stringify(objectCode),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        if (data.acknowledge) {
          window.location.href =
            "https://admin.lexmodo.com/plugins/waitlist-notification";
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }
}
