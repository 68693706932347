<template>
  <div v-if="statusApp">
    <UpperBar v-if="!productWidget"></UpperBar>
    <waitlist-nav v-if="!productWidget"></waitlist-nav>
    <waitlist-title-bar
      :title="title"
      v-if="!productWidget"
    ></waitlist-title-bar>
    <div class="menu-tab">
      <router-view
        @route-name="getTitle"
        ref="child"
        :widgetData="widgetData"
      ></router-view>
    </div>
  </div>

  <div v-else class="app-loading">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
  </div>
</template>

<script>
import WaitlistTitleBar from "./components/WaitlistTitleBar.vue";
import WaitlistNav from "./components/NavBar/WaitlistNav.vue";
import UpperBar from "./components/UpperBar.vue";
import { authLogin } from "./services/authLogin";
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "App",
  setup() {
    const title = ref(""),
      pathName = ref(""),
      widgetData = ref({}),
      productWidget = ref(false);

    const statusApp = ref(false),
      auth = new authLogin(),
      router = useRouter();

    auth.startApp()?.then((data) => {
      if (!data.meta.redirect) {
        if (data.product_widget?.product_widget === true) {
          widgetData.value = data;
          statusApp.value = true;
          productWidget.value = true;
          router.push("/widgets");
        } else {
          statusApp.value = true;
          router.push("/waitlist-notification");
        }
      }
    });

    return { statusApp, title, pathName, widgetData, productWidget };
  },
  mounted() {
    this.pathName = window.location.pathname;
  },
  components: {
    WaitlistTitleBar,
    WaitlistNav,
    UpperBar,
  },
  methods: {
    getTitle(value) {
      this.title = value;
    },
  },
};
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  padding: 50px;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  background-color: #f2f5fa;
  scrollbar-gutter: "stable";
}

.menu-tab {
  box-shadow: 0px 10px 13px -2px #6a73790d;
  position: relative;
}

/* Hide number arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.app-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media only screen and (max-width: 1200px) {
  #app {
    padding: 40px;
  }
}
@media only screen and (max-width: 1000px) {
  #app {
    padding: 35px;
  }
}
@media only screen and (max-width: 800px) {
  #app {
    padding: 30px;
  }
}
@media only screen and (max-width: 600px) {
  #app {
    padding: 25px;
  }
}
@media only screen and (max-width: 400px) {
  #app {
    padding: 20px;
  }
}
@media only screen and (max-width: 350px) {
  #app {
    padding: 15px;
  }
}
</style>
