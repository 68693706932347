<template>
  <div class="backdrop">
    <form class="add-customer-form" @submit.prevent="emailImport($event)">
      <div class="header">
        <h5>Import Data as CSV</h5>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="$emit('hideModal')"
        >
          <path
            d="M9.41385 7.99985L15.7069 1.70685C15.8944 1.51934 15.9997 1.26503 15.9997 0.999849C15.9997 0.734673 15.8944 0.480357 15.7069 0.292849C15.5193 0.105341 15.265 0 14.9998 0C14.7347 0 14.4804 0.105341 14.2928 0.292849L7.99985 6.58585L1.70685 0.292849C1.614 0.200005 1.50378 0.126356 1.38247 0.0761092C1.26117 0.0258621 1.13115 0 0.999849 0C0.868547 0 0.738531 0.0258621 0.617224 0.0761092C0.495916 0.126356 0.385694 0.200005 0.292849 0.292849C0.105341 0.480357 0 0.734673 0 0.999849C0 1.26503 0.105341 1.51934 0.292849 1.70685L6.58585 7.99985L0.292849 14.2928C0.105341 14.4804 0 14.7347 0 14.9998C0 15.265 0.105341 15.5193 0.292849 15.7069C0.480357 15.8944 0.734673 15.9997 0.999849 15.9997C1.26503 15.9997 1.51934 15.8944 1.70685 15.7069L7.99985 9.41385L14.2928 15.7069C14.3855 15.8 14.4957 15.874 14.617 15.9244C14.7383 15.9749 14.8684 16.0009 14.9998 16.0009C15.1313 16.0009 15.2614 15.9749 15.3827 15.9244C15.504 15.874 15.6142 15.8 15.7069 15.7069C15.7998 15.6141 15.8735 15.5039 15.9238 15.3825C15.9742 15.2612 16 15.1312 16 14.9998C16 14.8685 15.9742 14.7385 15.9238 14.6172C15.8735 14.4958 15.7998 14.3856 15.7069 14.2928L9.41385 7.99985Z"
            fill="#575959"
          />
        </svg>
      </div>
      <div class="rules">
        <p>Please follow this rules:</p>
        <ol>
          <li>The file should be in csv format.</li>
          <li>It should have 3 columns.</li>
          <li>
            Columns headers are email,product id and product variant
            respectively.
          </li>
          <li>Make sure each field has correct value.</li>
          <li>File size limit is 0.5 mb.</li>
        </ol>
      </div>
      <div>
        <label class="upload-input" for="csvFile">
          <span> Click to upload a CSV file </span>
          <input
            name="csvFile"
            ref="fileInput"
            @change="selectFile"
            type="file"
            accept=".csv"
            id="csvFile"
            required
            placeholder="Please enter an email which data download link will be sent to"
          />
          <p v-if="fileName">{{ fileName }}</p>
        </label>
        <p v-if="largeFile" style="font-size: 16px; color: red">
          file size has to be less than 500 kb
        </p>
        <p
          v-if="errorMessage"
          style="
            font-size: 16px;
            color: red;
            margin-bottom: 10px;
            overflow-wrap: break-word;
          "
        >
          {{ errorMessage }}
        </p>
      </div>

      <div class="actions">
        <base-button @click.prevent="$emit('hideModal')" class="small flat"
          >Back</base-button
        >
        <base-button
          ref="submitBtn"
          class="custom-btn small"
          :disabled="!fileData"
          type="submit"
        >
          Add Data
        </base-button>
      </div>
    </form>
  </div>
</template>

<script>
import tablesServices from "@/services/tablesServices";

export default {
  emits: ["hideModal", "submitEmailImport"],
  data() {
    return {
      largeFile: false,
      fileData: null,
      fileName: null,
      errorMessage: null,
    };
  },
  methods: {
    selectFile() {
      this.errorMessage = null;
      this.fileData = null;
      this.fileName = null;
      this.largeFile = false;

      let input = this.$refs.fileInput;
      this.fileData = input.files[0];
      if (this.fileData.size > 500000) {
        this.largeFile = true;
        return;
      }
      this.fileName = input.files[0].name;
    },
    async emailImport(event) {
      let formData = new FormData(event.target);

      // disable input and button
      this.$refs.fileInput.disabled = true;
      this.$refs.submitBtn.disabled = true;

      tablesServices.emailImport(formData).then((data) => {
        if (data.status === "success") {
          this.$emit("submitEmailImport");
        } else {
          this.errorMessage = data.message;
          if (data.payload.Row) {
            this.errorMessage += ", " + data.payload.Row_Count;
          }
        }
        this.$refs.fileInput.disabled = false;
        this.$refs.submitBtn.disabled = false;
      });
    },
  },
};
</script>

<style scoped>
.backdrop {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.35);
}

.add-customer-form {
  padding: 21px 36px 23px 33px;
  margin: 10% 20% auto auto;
  background-color: #fff;

  width: 50vw;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  z-index: 1005;
}

.add-customer-form input {
  display: none;
}

.upload-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 15px;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d8d8d8;
  margin-bottom: 20px;
}
.add-customer-form h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 36px;
}

.actions {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-top: auto;
}

.actions button:first-child {
  color: #ffb054;
}
.actions button:last-child {
  color: #fff;
}

div.header svg {
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
}

.custom-btn:disabled {
  cursor: not-allowed;
}

.rules p {
  font-size: 16px;
}
.rules {
  margin-bottom: 10px;
}

.rules ol {
  list-style-type: decimal;
  padding: 25px;
  padding-top: 10px;
  font-size: 14px;
}
</style>
