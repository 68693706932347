<template>
  <div class="main">
    <div class="left-content">
      <h5>Put your template code here</h5>
      <p>HTML & CSS only</p>
      <p>Waitlist Notification Email</p>

      <ul style="color: brown">
        <li>
          Please take in consideration that adding any other different ids than
          implemented here the inputs in the customize tab will no longer be
          attached to the email template, and the email template value will be
          taken from html template tab only.
        </li>
        <li>
          Also note that this html contains backend placeholders, editing those
          placeholders will make email template work improperly.
        </li>
      </ul>

      <p></p>
    </div>

    <textarea
      v-model="htmlTemplate"
      @blur="textAreaHandler($event)"
      @change="$emit('unsavedChanges', true)"
    ></textarea>
    <div class="actions">
      <base-button class="flat red-text small" @click="resetEmailTemplate"
        >Reset</base-button
      >
      <base-button class="flat small">Send Test Email</base-button>
      <base-button class="small" @click="saveEmailTemplate">Save</base-button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["unsaved-changes", "htmlTabUpdate", "unsavedChanges", "inputUpdated"],
  watch: {
    htmlCode(value) {
      this.htmlTemplate = value;
    },
    htmlTemplate() {
      this.$emit("unsavedChanges", true);
    },
  },
  methods: {
    textAreaHandler(event) {
      this.$emit("htmlTabUpdate", event.target.value);
    },
  },
  data() {
    return {
      htmlTemplate: this.htmlCode,
    };
  },
  props: ["htmlCode", "resetEmailTemplate", "saveEmailTemplate"],
};
</script>

<style scoped>
.main {
  padding-bottom: 34px;
}

.left-content h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

.left-content p {
  color: #6d6d6d;
  font-weight: 500;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 11px;
  padding: 0 18px;
  margin-top: 16px;
}

textarea {
  height: 673px;
  width: 100%;
  margin-top: 27px;
  background-color: #f2f5fa;
  border: 1px solid #e7e7e7;
  border-radius: 12px;
  resize: none;
  padding: 15px;
}

textarea:focus {
  border: 2px solid #a8a8a8;
  outline: none;
}

@media only screen and (max-width: 780px) {
  textarea {
    height: 400px;
    width: 100%;
    margin-top: 20px;
    padding: 12px;
  }
}

@media only screen and (max-width: 520px) {
  textarea {
    height: 300px;
  }
  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    padding: 0 10px;
    margin-top: 10px;
  }
}
</style>
