<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.41385 8.49997L15.7069 2.20697C15.8944 2.01946 15.9997 1.76515 15.9997 1.49997C15.9997 1.23479 15.8944 0.980479 15.7069 0.792971C15.5193 0.605463 15.265 0.500122 14.9998 0.500122C14.7347 0.500122 14.4804 0.605463 14.2928 0.792971L7.99985 7.08597L1.70685 0.792971C1.614 0.700127 1.50378 0.626478 1.38247 0.576231C1.26117 0.525984 1.13115 0.500122 0.999849 0.500122C0.868547 0.500122 0.738531 0.525984 0.617224 0.576231C0.495916 0.626478 0.385694 0.700127 0.292849 0.792971C0.105341 0.980479 0 1.23479 0 1.49997C0 1.76515 0.105341 2.01946 0.292849 2.20697L6.58585 8.49997L0.292849 14.793C0.105341 14.9805 0 15.2348 0 15.5C0 15.7651 0.105341 16.0195 0.292849 16.207C0.480357 16.3945 0.734673 16.4998 0.999849 16.4998C1.26503 16.4998 1.51934 16.3945 1.70685 16.207L7.99985 9.91397L14.2928 16.207C14.3855 16.3002 14.4957 16.3741 14.617 16.4246C14.7383 16.475 14.8684 16.501 14.9998 16.501C15.1313 16.501 15.2614 16.475 15.3827 16.4246C15.504 16.3741 15.6142 16.3002 15.7069 16.207C15.7998 16.1142 15.8735 16.004 15.9238 15.8827C15.9742 15.7613 16 15.6313 16 15.5C16 15.3686 15.9742 15.2386 15.9238 15.1173C15.8735 14.996 15.7998 14.8858 15.7069 14.793L9.41385 8.49997Z"
      fill="#575959"
    />
  </svg>
</template>
