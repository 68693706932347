<template>
  <div class="tab-container">
    <form @submit.prevent="saveData">
      <div class="left-content">
        <div>
          <header>
            <h6>Message after subscribing</h6>
            <p>Subscription message is shown to users after they subscribe.</p>
          </header>
          <hr />

          <base-accordion>
            <template #title>
              <span class="title">Message Content</span>
            </template>
            <template #content>
              <div class="content-section-body">
                <div>
                  <label for="already-sub-mess"
                    >Already subscribed message</label
                  >
                  <textarea
                    @change="newChanges"
                    v-model="store.alreadySubMessage"
                    name="already-sub-mess"
                    id="already-sub-mess"
                    cols="10"
                    rows="10"
                    maxlength="90"
                  ></textarea>
                </div>
              </div>
            </template>
          </base-accordion>

          <hr />

          <base-accordion>
            <template #title>
              <span>Message Styles</span>
            </template>
            <template #content>
              <div class="message-styles-section-body">
                <div class="grid2 pad-14">
                  <color-input
                    v-model="store.toastTextColor"
                    @input-changed="newChanges"
                    >Toast text color</color-input
                  >
                  <color-input
                    v-model="store.toastBgColor"
                    @input-changed="newChanges"
                    >Background color</color-input
                  >
                </div>
              </div>
            </template>
          </base-accordion>
        </div>
      </div>
      <div class="actions">
        <base-button class="flat" @click="discardChanges" type="button"
          >Discard</base-button
        >
        <base-button type="submit">Save Changes</base-button>
      </div>
    </form>

    <div class="right-content">
      <div class="img-containers">
        <img
          src="@/assets/images/apps/widgets_tab/sub-message/RightContent.png"
          alt=""
          @load="imageLoading = true"
        />
        <div :style="toastStyle" v-if="imageLoading">
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 10.5C0 16.014 4.486 20.5 10 20.5C15.514 20.5 20 16.014 20 10.5C20 4.986 15.514 0.5 10 0.5C4.486 0.5 0 4.986 0 10.5ZM15.2071 8.70711C15.5976 8.31658 15.5976 7.68342 15.2071 7.29289C14.8166 6.90237 14.1834 6.90237 13.7929 7.29289L9 12.0858L6.70711 9.79289C6.31658 9.40237 5.68342 9.40237 5.29289 9.79289C4.90237 10.1834 4.90237 10.8166 5.29289 11.2071L8.29289 14.2071C8.68342 14.5976 9.31658 14.5976 9.70711 14.2071L15.2071 8.70711Z"
              fill="#07D09E"
            />
          </svg>
          <p style="margin: 0">{{ store.alreadySubMessage }}</p>
        </div>
      </div>
    </div>
  </div>
  <success-message v-show="showAlert"> Saved Sucessfully! </success-message>
</template>

<script>
import BaseAccordion from "@/components/UI/BaseAccordion.vue";
import { waitListStoreFront } from "@/services/waitListStoreFront";

export default {
  props: ["setTab"],
  watch: {
    touchForm() {
      if (this.touched) {
        this.$emit("touchForm", true);
      }
      this.touched = true;
    },
  },
  computed: {
    touchForm() {
      return `${
        this.store.alreadySubMessage +
        this.store.toastBgColor +
        this.store.toastTextColor
      }`;
    },
    toastStyle() {
      return {
        //styles that can be edited by user
        color: this.store.toastTextColor,
        backgroundColor: this.store.toastBgColor,
        //constant styles
        overflow: "hidden",
        position: "absolute",
        top: "100px",
        left: "50px",
        paddingLeft: "20px",
        paddingTop: "16.5px",
        paddingBottom: "16.5px",
        paddingRight: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "18px",
        maxWidth: "600px",
        minHeight: "57px",
        border: "1px solid #07d09e",
        boxShadow: "9px 6px 24px -2px rgba(0, 0, 0, 0.06)",
        borderRadius: "8px",
        boxSizing: "border-box",
      };
    },
  },
  components: { BaseAccordion },
  emits: ["touchForm"],
  data() {
    return {
      imageLoading: false,
      store: waitListStoreFront(),
      touched: false,
      showAlert: false,
    };
  },
  methods: {
    discardChanges() {
      // This method should discard changes made by user on inputs
      // Discard changes by recalling the get API to get last inputs data
      // only discard if the user has made some changes
      // else show a message for him that changes already discarded
      if (this.store.unsavedChanges) {
        this.store.getWidgetData();
      } else {
        this.$toast.add({
          severity: "info",
          summary: "No Changes to be discarded",
          detail: "All changes are already discarded",
          life: 1500,
        });
      }
    },
    newChanges() {
      this.store.unsavedChanges = true;
    },
    saveData() {
      if (this.store.unsavedChanges) {
        this.store.submitWidgetData();
        this.showAlert = true;
        setTimeout(this.hideAlert, 1500);
      } else {
        this.$toast.add({
          severity: "info",
          summary: "Changes up to date",
          detail: "Please make some changes to be saved",
          life: 1500,
        });
      }
    },
    hideAlert() {
      this.showAlert = false;
    },
  },
};
</script>

<style scoped>
.tab-container {
  display: grid;
  grid-template-columns: 0.45fr 0.55fr;
  grid-column-gap: 36px;
  padding: 36px 23px 36px 27px;
  width: 100%;
  min-height: 1312px;
}

.left-content {
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow: hidden;
}

.left-content header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 26px;
  padding-bottom: 27px;
}

.left-content header div {
  display: flex;
  justify-content: space-between;
}

h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

/* 'Message Content' section style */
.content-section-body {
  padding: 2px 0 29px 0;
}

.content-section-body label {
  display: block;
  margin-bottom: 3px;
}
.content-section-body textarea {
  padding: 8px 13px;
  width: 100%;
  height: 98px;
  background: #fbfbfb;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  outline: none;
  resize: none;
}

/* 'Message Styles' section style */
.message-styles-section-body {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-top: 2px;
  padding-bottom: 33px;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 14px;
  grid-row-gap: 18px;
}

/* Style actions buttons */
.actions {
  margin-top: 27px;
  display: flex;
  gap: 15px;
}

.right-content {
  background-color: #fff;
}

.img-containers {
  position: relative;
}

@media only screen and (max-width: 800px) {
  .tab-container {
    grid-template-columns: 0.9fr;
    justify-content: center;
    row-gap: 30px;
    min-height: auto;
  }
  div.right-content {
    order: -1;
  }
}
</style>
