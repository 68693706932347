<template>
  <form class="form-container" @submit.prevent="saveSettings" ref="settingForm">
    <div class="grid_2">
      <p class="data_title">Mailhost</p>
      <div>
        <base-card class="mailhost">
          <div class="grid_2x2">
            <label-input
              id="mail_host"
              inputType="text"
              inputHeight="40"
              v-model="mailHost"
              @input-changed="inputChanged"
            >
              Mail host
            </label-input>
            <label-input
              id="mail_port"
              inputType="number"
              inputHeight="40"
              v-model="mailPort"
              @input-changed="inputChanged"
            >
              Mail port
            </label-input>
            <label-input
              id="mail_username"
              inputType="text"
              inputHeight="40"
              v-model="mailUsername"
              @input-changed="inputChanged"
            >
              Mail username
            </label-input>
            <label-input
              id="mail_password"
              inputType="password"
              v-model="mailPassword"
              @input-changed="inputChanged"
            >
              Mail password
            </label-input>
            <label-input
              id="mail_encryption"
              inputType="text"
              inputHeight="40"
              v-model="mailEncryption"
              @input-changed="inputChanged"
            >
              Mail encryption
            </label-input>
            <label-input
              id="st_address"
              inputType="text"
              v-model="mailFromAddress"
              @input-changed="inputChanged"
            >
              Mail from address
            </label-input>
          </div>
        </base-card>

        <ul v-if="errorMessage" class="errorMessage">
          <li v-for="(error, index) in errorMessage" :key="index">
            {{ error[0] }}
          </li>
        </ul>
        <div class="actions">
          <!-- @click="discardChanges" -->
          <base-button class="flat" type="button" @click="discardChanges"
            >Discard</base-button
          >
          <base-button class="save" type="submit">Save Changes</base-button>
        </div>
      </div>
    </div>
  </form>
  <confirm-message v-if="leavePageConfirmShow" @confirm-message="messageEvent">
    <template #title>Unsaved changes</template>
    <template #body>
      If you leave this page, any unsaved changes will be lost
    </template>
  </confirm-message>
  <success-message v-show="successMessage">
    Settings saved successfully!
  </success-message>
  <Toast />
</template>

<script>
import BaseCard from "@/components/UI/BaseCard.vue";
import SettingsService from "@/services/settingsService";

export default {
  components: { BaseCard },
  data() {
    return {
      localFormTouched: false,
      clickedRoute: "",
      leavePageConfirmShow: false,

      mailHost: "",
      mailPort: "",
      mailUsername: "",
      mailPassword: "",
      mailEncryption: "",
      mailFromAddress: "",
      errorMessage: null,
      successMessage: null,
    };
  },
  mounted() {
    this.$emit("routeName", "Settings");
    this.getSettings();
  },
  beforeRouteLeave(to, from, next) {
    if (this.localFormTouched) {
      this.leavePageConfirmShow = true;
      this.clickedRoute = to;
      next(false);
    } else {
      next(true);
    }
  },
  emits: ["routeName"],
  methods: {
    discardChanges() {
      // - This method should discard changes made by user on inputs
      // - Discard changes by recalling the get API to get last inputs data
      // - only discard if the user has made some changes
      // - else show a message for him that changes already discarded
      if (this.localFormTouched) {
        this.getSettings();
      } else {
        this.$toast.add({
          severity: "info",
          summary: "No Changes to be discarded",
          detail: "All changes are already discarded",
          life: 1500,
        });
      }
    },
    messageEvent(value) {
      if (value) {
        this.localFormTouched = false;
        this.$router.push(this.clickedRoute);
      } else {
        this.leavePageConfirmShow = false;
        this.clickedRoute = "";
      }
    },
    inputChanged() {
      this.localFormTouched = true;
    },
    getSettings() {
      SettingsService.getSettings()
        .then((data) => {
          if (data.status === "success") {
            let dataSmtp = data.payload.smtp;

            this.mailHost = dataSmtp?.mail_host;
            this.mailPort = dataSmtp?.mail_port;
            this.mailUsername = dataSmtp?.mail_username;
            this.mailPassword = dataSmtp?.mail_password;
            this.mailEncryption = dataSmtp?.mail_encryption;
            this.mailFromAddress = dataSmtp?.mail_from_address;
            this.localFormTouched = false;
          } else {
            // if the status failed clear inputs and change form to untouched
            this.$refs.settingForm.reset();
            this.localFormTouched = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveSettings() {
      if (this.localFormTouched) {
        let sendData = {
          mail_host: this.mailHost,
          mail_port: this.mailPort,
          mail_username: this.mailUsername,
          mail_password: this.mailPassword,
          mail_encryption: this.mailEncryption,
          mail_from_address: this.mailFromAddress,
        };
        SettingsService.saveSettings(sendData).then((data) => {
          if (data.status === "success") {
            this.successMessage = true;
            setTimeout(() => {
              this.successMessage = null;
            }, 1500);
            this.errorMessage = null;
            this.localFormTouched = false;
          } else {
            this.errorMessage = Object.values(data.errors);
          }
        });
      } else {
        this.$toast.add({
          severity: "info",
          summary: "Changes up to date",
          detail: "Please make some changes to be saved",
          life: 1500,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.errorMessage {
  font-weight: 600;
  padding: 5px;
  background-color: rgb(240, 153, 153);
  border: 1px solid #ff0033;
  color: #ff0033;
  border-radius: 8px;
  // text-align: center;
  margin-bottom: 10px;
}
.form-container {
  padding-left: 10vw;
  padding-bottom: 5vw;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

// Mailhost section
.mailhost {
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 24px;
  padding-bottom: 28px;
  margin-bottom: 12px;
}

.other-info {
  padding: 24px;
  padding-bottom: 34px;
  margin-bottom: 28px;
}

.save {
  margin-right: 0;
}
/************************************************************ */
/* General classes */
.grid_2 {
  display: grid;
  grid-template-columns: 0.25fr 0.6fr;
  column-gap: 15px;
}
.grid_2x2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 14px;
  row-gap: 14px;
}
.data_description {
  font-size: 12px;
  line-height: 16px;
  color: #6d7175;
}

.data_title {
  font-weight: 600;
}
/* Style actions buttons */
.actions {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

@media only screen and (max-width: 1000px) {
  .grid_2 {
    grid-template-columns: 0.25fr 0.7fr;
  }
}
@media only screen and (max-width: 850px) {
  .form-container {
    padding-left: 0;
  }
  .grid_2 {
    justify-content: center;
    grid-template-columns: 0.85fr;
    row-gap: 10px;
  }
}
@media only screen and (max-width: 750px) {
  .grid_2 {
    grid-template-columns: 0.9fr;
  }
}
@media only screen and (max-width: 650px) {
  .grid_2x2 {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 500px) {
  .grid_2 {
    grid-template-columns: 1fr;
  }
}
</style>
