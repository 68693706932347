<template>
  <div class="main">
    <h6>Send a notification</h6>
    <p>
      All notifications will be sent automatically when you restock products.
      Send a notification to the following request to see how it works.
    </p>
    <router-link to="/notification-email">
      <base-button>Send notification</base-button>
    </router-link>
  </div>
</template>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 9px;
}
p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 31px;
}
</style>
