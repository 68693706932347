<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="#575959"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.12388 0C7.67608 0 7.29639 0.329343 7.23311 0.772723L6.89891 3.11155C6.26676 3.35266 5.68338 3.69266 5.16768 4.11264L2.97349 3.2319C2.55777 3.06508 2.08272 3.22925 1.85882 3.61714L0.839509 5.38285C0.615388 5.77073 0.71075 6.26425 1.06319 6.54079L2.9223 8.00013C2.87044 8.32577 2.84342 8.65974 2.84342 9C2.84342 9.34026 2.87044 9.67422 2.9223 9.99985L1.06319 11.4592C0.71075 11.7357 0.615388 12.2293 0.839509 12.6171L1.85882 14.3828C2.08272 14.7707 2.55777 14.9349 2.97349 14.7681L5.16768 13.8874C5.68338 14.3073 6.26676 14.6473 6.89891 14.8885L7.23311 17.2273C7.29639 17.6707 7.67608 18 8.12388 18H10.1629C10.6107 18 10.9904 17.6707 11.0537 17.2273L11.3879 14.8885C12.0201 14.6473 12.6034 14.3074 13.1191 13.8874L15.3131 14.7681C15.7288 14.9349 16.2039 14.7707 16.4278 14.3828L17.4473 12.6171C17.6712 12.2293 17.5759 11.7357 17.2236 11.4592L15.3645 9.99992C15.4164 9.67426 15.4434 9.34027 15.4434 9C15.4434 8.88887 15.4406 8.77842 15.4348 8.66871C15.4232 8.44247 15.3995 8.21939 15.3645 8.00008L17.2236 6.54079C17.5759 6.26425 17.6712 5.77073 17.4473 5.38285L16.4278 3.61714C16.2039 3.22925 15.7288 3.06508 15.3131 3.2319L13.1191 4.11258C12.6034 3.69263 12.0201 3.35266 11.3879 3.11155L11.0537 0.772723C10.9904 0.329343 10.6107 0 10.1629 0H8.12388ZM9.14351 11.7C10.6347 11.7 11.8435 10.4912 11.8435 9C11.8435 7.50883 10.6347 6.3 9.14351 6.3C7.65234 6.3 6.44351 7.50883 6.44351 9C6.44351 10.4912 7.65234 11.7 9.14351 11.7Z"
    />
  </svg>
</template>
