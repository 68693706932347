<template>
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.9264 7.62857C17.7894 2.28591 13.6794 -0.148689 9.5713 0.0638015C5.51048 0.273844 1.67905 3.06556 0.054966 7.66715L-0.0625 7.99997L0.054966 8.33279C1.67905 12.9344 5.51048 15.7261 9.5713 15.9361C13.6794 16.1486 17.7894 13.714 19.9264 8.37136L20.075 7.99997L19.9264 7.62857ZM9.99805 13C12.7595 13 14.998 10.7614 14.998 7.99997C14.998 5.23855 12.7595 2.99997 9.99805 2.99997C7.23662 2.99997 4.99805 5.23855 4.99805 7.99997C4.99805 10.7614 7.23662 13 9.99805 13Z"
      fill="#6D6D6D"
    />
    <path
      d="M12.998 7.99997C12.998 9.65682 11.6549 11 9.99805 11C8.34119 11 6.99805 9.65682 6.99805 7.99997C6.99805 6.34312 8.34119 4.99997 9.99805 4.99997C11.6549 4.99997 12.998 6.34312 12.998 7.99997Z"
      fill="#6D6D6D"
    />
  </svg>
</template>
