import axios from "axios";

export default {
  async getSettings() {
    return axios
      .get("https://waitlist-api.numinix.com/api/show-settings", {
        headers: this.getHeader(),
      })
      .then((res) => res.data);
  },
  async saveSettings(sentData) {
    return axios
      .post("https://waitlist-api.numinix.com/api/save-settings", sentData, {
        headers: this.getHeader(),
      })
      .then((res) => res.data)
      .catch((error) => {
        return error.response.data;
      });
  },
  getHeader() {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("auth_waitlist")}`,
    };
  },
};
