<template>
  <div id="table-waitlist" style="position: relative">
    <div class="search-bar" style="position: relative">
      <div class="input-container">
        <i class="fa fa-search icon"></i>
        <input
          type="text"
          placeholder="Search by email"
          v-model="filters.user_email.value"
          @input="onFilter"
        />
      </div>
      <div
        style="
          padding-left: 20px;
          border-left: 1px solid rgb(229, 231, 235);
          margin-left: auto;
        "
      >
        <base-button
          class="flat"
          @click="refreshTable"
          v-tooltip.left="'Refresh Table'"
        >
          <i class="pi pi-refresh" style="font-size: 14px"></i>
        </base-button>
      </div>
    </div>
    <hr />
    <DataTable
      class="customers-table"
      :loading="loading"
      :lazy="true"
      :totalRecords="total"
      @page="onPage($event)"
      @sort="onPage($event)"
      :value="customersData"
      v-model:selection="selectedCustomers"
      dataKey="user_email"
      :paginator="total > 10"
      :rows="10"
      paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      responsiveLayout="scroll"
      ref="dataTable"
    >
      <Column
        selectionMode="multiple"
        headerStyle="width: 3em"
        style="z-index: 999; position: relative"
      ></Column>
      <Column
        field="user_email"
        header="User Email"
        :sortable="true"
        style="min-width: 200px"
      ></Column>
      <Column
        field="Customer_Exists"
        header="Customer Exists in Lexmodo"
        :sortable="true"
      >
        <template #body="{ data }">
          <span
            class="customer-exists-cell"
            :class="
              data.Customer_Exists === 1
                ? 'customer-exists-cell-true'
                : 'customer-exists-cell-false'
            "
          >
            {{ Boolean(+data.Customer_Exists) }}
          </span>
        </template>
      </Column>
      <Column
        field="Active_Count"
        header="Active Count"
        :sortable="true"
      ></Column>
      <Column
        field="Archived_Count"
        header="Archived Count"
        :sortable="true"
      ></Column>
      <template #empty>
        <empty-mails></empty-mails>
      </template>
    </DataTable>

    <div class="deleteBar" ref="deleteBar" v-if="selectedCustomers.length > 0">
      <button @click="deleteSelected">Delete Selected</button>
      <p>{{ selectedCustomers?.length }} selected</p>
    </div>
  </div>
</template>

<script>
import tablesServices from "@/services/tablesServices";

export default {
  mounted() {
    this.selectedCustomers = [];
    this.$emit("routeName", "Customers on Waitlist");
    this.loadLazyData();
  },
  data() {
    return {
      lazyParams: {
        first: 0,
        sortField: null,
        sortOrder: null,
        filters: this.filters,
      },
      filters: {
        user_email: { value: "", matchMode: "contains" },
      },
      total: 0,
      error: false,
      loading: true,
      customersData: [],
      selectedCustomers: [],
    };
  },
  methods: {
    refreshTable() {
      this.customersData = [];
      this.loadLazyData();
    },
    deleteSelected() {
      this.$confirm.require({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          tablesServices.deleteSelectedRecordsByEmail(
            this.selectedCustomers,
            this,
            "selectedCustomers",
            this.loadLazyData
          );
        },
        reject: () => {
          //callback to execute when user rejects the action
          this.selectedCustomers = [];
        },
      });
    },
    loadLazyData() {
      tablesServices.getAllCustomers(this.lazyParams).then((data) => {
        this.loading = false;
        if (data.status === "success") {
          this.total = data.payload.total;
          this.customersData = data.payload.data;
        } else {
          this.error = true;
        }
      });
    },
    onPage(data) {
      this.lazyParams = data;
      this.lazyParams.filters = this.filters;
      this.loadLazyData();
    },
    onFilter() {
      this.lazyParams.filters = this.filters;
      this.loadLazyData();
    },
  },
};
</script>

<style lang="scss" scoped>
/* Table styles */
#table-customers {
  position: relative;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  border: 1px solid #edeeef;
  overflow: hidden !important;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.p-datatable {
  :deep(.p-datatable-thead > tr > th) {
    color: #202223;
    background: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
}
.p-component {
  font-family: "Plus Jakarta Sans", sans-serif;
}

.customers-table {
  :deep(.p-checkbox-box) {
    width: 18px;
    height: 18px;
  }
  /* Paginator styles */
  :deep(div.p-paginator) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  :deep(button.p-paginator-prev),
  :deep(button.p-paginator-next),
  :deep(button.p-paginator-first),
  :deep(button.p-paginator-last) {
    width: 40px;
    height: 40px;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
  }
  :deep(span.p-paginator-current) {
    cursor: default;
    font-weight: 400;
    font-size: 14px;
    color: #202223;
  }
}

.deleteBar {
  position: absolute;
  padding-left: 68px;
  top: 0;
  right: 0;
  width: 100%;
  height: 70px;
  display: flex;
  gap: 24px;
  align-items: center;
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.deleteBar button {
  padding: 5.5px 21px;
  cursor: pointer;
  border: 1px solid #cecece;
  border-radius: 8px;
  outline: none;
  color: #c83e25;
}
.deleteBar button:hover {
  background-color: #f6f6f6;
}

// Search Bar Stylling
.search-bar {
  padding: 13px 30px;
  display: flex;
  gap: 12px;
}
.input-container {
  flex-grow: 1;
}

.input-container i {
  position: absolute;
  font-size: 15px;
}
.icon {
  padding: 20px;
  padding-top: 14px;
  min-width: 40px;
  color: #6d6d6d;
}

.search-bar input {
  width: 100%;
  height: 45px;
  padding: 8px 15px 8px 48px;
  background: #fbfbfb;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
}

.search-bar input:focus {
  outline: #6d6d6d;
}

//Style false and true cells
.customer-exists-cell {
  padding: 2px 10px;
  border-radius: 100px;
  font-size: 13px;
  line-height: 20px;
}

.customer-exists-cell-true {
  background: #cefff2;
  color: #00795b;
}
.customer-exists-cell-false {
  background: lightpink;
  color: red;
}
</style>
