<template>
  <div class="main">
    <h6>Customize widgets</h6>
    <p>
      Customize the look and feel of button, subscription form, and
      notifications to match your store.
    </p>
    <router-link to="/widgets-store-front">
      <base-button>Customize</base-button>
    </router-link>
  </div>
</template>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 9px;
}
p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 31px;
}
</style>
