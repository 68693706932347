import { createRouter, createWebHistory } from "vue-router";
import DashBoard from "../views/DashBoard.vue";
import RequestsTab from "../views/RequestsTab.vue";
import NotificationEmail from "../views/NotificationEmail.vue";
import SettingTab from "../views/SettingTab.vue";
import WidgetsTab from "../views/WidgetsTab.vue";
import WidgetsComponent from "../views/WidgetsComponent.vue";

import RequestsTable from "@/components/RequestsTabComponents/RequestsTable.vue";
import CustomersTable from "@/components/RequestsTabComponents/CustomersTable.vue";
import ExcludedProducts from "@/components/RequestsTabComponents/ExcludedProducts.vue";
import NotificationsTab from "@/components/RequestsTabComponents/NotificationsTab.vue";

const routes = [
  { path: "/", redirect: "/waitlist-notification" },
  { path: "/waitlist-notification", component: DashBoard },
  {
    path: "/reports",
    component: RequestsTab,
    children: [
      { path: "", redirect: "/reports/requests-list" },
      { path: "requests-list", component: RequestsTable },
      { path: "customers-list", component: CustomersTable },
      { path: "notifications-list", component: NotificationsTab },
      { path: "excluded-list", component: ExcludedProducts },
    ],
  },

  { path: "/notification-email", component: NotificationEmail },
  { path: "/widgets-store-front", component: WidgetsTab },
  { path: "/settings", component: SettingTab },
  { path: "/widgets", component: WidgetsComponent },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active-tab",
});

// // Authentication;
// let headersV = {
//   Accept: "application/json",
//   "Content-Type": "application/json",
//   Authorization: `Bearer ${sessionStorage.getItem("auth_waitlist")}`,
// };

// let urlParams = new URLSearchParams(window.location.search),
//   session_token = urlParams.get("session_token"),
//   object = {
//     session_token,
//   };

// // Start  Login widgets
// let loginWidgets = () => {
//   fetch("https://waitlist-api.numinix.com/api/auth/login", {
//     method: "post",
//     headers: headersV,
//     body: JSON.stringify(object),
//   })
//     .then((data) => {
//       return data.json();
//     })
//     .then((data) => {
//       console.log(data);
//       sessionStorage.setItem("auth_waitlist", data.meta.token);
//       if (!data.meta.redirect) {
//         let product_widget = {
//           product_id: urlParams.get("pid"),
//           variant_id: urlParams.get("vid"),
//           product_widget: new URLSearchParams(window.location.search).has(
//             "widgets"
//           ),
//         };

//         window.location.replace(
//           `/widgets?product_widget=${encodeURIComponent(
//             JSON.stringify(product_widget)
//           )}`
//         );
//       } else {
//         let product_widget = {
//           product_widget: false,
//         };
//         window.location.replace(
//           `/widgets?product_widget=${encodeURIComponent(
//             JSON.stringify(product_widget)
//           )}`
//         );
//       }
//     })
//     .catch((data) => {
//       console.log(data);
//     });
// };

// // post session token
// let login = () => {
//   if (urlParams.has("session_token")) {
//     fetch("https://waitlist-api.numinix.com/api/auth/login", {
//       method: "post",
//       headers: headersV,
//       body: JSON.stringify(object),
//     })
//       .then((response) => {
//         return response.json();
//       })
//       .then((data) => {
//         console.log("Auth Token");
//         console.log(data.meta.token);
//         sessionStorage.setItem("auth_waitlist", data.meta.token);
//         if (data.meta.redirect) {
//           window.parent.location.href = data.meta.authUrl;
//         } else {
//           window.location.replace("/");
//         }
//       });
//   } else {
//     if (sessionStorage.getItem("auth_waitlist") === null) {
//       window.parent.location.href =
//         "https://admin.lexmodo.com/plugins/waitlist-notification";
//     }
//   }
// };

// let fetchToCode = () => {
//   // eslint-disable-next-line no-unused-vars
//   console.log(urlParams);
//   let objectCode = {
//     code: urlParams.get("code"),
//     scope: urlParams.get("scope"),
//     state: urlParams.get("state"),
//   };
//   fetch("https://waitlist-api.numinix.com/api/auth/code", {
//     mode: "cors",
//     method: "post",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${sessionStorage.getItem("auth_waitlist")}`,
//     },
//     body: JSON.stringify(objectCode),
//   })
//     .then((data) => {
//       return data.json();
//     })
//     .then((data) => {
//       if (data.acknowledge)
//         window.location.href =
//           "https://admin.lexmodo.com/plugins/waitlist-notification";
//     });
// };

// if (urlParams.has("code")) {
//   console.log("code");
//   fetchToCode();
// }

// // start working login auth
// if (urlParams.has("widgets")) {
//   // if login widgets in main app this is way
//   loginWidgets();
// } else {
//   // if login in main app this is way
//   login();
// }

export default router;
