<template>
  <div class="alert">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10ZM15.2071 8.20711C15.5976 7.81658 15.5976 7.18342 15.2071 6.79289C14.8166 6.40237 14.1834 6.40237 13.7929 6.79289L9 11.5858L6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L15.2071 8.20711Z"
        fill="#07D09E"
      />
    </svg>
    <p>
      <slot></slot>
    </p>
  </div>
</template>

<style scoped>
.alert {
  padding-left: 20px;
  padding-right: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  position: fixed;
  top: 10%;
  right: 20%;
  width: 600px;
  height: 64px;
  border-radius: 8px;
  background-color: #e7fff9;
  border: 1px solid #07d09e;
  box-shadow: 9px 6px 24px -2px #0000000f;
  z-index: 1900;
}
</style>
