<template>
  <div class="empty">
    <svg
      width="250"
      height="250"
      viewBox="0 0 250 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="125" cy="125" r="125" fill="#F2F5FA" />
      <g clip-path="url(#clip0_6777_295422)">
        <path
          d="M187.216 106.348L125.499 146.283L63.7821 106.348V44.631C63.7396 42.6686 65.296 41.0434 67.2585 41.0009C67.3098 40.9998 67.3612 40.9998 67.4126 41.0009H183.586C185.548 40.9583 187.174 42.5147 187.216 44.4772C187.217 44.5286 187.217 44.58 187.216 44.6314V106.348Z"
          fill="white"
        />
        <path
          d="M63.7825 106.349L42 91.8269L63.7825 70.0444V106.349Z"
          fill="#FFB154"
        />
        <path
          d="M209.001 91.8269L187.219 106.349V70.0444L209.001 91.8269Z"
          fill="#FFB154"
        />
        <path
          d="M187.216 106.022L208.999 91.8267V193.478C208.992 197.329 207.465 201.021 204.751 203.753L179.955 178.957L140.021 139.022L139.004 137.461L187.216 106.022Z"
          fill="#FD9C2A"
        />
        <path
          d="M179.955 178.957L204.75 203.752C202.019 206.467 198.327 207.993 194.476 208H56.5203C52.6696 207.993 48.9774 206.467 46.2461 203.752L71.0417 178.957L110.976 139.022L111.993 137.461L125.498 146.283L139.003 137.461L140.02 139.022L179.955 178.957Z"
          fill="#FFB154"
        />
        <path
          d="M111.994 137.461L110.978 139.022L71.0432 178.957L46.2476 203.752C43.5333 201.021 42.0068 197.329 42 193.478V91.8267L111.994 137.461Z"
          fill="#FD9C2A"
        />
      </g>
      <defs>
        <clipPath id="clip0_6777_295422">
          <rect
            width="167"
            height="167"
            fill="white"
            transform="translate(42 41)"
          />
        </clipPath>
      </defs>
    </svg>
    <h5>You don't have any sent notifications</h5>
    <p>
      To see how back-in-stock notifications work on your store, subscribe for
      an out-of-stock product and restock it on your Shopify admin panel. You
      will then see your notification here.
    </p>
  </div>
</template>

<style scoped lang="scss">
.empty {
  padding-top: 7%;
  padding-bottom: 14%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h5 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  }

  p {
    max-width: 555px;
  }
}
</style>
