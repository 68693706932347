<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="#6D6D6D"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 7.90909V4.09091C3 3.48818 3.48818 3 4.09091 3H7.90909C8.51182 3 9 3.48818 9 4.09091V7.90909C9 8.51182 8.51182 9 7.90909 9H4.09091C3.48818 9 3 8.51182 3 7.90909Z"
      fill="#6D6D6D"
    />
    <path
      d="M11 7.90909V4.09091C11 3.48818 11.4882 3 12.0909 3H15.9091C16.5118 3 17 3.48818 17 4.09091V7.90909C17 8.51182 16.5118 9 15.9091 9H12.0909C11.4882 9 11 8.51182 11 7.90909Z"
      fill="#6D6D6D"
    />
    <path
      d="M3 15.9091V12.0909C3 11.4882 3.48818 11 4.09091 11H7.90909C8.51182 11 9 11.4882 9 12.0909V15.9091C9 16.5118 8.51182 17 7.90909 17H4.09091C3.48818 17 3 16.5118 3 15.9091Z"
      fill="#6D6D6D"
    />
    <path
      d="M11 14.5455V13.4545C11 13.304 11.1222 13.1818 11.2727 13.1818H13.1818V11.2727C13.1818 11.1222 13.304 11 13.4545 11H14.5455C14.696 11 14.8182 11.1222 14.8182 11.2727V13.1818H16.7273C16.8778 13.1818 17 13.304 17 13.4545V14.5455C17 14.696 16.8778 14.8182 16.7273 14.8182H14.8182V16.7273C14.8182 16.8778 14.696 17 14.5455 17H13.4545C13.304 17 13.1818 16.8778 13.1818 16.7273V14.8182H11.2727C11.1222 14.8182 11 14.696 11 14.5455Z"
      fill="#6D6D6D"
    />
  </svg>
</template>
