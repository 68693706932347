<template>
  <div class="tabs-panel">
    <!-- variant.count_customers -->
    <!-- variant.product_variant -->
    <!-- variant.user_emails -->
    <StatisticsComponent
      :totalActions="countCustomers"
      :title="'Waitlist count'"
      :paragraph="'Number of times this product variant has been added to waitlists'"
    ></StatisticsComponent>
    <div
      style="
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
      "
    >
      <h3>
        Recent customers who have added the product variant to their waitlist
      </h3>
      <p class="variant_id">Product Variant ID: {{ productVariants }}</p>
      <div style="display: flex; justify-content: space-between; width: 100%">
        <base-button
          class="delete-btn"
          :disabled="selectedCustomers.length === 0"
          @click="deleteSelected"
          >Delete Selected Emails</base-button
        >
        <base-button class="refresh-btn flat" @click="$emit('refresh-data')">
          Refresh Emails
          <i
            class="pi pi-refresh"
            style="color: #202223; font-size: 1.25rem"
          ></i>
        </base-button>
      </div>
    </div>
    <DataTable
      :value="userEmails"
      dataKey="row_identifier"
      v-model:selection="selectedCustomers"
      style="width: 100%; border-radius: 5px"
      :paginator="true"
      :rows="10"
      paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      responsiveLayout="scroll"
    >
      <Column
        selectionMode="multiple"
        headerStyle="width: 3em"
        style="z-index: 999; position: relative"
      ></Column>
      <Column field="email" header="Customer Email" :sortable="true"></Column>
    </DataTable>
  </div>
</template>

<script>
import axios from "axios";
import StatisticsComponent from "../StatisticsComponent/StatisticsComponent.vue";

export default {
  emits: ["refresh-data"],
  props: ["countCustomers", "productVariants", "userEmails"],
  components: {
    StatisticsComponent,
  },
  data() {
    return {
      selectedCustomers: [],
    };
  },
  methods: {
    getHeader() {
      return {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("auth_waitlist")}`,
      };
    },
    deleteSelected() {
      this.$confirm.require({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          let that = this;
          this.selectedCustomers.forEach(function (customer, index) {
            axios
              .post(
                `https://waitlist-api.numinix.com/api/destroy`,
                { row_identifier: customer.row_identifier },
                { headers: that.getHeader() }
              )
              .then(function () {
                if (that.selectedCustomers.length - 1 === index) {
                  // if this is the last request refresh the table
                  // Request data again & clear selected checkbox
                  that.$emit("refresh-data");
                  //   that.getCutsomers();
                  that.selectedCustomers = [];
                }
              })
              .catch((err) => console.log(err));
          });
        },
        reject: () => {
          // callback to execute when user rejects the action
          // clear the checkbox selection:
          this.selectedCustomers = [];
        },
      });
    },
  },
};
</script>

<style></style>
