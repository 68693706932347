/* eslint-disable */
import { createApp, reactive } from "vue";
import { createPinia } from "pinia";

import router from "./router";

//Vue Componenets
import App from "./App.vue";

//--> global and UI components
import EmptyMails from "./components/EmptyMails.vue";
import SuccessMessage from "./components/UI/SuccessMessage.vue";
import BaseButton from "./components/UI/BaseButton.vue";
import BaseInput from "./components/UI/BaseInput.vue";
import ColorInput from "./components/UI/ColorInput.vue";
import LabelWithInput from "./components/UI/LabelWithInput.vue";
import BaseSelect from "./components/UI/BaseSelect.vue";
import ConfirmMessage from "./components/UI/ConfirmMessage.vue";
import PixelsInput from "./components/UI/PixelsInput.vue";

//Primevue,other components
import PrimeVue from "primevue/config";
import DataTable from "primevue/datatable";
import CustomButton from "primevue/button";
import Column from "primevue/column";
import Row from "primevue/row";
import TabMenu from "primevue/tabmenu";
import InputText from "primevue/inputtext";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import ConfirmationService from "primevue/confirmationservice";
import Tooltip from "primevue/tooltip";

//Primevue css
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons

// Axios
import axios from "axios";
import VueAxios from "vue-axios";

const app = createApp(App);

app.use(VueAxios, axios);
const pinia = createPinia();
app.use(pinia);
app.component("empty-mails", EmptyMails);
app.component("base-button", BaseButton);
app.component("base-input", BaseInput);
app.component("success-message", SuccessMessage);
app.component("color-input", ColorInput);
app.component("label-input", LabelWithInput);
app.component("base-select", BaseSelect);
app.component("confirm-message", ConfirmMessage);
app.component("pixels-input", PixelsInput);

//primevue components
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Row", Row);
app.component("TabMenu", TabMenu);
app.component("CustomButton", CustomButton);
app.component("input-text", InputText);
app.component("Toast", Toast);

app.use(ToastService);
app.use(PrimeVue);
app.use(router);
app.use(ConfirmationService);
app.directive("tooltip", Tooltip);

//event-bus
const eventBus = reactive({
  events: {},
  $on(eventName, callback) {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(callback);
  },
  $emit(eventName, ...args) {
    if (this.events[eventName]) {
      this.events[eventName].forEach((callback) => {
        callback(...args);
      });
    }
  },
});
app.config.globalProperties.$eventBus = eventBus;

app.mount("#app");
