<template>
  <div class="status-filter">
    <p>Filters:</p>
    <Dropdown
      style="width: 200px; border-radius: 6px"
      v-model="filterStatusBy"
      :options="statusFilterOptions"
      @change="dropdownHandle($event, 'status')"
      optionLabel="label"
      placeholder="Select a status"
    ></Dropdown>
    <Dropdown
      style="width: 200px; border-radius: 6px"
      v-model="filterTypeBy"
      :options="typeFilterOptions"
      @change="dropdownHandle($event, 'type')"
      optionLabel="label"
      placeholder="Select a type"
    ></Dropdown>
    <div
      style="
        padding-left: 20px;
        border-left: 1px solid rgb(229, 231, 235);
        margin-left: auto;
      "
    >
      <base-button
        class="flat"
        @click="refreshTable"
        v-tooltip="'Refresh Table'"
      >
        <i class="pi pi-refresh" style="font-size: 14px"></i>
      </base-button>
    </div>
  </div>
  <DataTable
    class="datatable"
    :loading="loading"
    :lazy="true"
    :sortField="'created_at'"
    :sortOrder="-1"
    :totalRecords="total"
    @page="onPage($event)"
    :value="notificationsData"
    :paginator="total > 10"
    :rows="10"
    paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    responsiveLayout="scroll"
    :dataKey="String(Math.random())"
  >
    <Column
      field="email"
      header="Email"
      :style="{
        'white-space': 'normal',
        'word-wrap': 'anywhere',
        'min-width': '200px',
      }"
    ></Column>
    <Column field="status" header="Status">
      <template #body="{ data }">
        <span
          class="status-cell"
          :class="data.status !== 'failed' ? 'cell-success' : 'cell-fail'"
        >
          {{ data.status }}
        </span>
      </template>
    </Column>
    <Column field="type" header="Type"></Column>
    <Column field="log_msg" header="Message"></Column>
    <Column
      field="count_error"
      header="Trials Count"
      v-if="filters.status.value !== 'success'"
    ></Column>
    <Column
      field="last_trial"
      header="Last Trial"
      style="white-space: nowrap"
      v-if="filters.status.value !== 'success'"
      ><template #body="{ data }">
        <span class="cell" v-if="data.last_trial">{{
          tablesServices.getDateDiff(data.last_trial)
        }}</span>
      </template></Column
    >
    <Column
      field="created_at"
      header="Sending Time"
      style="white-space: nowrap"
    >
      <template #body="{ data }">
        <span class="cell">{{
          tablesServices.getDateDiff(data.created_at)
        }}</span>
      </template>
    </Column>

    <template #empty>
      <empty-notification v-if="filters.status.value === null" />
      <p v-else>No records</p>
    </template>
  </DataTable>
</template>

<script>
import tablesServices from "@/services/tablesServices";
import Dropdown from "primevue/dropdown";
import EmptyNotification from "./SubComponents/EmptyNotification.vue";

export default {
  computed: {
    statusFilterOptions() {
      return [
        { label: "All Statuses", value: null },
        { label: "Success", value: "success" },
        { label: "Failed", value: "failed" },
      ];
    },
    typeFilterOptions() {
      return [
        { label: "All Types", value: null },
        { label: "Waitlist Mails", value: "Waitlist Mails" },
        { label: "Export CSV", value: "Export CSV" },
        { label: "Subscription Mail", value: "Subscription Mail" },
      ];
    },
  },
  components: { EmptyNotification, Dropdown },
  mounted() {
    this.loadLazyData();
  },
  data() {
    return {
      filterStatusBy: null,
      filterTypeBy: null,
      filters: {
        status: { value: null, matchMode: "equals" },
        type: { value: null, matchMode: "equals" },
      },
      tablesServices: tablesServices,
      notificationsData: [],
      lazyParams: {
        first: 0,
        sortField: "created_at",
        sortOrder: -1,
      },
      total: null,
      error: false,
      loading: true,
    };
  },
  methods: {
    refreshTable() {
      this.notificationsData = [];
      this.loadLazyData();
    },
    dropdownHandle(event, filterName) {
      this.filters[filterName].value = event.value.value;
      this.loadLazyData();
    },
    loadLazyData() {
      tablesServices
        .getAllExports({ ...this.lazyParams, filters: { ...this.filters } })
        .then((data) => {
          this.loading = false;
          if (data.status === "success") {
            this.total = data.payload.total;
            this.notificationsData = data.payload.data;
          } else {
            this.error = true;
          }
        });
    },
    onPage(data) {
      this.lazyParams = data;
      this.loadLazyData();
    },
  },
};
</script>

<style lang="scss" scoped>
.status-cell {
  text-transform: capitalize;
  padding: 2px 10px;
  border-radius: 100px;
  font-size: 13px;
  line-height: 20px;
}
.cell-success {
  background: #cefff2;
  color: #00795b;
}
.cell-fail {
  background: lightpink;
  color: red;
}

.datatable {
  //Style table font
  :deep(.p-column-title),
  :deep(.p-datatable-tbody td) {
    font-family: "Plus Jakarta Sans";
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #202223;
  }
  //Style table header and its font
  :deep(.p-datatable-thead > tr > th) {
    background-color: #fff;
    .p-column-title {
      font-weight: 600;
    }
  }
  // Style paginator and align it to the right
  :deep(div.p-paginator) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  :deep(button.p-paginator-prev),
  :deep(button.p-paginator-next),
  :deep(button.p-paginator-first),
  :deep(button.p-paginator-last) {
    color: #202223;
    width: 40px;
    height: 40px;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
  }
  :deep(span.p-paginator-current) {
    cursor: default;
    font-weight: 400;
    font-size: 14px;
    color: #202223;
  }
}
.status-filter {
  padding: 13px 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #e5e7eb;
}
</style>
