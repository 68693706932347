<template>
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="#6D6D6D"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 2.32443V12.5C0 13.3284 0.671573 14 1.5 14H18.5C19.3284 14 20 13.3284 20 12.5V2.32433L10.5038 7.86379C10.1924 8.04542 9.80741 8.04542 9.49605 7.86379L0 2.32443Z"
    />
    <path
      d="M19.4433 0.333666C19.1856 0.125 18.8574 0 18.5 0H1.5C1.14256 0 0.814324 0.125022 0.556631 0.333722L9.99992 5.84231L19.4433 0.333666Z"
    />
  </svg>
</template>
