<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5976 9.95689H14.6088C14.404 9.95689 14.238 10.1229 14.238 10.3277V14.8358C14.238 15.0751 14.0433 15.2698 13.804 15.2698H5.16419C4.92495 15.2698 4.73036 15.0751 4.73036 14.8358V6.19617C4.73036 5.95684 4.92495 5.7621 5.16419 5.7621H9.90315C10.1079 5.7621 10.2739 5.59608 10.2739 5.39131V4.40253C10.2739 4.19775 10.1079 4.03174 9.90315 4.03174H5.16419C3.97083 4.03174 3 5.00272 3 6.19617V14.8359C3 16.0293 3.97088 17.0002 5.16419 17.0002H13.8039C14.9974 17.0002 15.9683 16.0293 15.9683 14.8359V10.3277C15.9684 10.1229 15.8023 9.95689 15.5976 9.95689Z"
      fill="#1575BF"
    />
    <path
      d="M16.6307 3H12.6977C12.4929 3 12.3269 3.16602 12.3269 3.37079V4.35957C12.3269 4.56434 12.4929 4.73036 12.6977 4.73036H14.0476L8.96798 9.80986C8.82317 9.95466 8.82317 10.1894 8.96798 10.3343L9.66714 11.0335C9.7367 11.103 9.83098 11.1421 9.92937 11.1421C10.0277 11.1421 10.122 11.103 10.1915 11.0335L15.2711 5.95387V7.3037C15.2711 7.50848 15.4372 7.67449 15.6419 7.67449H16.6307C16.8355 7.67449 17.0015 7.50848 17.0015 7.3037V3.37079C17.0015 3.16602 16.8355 3 16.6307 3Z"
      fill="#1575BF"
    />
  </svg>
</template>
