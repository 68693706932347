<template>
  <div class="dashboard-table-container">
    <p class="dashboard-title">{{ title }}</p>
    <DataTable
      class="dashboard-table"
      :value="data"
      :paginator="data.length > 5"
      :rows="5"
      paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
      responsiveLayout="scroll"
      currentPageReportTemplate="{totalRecords} items"
    >
      <Column field="product_id" header="View on Lexmodo">
        <template #body="slotProps">
          <a
            target="_blank"
            style="color: #1575bf; display: flex; align-items: center; gap: 7px"
            :href="`https://admin.lexmodo.com/products/${slotProps.data.product_id}/variants/${slotProps.data.product_variant}`"
          >
            <span> View Link </span>
            <IconLinkSvg />
          </a>
        </template>
      </Column>
      <Column field="products_name" header="Product">
        <template #body="{ data }">
          <div>
            <p>{{ data.products_name.substring(1, 35) }}</p>
            <p style="font-size: 12px">
              {{ data.variant_value }}
            </p>
          </div>
        </template>
      </Column>
      <Column field="count" header="Counts"></Column>
      <template #empty> No records found. </template>
    </DataTable>
  </div>
</template>

<script>
import IconLinkSvg from "./icons/IconLinkSvg.vue";

export default {
  props: ["title", "data"],
  components: { IconLinkSvg },
};
</script>

<style lang="scss" scoped>
.dashboard-table-container {
  display: flex;
  flex-direction: column;

  :deep(.p-column-title),
  :deep(.p-datatable-tbody td) {
    font-family: "Plus Jakarta Sans";
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #202223;
  }

  :deep(.p-column-title) {
    font-weight: 600;
  }

  :deep(div.p-paginator.p-component) {
    display: flex;
    justify-content: flex-end;
  }
  :deep(span.p-paginator-current) {
    margin-right: auto;
  }
  :deep(div.p-paginator) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  :deep(button.p-paginator-prev),
  :deep(button.p-paginator-next),
  :deep(button.p-paginator-first),
  :deep(button.p-paginator-last) {
    color: #202223;
    width: 40px;
    height: 40px;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
  }
  :deep(span.p-paginator-current) {
    cursor: default;
    font-weight: 400;
    font-size: 14px;
    color: #202223;
  }
}

.dashboard-table {
  border: 1px solid #edeeef;
  border-radius: 8px;
  overflow: hidden;
}

p.dashboard-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 12px;
}
</style>
