<template>
  <div>
    <label>
      <slot></slot>
    </label>
    <div>
      <input
        pattern="/[0-10]/"
        :max="maxValue"
        :min="minValue"
        type="number"
        :style="{ height: inputHeight + 'px' }"
        @input="$emit('update:modelValue', $event.target.value)"
        :value="modelValue"
        @change="$emit('inputChanged')"
      />
      <p>px</p>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue", "inputChanged"],
  props: ["modelValue", "inputHeight", "maxValue", "minValue"],
};
</script>

<style scoped>
div {
  position: relative;
}

p {
  position: absolute;
  bottom: 10.5px;
  right: 24px;
}

label {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 3px;
}

input {
  padding-left: 20px;
  padding-right: 45px;
  height: 45px;
  width: 100%;
  background-color: #fbfbfb;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #202223;
}

input:focus {
  outline: 1px solid #1575bf;
}
</style>
