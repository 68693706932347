<template>
  <div class="main">
    <h6>Check the “Notify me” button</h6>
    <p>
      The button is automatically added on your out-of-stock products pages.
    </p>
    <div class="actions">
      <router-link to="/widgets-store-front">
        <base-button>Check button</base-button>
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 9px;
}
p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 31px;
}

.actions {
  display: flex;
  gap: 8px;
}
</style>
