<template>
  <section class="wdgt" v-if="wdgetsStatus">
    <!-- if product has more than one variant show navigation tabs-->
    <TabView v-if="waitlistData.length > 1">
      <TabPanel
        :key="index"
        v-for="(variant, index) in waitlistData"
        :header="`Variant ${index + 1}`"
      >
        <WidgetContent
          :countCustomers="variant.count_customers"
          :productVariants="variant.product_variant"
          :userEmails="variant.user_emails"
          @refresh-data="getCutsomers"
        ></WidgetContent>
      </TabPanel>
    </TabView>
    <!-- if product has one variant only -->
    <WidgetContent
      v-else-if="waitlistData.length === 1"
      :countCustomers="waitlistData[0].count_customers"
      :productVariants="waitlistData[0].product_variant"
      :userEmails="waitlistData[0].user_emails"
      @refresh-data="getCutsomers"
    ></WidgetContent>

    <!-- if there is no products show empty component -->
    <div class="empty-data" v-else>
      <empty-mails
        :hideAddCustomer="true"
        emptyEmailsMessage="No waitlist emails for this product"
      ></empty-mails>
    </div>
  </section>
  <section class="install" v-else>
    <h3>please Install your waitlist app</h3>
    <a href="https://bigpawoliveoil.lexmodo.com/plugins/waitlist"
      >Install now</a
    >
  </section>
  <ConfirmDialog style="border-radius: 12px"></ConfirmDialog>
</template>

<script>
import axios from "axios";

import ConfirmDialog from "primevue/confirmdialog";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import WidgetContent from "@/components/Widgets/WidgetContent/WidgetContent.vue";

export default {
  data() {
    return {
      selectedCustomers: [],
      waitlistData: [],
      objectProductWidget: {},
      wdgetsStatus: true,
    };
  },
  emits: ["routeName"],
  props: ["widgetData"],
  mounted() {
    console.log("Widget component");
    console.log(this.widgetData.product_widget);
    console.log(this.widgetData.meta.token);
    console.log(sessionStorage.getItem("auth_waitlist"));
    this.objectProductWidget = this.widgetData.product_widget;
    this.wdgetsStatus = this.widgetData.product_widget.product_widget;
    // this.$emit("routeName", "Waitlist Widget");
    // this.objectProductWidget = JSON.parse(
    //   new URLSearchParams(window.location.search).get("product_widget")
    // );
    // this.wdgetsStatus = this.objectProductWidget.product_widget;
    document.body.style.display = "inline";
    document.getElementById("body")?.classList.remove("d-none");
    this.getCutsomers();
  },
  methods: {
    getCutsomers() {
      // bps &widgets=1&pid=Fk5LL2VFj6TPs6uWEfXPrS&vid=67076064-5581-513b-b25e-92285b6bc8ef
      //softcomfort &widgets=1&pid=QMrcT8QWUzq3pP7unDV68t&vid=bd064f32-289d-5f1c-b292-622d3b3bc2ac
      axios
        .get(`https://waitlist-api.numinix.com/api/WaitListsByProduct`, {
          headers: this.getHeader(),
          params: { product_id: this.objectProductWidget.product_id },
        })
        .then((res) => {
          if (res.data.status === "success") {
            this.waitlistData = res.data.payload;
          }
        })
        .catch((err) => console.log(err));
    },
    getHeader() {
      return {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("auth_waitlist")}`,
      };
    },
    deleteSelected() {
      this.$confirm.require({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          let that = this;
          this.selectedCustomers.forEach(function (customer, index) {
            axios
              .post(
                `https://waitlist-api.numinix.com/api/destroy`,
                { row_identifier: customer.row_identifier },
                { headers: that.getHeader() }
              )
              .then(function () {
                if (that.selectedCustomers.length - 1 === index) {
                  // if this is the last request refresh the table
                  // Request data again & clear selected checkbox
                  that.getCutsomers();
                  that.selectedCustomers = [];
                }
              })
              .catch((err) => console.log(err));
          });
        },
        reject: () => {
          // callback to execute when user rejects the action
          // clear the checkbox selection:
          this.selectedCustomers = [];
        },
      });
    },
  },
  components: {
    TabPanel,
    TabView,
    // UpperBar,
    // StatisticsComponent,
    ConfirmDialog,
    WidgetContent,
  },
};
</script>

<style lang="scss" scoped>
section.wdgt {
  background-color: lemonchiffon;
  padding: 40px 30px 120px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: #202223;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.tabs-panel {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

h3 {
  font-size: 20px;
  font-weight: 500;
  color: #202223;
}

:deep(.p-datatable .p-datatable-thead > tr > th) {
  background-color: #323131;
  color: white;
  font-family: "Plus Jakarta Sans", sans-serif !important;
}
:deep(.p-datatable .p-datatable-thead > tr) {
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

:deep(.p-datatable .p-datatable-tbody > tr > td),
:deep(.p-paginator .p-paginator-current),
:deep(.p-component) {
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

:deep(.p-datatable .p-datatable-thead > tr > th):hover {
  background-color: #323131;
  color: white;
}
:deep(.p-tabview .p-tabview-nav) {
  padding-left: 20px;
  padding-right: 20px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.install {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    text-align: center;
    font-size: 21px;
    text-transform: capitalize;
  }
  a {
    background-color: #025ca1;
    color: #fff;
    text-transform: capitalize;
    font-size: 16px;
    border-radius: 5px;
    padding: 9px 15px;
    margin-top: 21px;
  }
}

.delete-btn:disabled {
  cursor: auto;
}
.empty-data {
  border-radius: 12px;
  overflow: hidden;
}
</style>
