<template>
  <div class="tab-container">
    <form @submit.prevent="saveData">
      <div class="left-content">
        <div>
          <header>
            <h6>“Notify me” Widgets</h6>
            <div>
              <p>"Notify me" button status on your website:</p>
              <div
                class="badge"
                :class="
                  store.NotifyBtnProductPageInline === 'inlineForm' &&
                  'badge-disable'
                "
              >
                <span class="badge-text">{{
                  store.NotifyBtnProductPageInline === "inlineForm"
                    ? "Disabled"
                    : "Active"
                }}</span>
              </div>
            </div>
          </header>

          <hr />

          <div class="product-page">
            <div>
              <p>Product Page</p>
              <div>
                <SwitchInput v-model="activeChecked">Active</SwitchInput>
              </div>
            </div>
            <base-select
              :options="['inline Button', 'inline Form']"
              :default="`inline ${this.store.NotifyBtnProductPageInline.slice(
                6
              )}`"
              class="select"
              @input-select="selectFn"
            ></base-select>
            <ul
              v-if="store.NotifyBtnProductPageInline === 'inlineForm'"
              style="color: #6d6d6d; list-style-type: disc; padding-left: 20px"
            >
              <li>
                Inline Form Styles can be setted from
                <button
                  type="button"
                  @click="setTab('subscribe-form')"
                  style="
                    text-decoration: underline;
                    color: #ffb054;
                    outline: none;
                    font-weight: 600;
                  "
                >
                  Subscriber Form tab.
                </button>
              </li>
              <li>Inline Button is disabled.</li>
            </ul>
          </div>
          <hr v-if="store.NotifyBtnProductPageInline === 'inlineButton'" />
          <BaseAccordion
            v-if="store.NotifyBtnProductPageInline === 'inlineButton'"
          >
            <template #title>
              <span class="title">Content</span>
            </template>
            <template #content>
              <label-input
                v-model="store.NotifyBtnButtonContent"
                class="content-section-body"
                maxLength="35"
                @input-changed="newChanges"
                >Text</label-input
              >
            </template>
          </BaseAccordion>

          <hr />

          <BaseAccordion
            v-if="store.NotifyBtnProductPageInline === 'inlineButton'"
          >
            <template #title>
              <span>Styles</span>
            </template>
            <template #content>
              <div class="styles-section-body">
                <div class="grid2 pad-14">
                  <color-input
                    @input-changed="newChanges"
                    v-model="store.NotifyBtnTextColor"
                    >Text Color</color-input
                  >
                  <color-input
                    @input-changed="newChanges"
                    v-model="store.NotifyBtnBgColor"
                    >Background color</color-input
                  >
                  <color-input
                    @input-changed="newChanges"
                    v-model="store.NotifyBtnBorderColor"
                    >Border color</color-input
                  >
                  <pixels-input
                    @input-changed="newChanges"
                    v-model="store.NotifyBtnBorderRadius"
                    >Border radius</pixels-input
                  >
                  <div>
                    <pixels-input
                      @input-changed="newChanges"
                      v-model="store.NotifyBtnBorderSize"
                      >Border size</pixels-input
                    >
                    <p v-if="store.NotifyBtnBorderSize > 10" style="color: red">
                      Border size can't exceed 10px
                    </p>
                    <p v-if="store.NotifyBtnBorderSize < 0" style="color: red">
                      Border size can't be negative value
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </BaseAccordion>
        </div>
      </div>
      <div class="actions">
        <base-button class="flat" @click="discardChanges" type="button"
          >Discard</base-button
        >
        <base-button type="submit">Save Changes</base-button>
      </div>
    </form>

    <div class="right-content">
      <div class="img-containers">
        <img src="@/assets/images/apps/widgets_tab/notify-me/1.png" alt="" />
        <div class="mid-images">
          <img
            src="@/assets/images/apps/widgets_tab/notify-me/2.png"
            style="width: 50%"
            alt=""
          />
          <div class="btns-container">
            <img
              style="width: 100%"
              src="@/assets/images/apps/widgets_tab/notify-me/3.png"
              alt=""
            />
            <button class="fake-btn">Add to cart</button>
            <button
              class="btn"
              :disabled="!activeChecked"
              :style="buttonStyle"
              v-if="store.NotifyBtnProductPageInline === 'inlineButton'"
            >
              {{ store.NotifyBtnButtonContent }}
            </button>
            <div
              v-if="store.NotifyBtnProductPageInline === 'inlineForm'"
              class="inline-form-container"
            >
              <img
                src="@/assets/images/apps/widgets_tab/notify-me/inlineForm/1.png"
                alt=""
              />
              <img
                src="@/assets/images/apps/widgets_tab/notify-me/inlineForm/2.png"
                alt=""
              />
              <button
                class="btn"
                :disabled="!activeChecked"
                :style="formButtonStyle"
              >
                Notify Me
              </button>
            </div>
          </div>
        </div>
        <img
          src="@/assets/images/apps/widgets_tab/notify-me/4.png"
          alt=""
          style="border-width: 1px solid black"
        />
      </div>
    </div>
  </div>
  <success-message v-show="showAlert"> Saved Sucessfully! </success-message>
</template>

<script>
import BaseAccordion from "@/components/UI/BaseAccordion.vue";
import SwitchInput from "@/components/UI/SwitchInput.vue";
import { waitListStoreFront } from "@/services/waitListStoreFront";

export default {
  props: ["setTab"],
  components: { BaseAccordion, SwitchInput },
  computed: {
    buttonStyle() {
      return {
        color: this.store.NotifyBtnTextColor,
        backgroundColor: this.store.NotifyBtnBgColor,
        borderColor: this.store.NotifyBtnBorderColor,
        borderRadius: this.store.NotifyBtnBorderRadius + "px",
        borderWidth:
          this.store.NotifyBtnBorderSize > 10
            ? "10px"
            : this.store.NotifyBtnBorderSize + "px",
      };
    },
    formButtonStyle() {
      return {
        color: this.store.NotifyFormTextColorBtn,
        backgroundColor: this.store.NotifyFormBgColorBtn,
        borderColor: this.store.NotifyFormBorderColorBtn,
        borderRadius: this.store.NotifyFormBorderRadiusBtn + "px",
        borderWidth:
          this.store.NotifyFormBorderSizeBtn > 10
            ? "10px"
            : this.store.NotifyFormBorderSizeBtn + "px",
      };
    },
  },
  data() {
    return {
      store: waitListStoreFront(),
      showAlert: false,
      activeChecked: true,
    };
  },
  methods: {
    discardChanges() {
      // This method should discard changes made by user on inputs
      // Discard changes by recalling the get API to get last inputs data
      // only discard if the user has made some changes
      // else show a message for him that changes already discarded
      if (this.store.unsavedChanges) {
        this.store.getWidgetData();
      } else {
        this.$toast.add({
          severity: "info",
          summary: "No Changes to be discarded",
          detail: "All changes are already discarded",
          life: 1500,
        });
      }
    },
    newChanges() {
      this.store.unsavedChanges = true;
    },
    selectFn(value) {
      this.store.NotifyBtnProductPageInline = value.split(" ").join("");
      this.store.unsavedChanges = true;
    },

    saveData() {
      if (this.store.unsavedChanges) {
        this.store.submitWidgetData();
        // if (this.store.error) {
        //   return;
        // }
        this.showAlert = true;
        setTimeout(this.hideAlert, 1500);
      } else {
        this.$toast.add({
          severity: "info",
          summary: "Changes up to date",
          detail: "Please make some changes to be saved",
          life: 1500,
        });
      }
    },
    hideAlert() {
      this.showAlert = false;
    },
  },
};
</script>

<style scoped>
.tab-container {
  display: grid;
  grid-template-columns: 0.45fr 0.55fr;
  grid-column-gap: 36px;
  padding: 36px 23px 36px 27px;
  width: 100%;
  min-height: 1312px;
}
.right-content {
  position: relative;
  background-color: #fff;
}

.btn {
  outline: none;
  height: 45px;
  font-weight: 600;
}

.btn:disabled {
  cursor: not-allowed;
  background-color: #e7e7e7 !important;
}

.img-containers {
  min-height: 550px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 30px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
}
.mid-images {
  display: flex;
  gap: 30px;
}

.btns-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 30px;
}
.fake-btn {
  height: 45px;
  font-weight: 600;
  color: #6d6d6d;
  background: #e7e7e7;
  border-radius: 8px;
  outline: none;
}

.inline-form-container {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
}

.left-content {
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow: hidden;
}

.left-content header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 26px;
  padding-bottom: 27px;
}

.left-content header div {
  display: flex;
  justify-content: space-between;
}

h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.badge {
  height: 24px;
  padding: 2px 10px;
  background-color: #9fd5ff;
  border-radius: 500px;
}
.badge-disable {
  background-color: lightpink;
}

.badge-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #004982;
}
.badge-disable .badge-text {
  color: red;
}

.product-page {
  padding: 24px 23px 34px 20px;
  display: flex;
  flex-direction: column;
  gap: 19px;
}

.product-page > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 'Content' section style */
.content-section-body {
  padding: 2px 0 29px 0;
  /* margin-bottom: 29px; */
}

/* 'Styles' section style */
.styles-section-body {
  padding-top: 6px;
  padding-bottom: 32px;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 14px;
  grid-row-gap: 18px;
}

.styles-section-checkbox {
  padding-bottom: 20px;
  display: flex;
  gap: 3px;
  align-items: center;
}

/* 'Edit Code' section style */
.edit-code-section-content {
  padding-top: 2px;
  padding-bottom: 24px;
}
.edit-code-section-content label {
  display: block;
  margin-bottom: 3px;
}
.edit-code-section-content textarea {
  padding: 8px 13px;
  width: 100%;
  height: 162px;
  background: #fbfbfb;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  outline: none;
  resize: none;
}

/* Style actions buttons */
.actions {
  margin-top: 27px;
  display: flex;
  gap: 15px;
}

@media only screen and (max-width: 850px) {
  .tab-container {
    grid-template-columns: 0.9fr;
    justify-content: center;
    row-gap: 30px;
    min-height: auto;
  }
  div.right-content {
    order: -1;
  }
}
</style>
