<template>
  <div class="backdrop">
    <form class="add-customer-form" @submit.prevent="addCustomer">
      <div class="header">
        <h5>Add customer</h5>

        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="$emit('hideModal')"
        >
          <path
            d="M9.41385 7.99985L15.7069 1.70685C15.8944 1.51934 15.9997 1.26503 15.9997 0.999849C15.9997 0.734673 15.8944 0.480357 15.7069 0.292849C15.5193 0.105341 15.265 0 14.9998 0C14.7347 0 14.4804 0.105341 14.2928 0.292849L7.99985 6.58585L1.70685 0.292849C1.614 0.200005 1.50378 0.126356 1.38247 0.0761092C1.26117 0.0258621 1.13115 0 0.999849 0C0.868547 0 0.738531 0.0258621 0.617224 0.0761092C0.495916 0.126356 0.385694 0.200005 0.292849 0.292849C0.105341 0.480357 0 0.734673 0 0.999849C0 1.26503 0.105341 1.51934 0.292849 1.70685L6.58585 7.99985L0.292849 14.2928C0.105341 14.4804 0 14.7347 0 14.9998C0 15.265 0.105341 15.5193 0.292849 15.7069C0.480357 15.8944 0.734673 15.9997 0.999849 15.9997C1.26503 15.9997 1.51934 15.8944 1.70685 15.7069L7.99985 9.41385L14.2928 15.7069C14.3855 15.8 14.4957 15.874 14.617 15.9244C14.7383 15.9749 14.8684 16.0009 14.9998 16.0009C15.1313 16.0009 15.2614 15.9749 15.3827 15.9244C15.504 15.874 15.6142 15.8 15.7069 15.7069C15.7998 15.6141 15.8735 15.5039 15.9238 15.3825C15.9742 15.2612 16 15.1312 16 14.9998C16 14.8685 15.9742 14.7385 15.9238 14.6172C15.8735 14.4958 15.7998 14.3856 15.7069 14.2928L9.41385 7.99985Z"
            fill="#575959"
          />
        </svg>
      </div>
      <p v-if="errorMessage" class="errorMessage">
        {{ errorMessage }}
      </p>
      <div class="double-inp">
        <div>
          <label for="email">Email</label>
          <input type="email" id="email" required v-model="email" />
        </div>
        <div>
          <label for="product-id">Product ID</label>
          <input type="text" id="prodcut-id" required v-model="productId" />
        </div>
      </div>
      <div class="single-inp">
        <label for="product-var">Product Variant</label>
        <input type="text" id="prodcut-var" required v-model="productVariant" />
      </div>
      <div class="actions">
        <base-button @click.prevent="$emit('hideModal')" class="small flat"
          >Back</base-button
        >
        <base-button class="small"> Add Customer </base-button>
      </div>
    </form>
  </div>
</template>

<script>
import TablesService from "@/services/tablesServices";

export default {
  emits: ["hideModal", "submitCustomer"],
  data() {
    return {
      productId: "",
      email: "",
      productVariant: "",
      errorMessage: null,
    };
  },
  methods: {
    addCustomer() {
      let sentData = {
        user_email: this.email,
        product_id: this.productId,
        product_variant: this.productVariant,
      };

      TablesService.addCustomer(sentData)
        .then((res) => {
          if (res.data.status === "success") {
            this.$emit("submitCustomer");
            // setTimeout(() => {
            //   this.$router.go(0);
            // }, 1500);
          } else {
            this.errorMessage = res.data.message;
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.errorMessage {
  font-weight: 600;
  padding: 5px;
  background-color: rgb(240, 153, 153);
  border: 1px solid #ff0033;
  color: #ff0033;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 5px;
}
.backdrop {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.35);
}

.add-customer-form {
  padding: 21px 36px 23px 33px;
  margin: 10% 20% auto auto;
  background-color: #fff;
  height: 366px;
  width: 640px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  z-index: 1005;
}

.add-customer-form input {
  height: 40px;
  border-radius: 8px;
  border: 1px solid #d8d8d8;
  padding-left: 20px;
}

.add-customer-form input:focus {
  border: 2px solid #a8a8a8;
  outline: none;
}

.add-customer-form h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 36px;
}

.double-inp {
  display: grid;
  column-gap: 12px;
  grid-template-columns: 1fr 1fr;
}

.add-customer-form label {
  display: block;
  margin-bottom: 4px;
}

.double-inp input {
  width: 100%;
  margin-bottom: 23px;
}

.actions {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-top: auto;
}

.actions button:first-child {
  color: #ffb054;
}
.actions button:last-child {
  color: #fff;
}

.single-inp input {
  width: 100%;
}

div.header svg {
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .add-customer-form {
    padding: 18px 30px;
    height: 340px;
    width: 600px;
  }
}
@media only screen and (max-width: 1000px) {
  .add-customer-form {
    padding: 16px 25px;
    height: 335px;
    width: 550px;
  }
}
@media only screen and (max-width: 800px) {
  .add-customer-form {
    padding: 14px 20px;
    height: 330px;
    width: 500px;
  }
  .add-customer-form h5 {
    margin-bottom: 20px;
  }

  .double-inp input {
    width: 100%;
    margin-bottom: 15px;
  }
}
</style>
