<template>
  <div>
    <div id="mainDiv">
      <label :for="id">
        <slot></slot>
      </label>
      <div id="inputsDiv">
        <input
          type="text"
          :value="modelValue"
          @blur="setColor"
          :id="id"
          :name="name"
          @change="$emit('inputChanged')"
          maxlength="7"
        />
        <input
          @blur="setColor"
          @change="$emit('inputChanged')"
          type="color"
          v-model="colorInput"
        />
      </div>
    </div>
    <p
      v-if="errorMessage"
      style="
        font-size: 16px;
        color: red;
        margin-bottom: 10px;
        overflow-wrap: break-word;
      "
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
export default {
  watch: {
    colorInput(value) {
      this.$emit("update:modelValue", value);
    },
    modelValue(value) {
      this.colorInput = value;
    },
  },
  data() {
    return {
      colorInput: this.modelValue,
      errorMessage: "",
    };
  },
  props: ["defaultValue", "id", "name", "modelValue"],
  emits: ["update:modelValue", "inputChanged"],
  methods: {
    setColor(event) {
      this.errorMessage = "";
      let value = event.target.value;
      if (value.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)) {
        // if user input is correct
        if (value.length === 4) {
          this.colorInput = value + value.slice(1, 4);
        } else {
          this.colorInput = value;
        }
        this.$emit("update:modelValue", this.colorInput);
      } else if (value.match(/^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)) {
        // if user input is correct but missing "#"
        if (value.length === 3) {
          this.colorInput = "#" + value + value;
        } else {
          this.colorInput = "#" + value;
        }
        this.$emit("update:modelValue", this.colorInput);
      } else {
        // if user input is invalid
        this.$emit("update:modelValue", value);
        this.errorMessage = "invalid color format";
      }
    },
  },
};
</script>

<style scoped>
#mainDiv {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
div#inputsDiv {
  position: relative;
}

div#inputsDiv input[type="color"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
}

input[type="color"] {
  overflow: hidden;
  padding: 0;
  height: 30px;
  width: 45px;
  border-radius: 4px;
  border: 1px solid #cecece;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}
input[type="text"] {
  width: 100%;
  padding-left: 20px;
  height: 45px;
  color: #202223;
  background-color: #fbfbfb;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

input[type="text"]:focus {
  outline: 1px solid #1575bf;
}

/* @media only screen and (max-width: 380px) {
  input[type="text"] {
    width: 200px;
  }
  div#inputsDiv input[type="color"] {
    right: 62px;
  }
} */
</style>
