<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="#6D6D6D"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 1.5C0 0.671573 0.671573 0 1.5 0H16.5C17.3284 0 18 0.671573 18 1.5V16.5C18 17.3284 17.3284 18 16.5 18H1.5C0.671573 18 0 17.3284 0 16.5V1.5ZM7 4H15V6H7V4ZM15 8H7V10H15V8ZM7 12H15V14H7V12ZM4 6C4.55228 6 5 5.55228 5 5C5 4.44772 4.55228 4 4 4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6ZM5 9C5 9.55229 4.55228 10 4 10C3.44772 10 3 9.55229 3 9C3 8.44771 3.44772 8 4 8C4.55228 8 5 8.44771 5 9ZM4 14C4.55228 14 5 13.5523 5 13C5 12.4477 4.55228 12 4 12C3.44772 12 3 12.4477 3 13C3 13.5523 3.44772 14 4 14Z"
    />
  </svg>
</template>
