<template>
  <input :type="type || 'text'" :placeholder="placeholder" maxlength="50" />
</template>

<script>
export default {
  props: ["type", "placeholder", "width"],
};
</script>

<style scoped>
input {
  height: 45px;
  padding-left: 18px;
  border-radius: 8px;
  color: #6d6d6d;
  border: #d8d8d8 1px solid;
}

input:focus {
  outline: none;
}

.search-input {
  width: 316px;
  height: 45px;
  border-radius: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
</style>
