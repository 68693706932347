import axios from "axios";

export default {
  getHeader(importMode = false) {
    if (importMode) {
      return {
        Authorization: `Bearer ${sessionStorage.getItem("auth_waitlist")}`,
      };
    } else {
      return {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("auth_waitlist")}`,
      };
    }
  },
  async getAllExcludedProducts(params) {
    return axios
      .get(`https://waitlist-api.numinix.com/api/get-exclude-product`, {
        headers: this.getHeader(),
        params: {
          dt_params: JSON.stringify({
            ...params,
          }),
        },
      })
      .then((res) => res.data)
      .catch((err) => console.log(err));
  },
  async getAllExports(params) {
    return axios
      .get(`https://waitlist-api.numinix.com/api/notifications-csv-file`, {
        headers: this.getHeader(),
        params: {
          dt_params: JSON.stringify({
            ...params,
          }),
        },
      })
      .then((res) => res.data)
      .catch((err) => console.log(err));
  },
  async getAllRequests(params) {
    return axios
      .get(`https://waitlist-api.numinix.com/api/all`, {
        params: {
          dt_params: JSON.stringify({
            ...params,
          }),
        },
        headers: this.getHeader(),
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => console.log(err));
  },
  async getAllCustomers(params) {
    return axios
      .get(`https://waitlist-api.numinix.com/api/getCustomersData`, {
        params: {
          dt_params: JSON.stringify({
            ...params,
          }),
        },
        headers: this.getHeader(),
      })
      .then((res) => res.data)
      .catch((err) => console.log(err));
  },

  async addCustomer(sentData) {
    return axios.post(
      "https://waitlist-api.numinix.com/api/addToWaitList",
      sentData,
      { headers: this.getHeader() }
    );
  },
  async excludeProduct(sentData) {
    return axios.post(
      "https://waitlist-api.numinix.com/api/exclude-product",
      sentData,
      { headers: this.getHeader() }
    );
  },

  async emailImport(sentData) {
    return axios
      .post("https://waitlist-api.numinix.com/api/import-csv-file", sentData, {
        headers: this.getHeader(true),
      })
      .then((res) => res.data);
  },

  async emailExport(sentData) {
    return axios
      .post("https://waitlist-api.numinix.com/api/export-csv-file", sentData, {
        headers: this.getHeader(),
      })
      .then((response) => response.data);
  },

  deleteSelectedRecordsByEmail(
    recordsArray = [],
    currentThis,
    recordsArrayName = "",
    loadDataFn
  ) {
    let that = currentThis;
    let thatObject = this;
    recordsArray.forEach(function (row, index) {
      axios
        .post(
          "https://waitlist-api.numinix.com/api/deleteEmail",
          {
            user_email: row.user_email,
          },
          { headers: thatObject.getHeader() }
        )
        .then(() => {
          if (that[recordsArrayName].length - 1 === index) {
            loadDataFn();
            that[recordsArrayName] = [];
          }
        })
        .catch((err) => console.log(err));
    });
  },
  deleteSelectedRecordsByRowId(
    recordsArray = [],
    currentThis,
    recordsArrayName = "",
    loadDataFn
  ) {
    let that = currentThis;
    let thatObject = this;
    recordsArray.forEach(function (row, index) {
      axios
        .post(
          `https://waitlist-api.numinix.com/api/destroy`,
          {
            row_identifier: row.row_identifier,
          },
          { headers: thatObject.getHeader() }
        )
        .then(() => {
          if (that[recordsArrayName].length - 1 === index) {
            loadDataFn();
            that[recordsArrayName] = [];
          }
        })
        .catch((err) => console.log(err));
    });
  },
  deleteSelectedRecordsByVariantId(
    recordsArray = [],
    currentThis,
    recordsArrayName = "",
    loadDataFn
  ) {
    let that = currentThis;
    let thatObject = this;
    recordsArray.forEach(function (row, index) {
      axios
        .post(
          `https://waitlist-api.numinix.com/api/delete-exclude-product`,
          {
            product_variant: row.product_variant,
          },
          { headers: thatObject.getHeader() }
        )
        .then(() => {
          if (that[recordsArrayName].length - 1 === index) {
            loadDataFn();
            that[recordsArrayName] = [];
          }
        })
        .catch((err) => console.log(err));
    });
  },
  getDateDiff(date) {
    const oneMinute = 60 * 1000; // Number of milliseconds in a minute
    const oneHour = 60 * oneMinute; // Number of milliseconds in an hour
    const oneDay = 24 * oneHour; // Number of milliseconds in a day
    const oneMonth = 30 * oneDay; // Approximate number of milliseconds in a month (assuming 30 days per month)
    const oneYear = 365 * oneDay; // Approximate number of milliseconds in a year (assuming 365 days per year)
    const currentDate = new Date(); // Current date and time
    const dateToCompare = new Date(date * 1000); // Date to compare
    const diffInMilliseconds = currentDate - dateToCompare; // Difference in milliseconds

    if (diffInMilliseconds < oneHour) {
      const diffInMinutes = Math.round(diffInMilliseconds / oneMinute);
      return `${diffInMinutes} minute(s) ago`;
    } else if (diffInMilliseconds < oneDay) {
      const diffInHours = Math.floor(diffInMilliseconds / oneHour);
      return `${diffInHours} hour(s) ago`;
    } else if (diffInMilliseconds < oneMonth) {
      const diffInDays = Math.floor(diffInMilliseconds / oneDay);
      return `${diffInDays} day(s) ago`;
    } else if (diffInMilliseconds < oneYear) {
      const diffInMonths = Math.floor(diffInMilliseconds / oneMonth);
      const remainingMilliseconds =
        diffInMilliseconds - diffInMonths * oneMonth;
      const diffInDays = Math.floor(remainingMilliseconds / oneDay);
      return `${diffInMonths} month(s) and ${diffInDays} day(s) ago`;
    } else {
      const diffInYears = Math.floor(diffInMilliseconds / oneYear);
      const remainingMilliseconds = diffInMilliseconds - diffInYears * oneYear;
      const diffInMonths = Math.floor(remainingMilliseconds / oneMonth);
      const remainingMilliseconds2 =
        remainingMilliseconds - diffInMonths * oneMonth;
      const diffInDays = Math.floor(remainingMilliseconds2 / oneDay);
      return `${diffInYears} year(s), ${diffInMonths} month(s), and ${diffInDays} day(s) ago`;
    }
  },
};
