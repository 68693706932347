<template>
  <div class="empty-page">
    <svg
      class="mail-svg"
      width="250"
      height="250"
      viewBox="0 0 250 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="125" cy="125" r="125" fill="#F2F5FA" />
      <g clip-path="url(#clip0_5965_225963)">
        <path
          d="M187.216 106.348L125.499 146.283L63.7821 106.348V44.631C63.7396 42.6686 65.296 41.0434 67.2585 41.0009C67.3098 40.9998 67.3612 40.9998 67.4126 41.0009H183.586C185.548 40.9583 187.174 42.5147 187.216 44.4772C187.217 44.5286 187.217 44.58 187.216 44.6314V106.348Z"
          fill="white"
        />
        <path
          d="M63.7825 106.349L42 91.8269L63.7825 70.0444V106.349Z"
          fill="#FFB154"
        />
        <path
          d="M209.001 91.8269L187.219 106.349V70.0444L209.001 91.8269Z"
          fill="#FFB154"
        />
        <path
          d="M187.216 106.022L208.999 91.8267V193.478C208.992 197.329 207.465 201.021 204.751 203.753L179.955 178.957L140.021 139.022L139.004 137.461L187.216 106.022Z"
          fill="#FD9C2A"
        />
        <path
          d="M179.955 178.957L204.75 203.752C202.019 206.467 198.327 207.993 194.476 208H56.5203C52.6696 207.993 48.9774 206.467 46.2461 203.752L71.0417 178.957L110.976 139.022L111.993 137.461L125.498 146.283L139.003 137.461L140.02 139.022L179.955 178.957Z"
          fill="#FFB154"
        />
        <path
          d="M111.994 137.461L110.978 139.022L71.0432 178.957L46.2476 203.752C43.5333 201.021 42.0068 197.329 42 193.478V91.8267L111.994 137.461Z"
          fill="#FD9C2A"
        />
      </g>
      <defs>
        <clipPath id="clip0_5965_225963">
          <rect
            width="167"
            height="167"
            fill="white"
            transform="translate(42 41)"
          />
        </clipPath>
      </defs>
    </svg>
    <h5>{{ emptyEmailsMessage || `No waitlist emails` }}</h5>
    <p>You've not added any emails yet</p>

    <base-button class="small" @click="showModal" v-if="!hideAddCustomer">
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.625 6.125H8.375V0.875C8.375 0.392 7.98387 0 7.5 0C7.01613 0 6.625 0.392 6.625 0.875V6.125H1.375C0.891125 6.125 0.5 6.517 0.5 7C0.5 7.483 0.891125 7.875 1.375 7.875H6.625V13.125C6.625 13.608 7.01613 14 7.5 14C7.98387 14 8.375 13.608 8.375 13.125V7.875H13.625C14.1089 7.875 14.5 7.483 14.5 7C14.5 6.517 14.1089 6.125 13.625 6.125Z"
          fill="#202223"
        />
      </svg>
      Add Customer
    </base-button>
  </div>
  <!-- Add Customer Modal -->
  <add-customer-modal
    v-if="showModalFlag"
    @hide-modal="hideModal"
    @submit-customer="submitCustomer"
  ></add-customer-modal>
  <!-- Alert Message -->
  <success-message v-show="showAlert">
    Customer added successfully!
  </success-message>
</template>

<script>
import AddCustomerModal from "@/components/AddCustomerModal.vue";

export default {
  props: ["hideAddCustomer", "emptyEmailsMessage"],
  components: {
    AddCustomerModal,
  },
  data() {
    return {
      showModalFlag: false,
      showAlert: false,
    };
  },
  methods: {
    showModal() {
      this.showModalFlag = true;
    },
    hideModal() {
      this.showModalFlag = false;
    },
    submitCustomer() {
      this.showAlert = true;
      setTimeout(this.hideAlert, 1500);
      this.hideModal();
    },
    hideAlert() {
      this.showAlert = false;
    },
  },
};
</script>

<style scoped>
.empty-page {
  height: 80vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding-top: 20vh;
  align-items: center;
}

.mail-svg {
  margin-bottom: 20px;
}

.empty-page h5 {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 5px;
}

.empty-page p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;
  color: #6d6d6d;
}
</style>
