<template>
  <div class="container">
    <div class="flex-row">
      <div class="left-flex">
        <p class="head-text">Button Display</p>
        <p class="body-text">
          Show the "Notify me" widget for not selectable variants.
        </p>
      </div>
      <div class="checkbox-group">
        <div class="checkbox-container">
          <input
            type="checkbox"
            id="nonLogged-users"
            v-model="store.hide_subscriber_form_non_logged_users"
            @click="newChanges"
          />
          <label for="nonLogged-users"
            >Hide Subscribe Form for non logged-in users</label
          >
        </div>
        <div class="checkbox-container">
          <input
            type="checkbox"
            id="logged-users"
            v-model="store.hide_subscriber_form_logged_users"
            @click="newChanges"
          />
          <label for="logged-users"
            >Hide Subscribe Form for logged-in users</label
          >
        </div>
      </div>
    </div>

    <hr class="hr-section" />

    <div class="flex-row">
      <div class="left-flex">
        <p class="head-text">Hide "Notify me" button on specific products</p>
        <p class="body-text">
          If you don’t want to show the “Notify me” button on specific products,
          add them to excluded products.
        </p>
      </div>
      <div class="tag-action">
        <div class="tag">
          <p class="head-text">Add products to hide “Notify me” button</p>
          <p>
            Add products to excluded products that you don’t want to have the
            “notify me” button. You can remove the added product
            later.<router-link to="/reports/excluded-list">
              <button
                @click="setTab('excluded-products')"
                style="
                  text-decoration: underline;
                  outline: none;
                  color: #1575bf;
                "
              >
                Go to excluded product tab.
              </button>
            </router-link>
          </p>
        </div>
        <div class="actions">
          <base-button class="flat" @click="discardChanges"
            >Discard</base-button
          >
          <base-button @click="saveData">Save Changes</base-button>
        </div>
      </div>
    </div>
  </div>
  <success-message v-show="showAlert"> Saved Sucessfully! </success-message>
</template>

<script>
import { waitListStoreFront } from "@/services/waitListStoreFront";

export default {
  props: ["setTab"],
  emits: ["touchForm"],
  watch: {
    touchForm() {
      if (this.touched) {
        this.$emit("touchForm", true);
      }
    },
  },
  computed: {
    touchForm() {
      return `${
        this.store.hide_subscriber_form_logged_users +
        this.store.hide_subscriber_form_non_logged_users
      }`;
    },
  },
  updated() {
    this.touched = true;
  },
  methods: {
    discardChanges() {
      // This method should discard changes made by user on inputs
      // Discard changes by recalling the get API to get last inputs data
      // only discard if the user has made some changes
      // else show a message for him that changes already discarded
      if (this.store.unsavedChanges) {
        this.store.getWidgetData();
      } else {
        this.$toast.add({
          severity: "info",
          summary: "No Changes to be discarded",
          detail: "All changes are already discarded",
          life: 1500,
        });
      }
    },
    //2- Save data on user submit
    saveData() {
      if (this.store.unsavedChanges) {
        this.store.submitWidgetData();
        this.showAlert = true;
        setTimeout(this.hideAlert, 1500);
      } else {
        this.$toast.add({
          severity: "info",
          summary: "Changes up to date",
          detail: "Please make some changes to be saved",
          life: 1500,
        });
      }
    },
    hideAlert() {
      this.showAlert = false;
    },
    newChanges() {
      this.store.unsavedChanges = true;
    },
  },
  data() {
    return {
      store: waitListStoreFront(),

      showAlert: false,
      touched: false,
    };
  },
};
</script>

<style scoped>
.container {
  padding: 34px 23px 50px 27px;
}

.tag {
  display: flex;
  flex-direction: column;
  gap: 19px;
  padding: 22px 27px;
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  max-width: 737px;
}

.tag-action {
  display: flex;
  flex-direction: column;
  gap: 37px;
}

.tag-action button {
  align-self: flex-start;
}

.head-text {
  font-weight: 600;
}

.body-text {
  color: #6d6d6d;
}

.flex-row {
  display: flex;
  gap: 39px;
}

.left-flex {
  display: flex;
  flex-direction: column;
  gap: 11px;
  max-width: 393px;
}

.checkbox-group {
  padding: 22px 27px;
  max-width: 800px;
  border: 1px solid #d8d8d8;
  border-radius: 12px;
}
.checkbox-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 9px;
}

.checkbox-container input {
  cursor: pointer;
  margin-top: 3px;
}

.hr-checkbox {
  margin: 22px 0 19px 0;
}

.hr-section {
  margin: 20px 0 57px 0;
}

/* Style actions buttons */
.actions {
  display: flex;
  gap: 15px;
}
</style>
