import axios from "axios";
// import router from "@/router";

export default {
  getHeader() {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("auth_waitlist")}`,
    };
  },
  async getStatistics(date = {}) {
    return axios
      .post("https://waitlist-api.numinix.com/api/calculate", date, {
        headers: this.getHeader(),
      })
      .then((res) => res.data);
  },
  async getTopProducts(date = {}) {
    return axios
      .post("https://waitlist-api.numinix.com/api/topAdded", date, {
        headers: this.getHeader(),
      })
      .then((res) => res.data);
  },
  async getRecentProducts(date = {}) {
    return axios
      .post("https://waitlist-api.numinix.com/api/recentAdded", date, {
        headers: this.getHeader(),
      })
      .then((res) => res.data);
  },
  async getChartsData(date = {}) {
    return axios
      .post("https://waitlist-api.numinix.com/api/emails-statistics", date, {
        headers: this.getHeader(),
      })
      .then((res) => res.data);
  },
};
