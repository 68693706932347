import IconCloseSvg from './icons/IconCloseSvg.vue';

<template>
  <div class="main">
    <button>
      <IconCloseSvg />
      <span>Dismiss</span>
    </button>
  </div>
</template>

<script>
import IconCloseSvg from "./icons/IconCloseSvg.vue";

export default {
  components: { IconCloseSvg },
};
</script>

<style scoped>
.main {
  padding: 5px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 29px;
  top: 62px;
  width: 141px;
  background: #ffffff;
  box-shadow: 9px 6px 24px -2px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}

button {
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  height: 44px;
}

button:focus {
  background: #f6f6f7;
  border-radius: 6px;
  outline: none;
}
</style>
