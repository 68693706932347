<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.00004 4H12V6H6.00004V4Z" fill="#6D6D6D" />
    <path d="M10 8H6.00004V10H10V8Z" fill="#6D6D6D" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.00004 10V1.5C2.00004 0.671573 2.67162 0 3.50004 0H14.5C15.3285 0 16 0.671574 16 1.5V10H18L18 17.5C18 18.3284 17.3285 19 16.5 19H1.50004C0.671617 19 4.37498e-05 18.3284 4.37498e-05 17.5L0 10H2.00004ZM4.00004 12V2H14V12L9.00004 14.7711L4.00004 12Z"
      fill="#6D6D6D"
    />
  </svg>
</template>
