<template>
  <section>
    <nav>
      <ul>
        <li v-for="tab in navTabs" :key="tab.id">
          <router-link :to="'/reports/' + tab.route">
            <button
              :class="currentRoute === '/reports/' + tab.route ? 'active' : ''"
            >
              {{ tab.tabName }}
            </button>
          </router-link>
        </li>
      </ul>
      <hr class="nav-hr" />
    </nav>

    <div class="tab-content">
      <router-view></router-view>
      <ConfirmDialog></ConfirmDialog>
    </div>
  </section>
</template>
<script>
import ConfirmDialog from "primevue/confirmdialog";

export default {
  components: {
    ConfirmDialog,
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
  },
  emits: ["routeName"],
  data() {
    return {
      navTabs: [
        {
          id: 0,
          tabName: "Requests",
          tabComponent: "requests-table",
          route: "requests-list",
        },
        {
          id: 1,
          tabName: "Customers on Waitlist",
          tabComponent: "customers-table",
          route: "customers-list",
        },
        {
          id: 2,
          tabName: "Sent Notifications",
          tabComponent: "notification-tab",
          route: "notifications-list",
        },
        {
          id: 3,
          tabName: "Excluded Products",
          tabComponent: "excluded-products",
          route: "excluded-list",
        },
      ],
    };
  },
  mounted() {
    this.$emit("routeName", "Requests");
    // this.$router.push("/reports/requests-list");
  },
};
</script>

<style scoped>
section {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 10px 13px -2px #6a73790d;
  border: 1px solid #edeeef;
  border-radius: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

nav {
  display: flex;
  flex-direction: column;
  padding-left: 27px;
  padding-top: 22px;
  padding-right: 23px;
  height: 70px;
}

ul {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.nav-hr {
  border-bottom: 1px solid #cecece;
  margin-top: auto;
}

nav button {
  white-space: nowrap;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  height: 93%;
  color: #6d6d6d;
  outline: none;
  z-index: 1;
}

.active {
  box-shadow: 0 4px 0 #ffb054;
  color: #202223;
}
button.active {
  font-weight: 500;
}
</style>
