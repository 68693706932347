<template>
  <nav class="menu">
    <ul>
      <li v-for="item in navData" :key="item.tabRoute">
        <router-link :to="item.tabRoute">
          <span>
            <component :is="item.iconSvg"></component>
          </span>
          <span style="white-space: nowrap">{{ item.tabLabel }}</span>
        </router-link>
      </li>
    </ul>
  </nav>
  <nav class="hamburger-menu">
    <base-button class="flat" @click.prevent.stop="toggleMenu">
      <i class="pi pi-bars" style="font-size: 16px"></i>
    </base-button>
    <div v-if="menuVisible" class="anchors-container">
      <router-link
        :to="item.tabRoute"
        v-for="item in navData"
        :key="item.tabRoute"
      >
        <div class="icon-text-container">
          <span>
            <component :is="item.iconSvg"></component>
          </span>
          <span style="white-space: nowrap">{{ item.tabLabel }}</span>
        </div>
      </router-link>
    </div>
  </nav>
</template>

<script>
import IconDashboardSvg from "./icons/IconDashboardSvg.vue";
import IconNotificationEmailSvg from "./icons/IconNotificationEmailSvg.vue";
import IconSettingsSvg from "./icons/IconSettingsSvg.vue";
import IconWidgetsSvg from "./icons/IconWidgetsSvg.vue";
import IconRequestsSvg from "./icons/IconRequestsSvg.vue";
import BaseCard from "../UI/BaseCard.vue";

import { markRaw } from "vue";

export default {
  components: {
    IconDashboardSvg,
    IconNotificationEmailSvg,
    IconSettingsSvg,
    IconWidgetsSvg,
    IconRequestsSvg,
    BaseCard,
  },
  data() {
    return {
      menuVisible: false,
      navData: [
        {
          tabLabel: "Dashboard",
          tabRoute: "/waitlist-notification",
          iconSvg: markRaw(IconDashboardSvg),
        },
        {
          tabLabel: "Requests",
          tabRoute: "/reports",
          iconSvg: markRaw(IconRequestsSvg),
        },
        {
          tabLabel: "Widgets",
          tabRoute: "/widgets-store-front",
          iconSvg: markRaw(IconWidgetsSvg),
        },
        {
          tabLabel: "Notification Email",
          tabRoute: "/notification-email",
          iconSvg: markRaw(IconNotificationEmailSvg),
        },
        {
          tabLabel: "Settings",
          tabRoute: "/settings",
          iconSvg: markRaw(IconSettingsSvg),
        },
      ],
    };
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
  },
};
</script>

<style scoped>
nav.menu {
  padding: 0 28px;
  width: 100%;
  height: 65px;
  background-color: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px 10px 13px -2px #6a73790d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #6d6d6d;
  margin-bottom: 20px;
}
nav.hamburger-menu {
  display: none;
  width: 100%;
  background-color: #fff;
  padding: 15px;
  border: 1px solid #edeeef;
  box-shadow: 0px 10px 13px -2px rgba(106, 115, 121, 0.05);
  border-radius: 12px;
}

.anchors-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
}

.icon-text-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
ul {
  height: 100%;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 53px;
  border-bottom: 1px solid #cecece;
}

ul li:last-of-type {
  margin-left: auto;
}

ul li {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

ul li:hover {
  border-bottom: 3px solid #cecece;
}

.active-tab {
  border-bottom: 3px solid #ffb054;
  color: #202223;
}
a.active-tab :deep(svg) {
  fill: #202223;
}
a.active-tab :deep(path) {
  fill: #202223;
}

ul li span {
  margin-right: 10px;
}

li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #6d6d6d;
  height: 100%;
}

@media only screen and (max-width: 900px) {
  /* nav.menu {
    padding: 10px 28px;
    height: auto;
  }
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 15px;
    column-gap: 15px;
    border-bottom: 0px solid #cecece;
  } */
  nav.menu {
    display: none;
  }
  nav.hamburger-menu {
    display: block;
  }
}
</style>
