<template>
  <div class="tab-container">
    <form @submit.prevent="saveData">
      <div class="left-content">
        <div>
          <header>
            <h6>Subscriber Form</h6>
            <p v-if="store.NotifyBtnProductPageInline === 'inlineButton'">
              The subscription form appears after you click on "Notify me"
              button.
            </p>
            <p v-else>The subscription form appears as inline form.</p>
          </header>
          <hr />

          <base-accordion>
            <template #title>
              <span class="title">Content</span>
            </template>
            <template #content>
              <label-input
                maxLength="35"
                v-model="store.NotifyFormTitleContent"
                class="content-section-body"
                @input-changed="newChanges"
                >Title</label-input
              >
            </template>
          </base-accordion>

          <hr />

          <base-accordion>
            <template #title>
              <span>Email</span>
            </template>
            <template #content>
              <div class="email-section-body">
                <div class="grid2 pad-14">
                  <label-input
                    @input-changed="newChanges"
                    v-model="store.NotifyFormEmailLabel"
                    maxLength="40"
                    >Email label</label-input
                  >
                  <label-input
                    v-model="store.NotifyFormEmailPlaceholder"
                    @input-changed="newChanges"
                    >Email Placeholder</label-input
                  >
                </div>
                <label-input
                  v-model="store.NotifyFormWrongEmailError"
                  maxLength="45"
                  @input-changed="newChanges"
                  >Wrong email address error</label-input
                >
              </div>
            </template>
          </base-accordion>

          <hr />

          <base-accordion>
            <template #title>
              <span>Button</span>
            </template>
            <template #content>
              <div class="button-section-body">
                <label-input
                  v-model="store.NotifyFormButtonContent"
                  maxLength="35"
                  @input-changed="newChanges"
                  >Submit button text</label-input
                >
                <div class="grid2 pad-14">
                  <color-input
                    @input-changed="newChanges"
                    v-model="store.NotifyFormTextColorBtn"
                    >Text color</color-input
                  >
                  <color-input
                    @input-changed="newChanges"
                    v-model="store.NotifyFormBgColorBtn"
                    >Background color</color-input
                  >
                  <color-input
                    @input-changed="newChanges"
                    v-model="store.NotifyFormBorderColorBtn"
                    >Border color</color-input
                  >
                  <pixels-input
                    @input-changed="newChanges"
                    v-model="store.NotifyFormBorderRadiusBtn"
                    >Border radius</pixels-input
                  >
                  <div>
                    <pixels-input
                      @input-changed="newChanges"
                      v-model="store.NotifyFormBorderSizeBtn"
                      >Border size</pixels-input
                    >
                    <p
                      v-if="store.NotifyFormBorderSizeBtn > 10"
                      style="color: red"
                    >
                      Border size can't exceed 10px
                    </p>
                    <p
                      v-if="store.NotifyFormBorderSizeBtn < 0"
                      style="color: red"
                    >
                      Border size can't be negative value
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </base-accordion>
        </div>
      </div>
      <div class="actions">
        <base-button class="flat" @click="discardChanges" type="button"
          >Discard</base-button
        >
        <base-button type="submit">Save Changes</base-button>
      </div>
    </form>

    <div class="right-content">
      <div
        class="imgs-container"
        :style="
          store.NotifyBtnProductPageInline === 'inlineForm' && 'height: 350px;'
        "
      >
        <div
          :style="
            store.NotifyBtnProductPageInline === 'inlineForm' &&
            'background: #fff;'
          "
          class="backdrop"
        >
          <form @submit.prevent class="form">
            <h6 style="margin-bottom: 17px">
              {{ store.NotifyFormTitleContent }}
            </h6>
            <label style="margin-bottom: 3px" for="email">{{
              store.NotifyFormEmailLabel
            }}</label>
            <div style="position: relative; margin-bottom: 3px">
              <svg
                style="
                  position: absolute;
                  top: 50%;
                  left: 20px;
                  transform: translateY(-50%);
                "
                width="20"
                height="15"
                viewBox="0 0 20 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 2.82443V13C0 13.8284 0.671573 14.5 1.5 14.5H18.5C19.3284 14.5 20 13.8284 20 13V2.82433L10.5038 8.36379C10.1924 8.54542 9.80741 8.54542 9.49605 8.36379L0 2.82443Z"
                  fill="#575959"
                />
                <path
                  d="M19.4433 0.833666C19.1856 0.625 18.8574 0.5 18.5 0.5H1.5C1.14256 0.5 0.814324 0.625022 0.556631 0.833722L9.99992 6.34231L19.4433 0.833666Z"
                  fill="#575959"
                />
              </svg>
              <input
                type="text"
                style="height: 45px"
                :style="inputStyle"
                id="email"
                :placeholder="store.NotifyFormEmailPlaceholder"
              />
            </div>
            <p
              style="color: #b00020; font-size: 12px"
              v-if="store.NotifyFormWrongEmailError"
            >
              {{ store.NotifyFormWrongEmailError }}
            </p>

            <button
              style="
                height: 45px;
                font-weight: 600;
                outline: none;
                margin-top: 25px;
              "
              :style="buttonStyle"
            >
              {{ store.NotifyFormButtonContent }}
            </button>
          </form>
        </div>
        <img
          src="@/assets/images/apps/widgets_tab/sub-form/1.png"
          v-if="store.NotifyBtnProductPageInline === 'inlineButton'"
        />
        <img
          src="@/assets/images/apps/widgets_tab/sub-form/2.png"
          v-if="store.NotifyBtnProductPageInline === 'inlineButton'"
        />
        <img
          src="@/assets/images/apps/widgets_tab/sub-form/3.png"
          v-if="store.NotifyBtnProductPageInline === 'inlineButton'"
        />
      </div>
    </div>
  </div>
  <success-message v-show="showAlert"> Saved Sucessfully! </success-message>
</template>
<script>
import BaseAccordion from "@/components/UI/BaseAccordion.vue";
import { waitListStoreFront } from "@/services/waitListStoreFront";

export default {
  emits: ["touchForm"],
  props: ["setTab"],
  components: { BaseAccordion },
  computed: {
    inputStyle() {
      return {
        paddingLeft: "58px",
        width: "100%",
        fontFamily: "inherit",
        color: "inherit",
        borderRadius: "8px",
        border: "1px solid #d8d8d8",
        backgroundColor: "#fbfbfb",
      };
    },
    buttonStyle() {
      return {
        color: this.store.NotifyFormTextColorBtn,
        backgroundColor: this.store.NotifyFormBgColorBtn,
        borderColor: this.store.NotifyFormBorderColorBtn,
        borderRadius: this.store.NotifyFormBorderRadiusBtn + "px",
        // borderWidth: this.store.NotifyFormBorderSizeBtn + "px",
        borderWidth:
          this.store.NotifyFormBorderSizeBtn > 10
            ? "10px"
            : this.store.NotifyFormBorderSizeBtn + "px",
      };
    },
    //Property to check if any change happen on this inputs
    touchForm() {
      return `${
        this.store.NotifyFormTitleContent +
        this.store.NotifyFormEmailLabel +
        this.store.NotifyFormEmailPlaceholder +
        this.store.NotifyFormWrongEmailError +
        this.store.NotifyFormButtonContent +
        this.store.NotifyFormTextColorBtn +
        this.store.NotifyFormBgColorBtn +
        this.store.NotifyFormBorderRadiusBtn +
        this.store.NotifyFormBorderSizeBtn +
        this.store.NotifyFormBorderColorBtn
      }`;
    },
  },
  methods: {
    discardChanges() {
      // This method should discard changes made by user on inputs
      // Discard changes by recalling the get API to get last inputs data
      // only discard if the user has made some changes
      // else show a message for him that changes already discarded
      if (this.store.unsavedChanges) {
        this.store.getWidgetData();
      } else {
        this.$toast.add({
          severity: "info",
          summary: "No Changes to be discarded",
          detail: "All changes are already discarded",
          life: 1500,
        });
      }
    },
    newChanges() {
      this.store.unsavedChanges = true;
    },
    saveData() {
      if (this.store.unsavedChanges) {
        this.store.submitWidgetData();
        this.showAlert = true;
        setTimeout(this.hideAlert, 1500);
      } else {
        this.$toast.add({
          severity: "info",
          summary: "Changes up to date",
          detail: "Please make some changes to be saved",
          life: 1500,
        });
      }
    },
    hideAlert() {
      this.showAlert = false;
    },
  },
  watch: {
    touchForm() {
      if (this.formTouchFlag) {
        this.$emit("touchForm", true);
      }
      this.formTouchFlag = true;
    },
  },

  data() {
    return {
      store: waitListStoreFront(),
      formTouchFlag: false,
      showAlert: false,
    };
  },
};
</script>

<style scoped>
.tab-container {
  display: grid;
  grid-template-columns: 0.45fr 0.55fr;
  grid-column-gap: 36px;
  padding: 36px 23px 36px 27px;
  width: 100%;
  min-height: 1312px;
}

.left-content {
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow: hidden;
}

.left-content header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 26px;
  padding-bottom: 27px;
}

.left-content header div {
  display: flex;
  justify-content: space-between;
}

h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.product-page {
  padding: 24px 23px 34px 20px;
  display: flex;
  flex-direction: column;
  gap: 19px;
}

/* 'Content' section style */
.content-section-body {
  padding: 2px 0 29px 0;
}

/* 'Email' section style */
.email-section-body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 2px;
  padding-bottom: 34px;
}

/* 'Button' section style */
.button-section-body {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-top: 2px;
  padding-bottom: 33px;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 14px;
  grid-row-gap: 18px;
}

/* 'Success Subscription Mail' section style */
.success-mail-section-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 2px;
  padding-bottom: 31px;
}
.success-mail-section-content label {
  display: block;
  margin-bottom: 3px;
}
.success-mail-section-content textarea {
  padding: 8px 13px;
  width: 100%;
  height: 162px;
  background: #fbfbfb;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  outline: none;
  resize: none;
}

/* Style actions buttons */
.actions {
  margin-top: 27px;
  display: flex;
  gap: 15px;
}

.right-content {
  position: relative;
  background-color: #fff;
}
.backdrop {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
}
.form {
  padding: 45px 23px 33px;
  background-color: #fff;
  width: 376px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  z-index: 1005;
  display: flex;
  border: 1px solid #ccc;
}
.imgs-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  padding: 39px 53px 49px 32px;
  overflow: hidden;
}
@media only screen and (max-width: 800px) {
  .tab-container {
    grid-template-columns: 0.9fr;
    justify-content: center;
    row-gap: 30px;
    min-height: auto;
  }
  div.right-content {
    order: -1;
  }
}
</style>
