<template>
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.415334 0.188759C0.73957 -0.0449367 1.17182 -0.0631242 1.51454 0.142509L16.5145 9.14251C16.8157 9.32323 17 9.64874 17 10C17 10.3513 16.8157 10.6768 16.5145 10.8575L1.51454 19.8575C1.17182 20.0631 0.73957 20.0449 0.415334 19.8112C0.0910977 19.5775 -0.0628454 19.1732 0.023857 18.7831L1.75343 11L7 11C7.55228 11 8 10.5523 8 10C8 9.44772 7.55228 9 7 9L1.75343 9L0.023857 1.21693C-0.0628454 0.826771 0.0910977 0.422456 0.415334 0.188759Z"
      fill="#6D6D6D"
    />
  </svg>
</template>
