<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="#6D6D6D"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.09371 3.75H8.78121C8.97026 3.75 9.12503 3.90487 9.12503 4.09371V6.90621C9.12503 7.09522 8.97021 7.25004 8.78121 7.25004H4.09371C3.90487 7.25004 3.75 7.09527 3.75 6.90621V4.09371C3.75 3.90482 3.90482 3.75 4.09371 3.75Z"
      stroke-width="1.5"
    />
    <path
      d="M4.09371 10H8.78121C8.97021 10 9.12503 10.1548 9.12503 10.3438V16.9063C9.12503 17.0952 8.97026 17.25 8.78121 17.25H4.09371C3.90482 17.25 3.75 17.0952 3.75 16.9063V10.3438C3.75 10.1548 3.90487 10 4.09371 10Z"
      stroke-width="1.5"
    />
    <path
      d="M12.2188 13.75H16.9063C17.0952 13.75 17.25 13.9048 17.25 14.0938V16.9063C17.25 17.0952 17.0952 17.25 16.9063 17.25H12.2188C12.0298 17.25 11.875 17.0952 11.875 16.9063V14.0938C11.875 13.9048 12.0298 13.75 12.2188 13.75Z"
      stroke-width="1.5"
    />
    <path
      d="M16.1563 3.75H16.9063C17.0952 3.75 17.25 3.90482 17.25 4.09371V10.6562C17.25 10.8453 17.0952 11 16.9063 11H12.2188C12.0298 11 11.875 10.8452 11.875 10.6562V4.09371C11.875 3.90487 12.0298 3.75 12.2188 3.75H16.1563Z"
      stroke-width="1.5"
    />
  </svg>
</template>
