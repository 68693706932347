<template>
  <div class="main">
    <p style="margin-bottom: 20px; color: lightcoral" v-if="settingsWarning">
      Please make sure to fill in your setting to make waitlist work properly.
      <router-link to="/settings">
        <button style="font-weight: 600; text-decoration: underline">
          Go to settings tab
        </button>
      </router-link>
    </p>
    <getting-started></getting-started>

    <div class="title-bar">
      <p>View analytics for your store.</p>
      <div>
        <drop-down
          v-model="selectedTime"
          :options="times"
          optionLabel="name"
          optionValue="value"
          @change="timeFilterChange"
          style="
            width: 150px;
            color: #202223;
            border: 1px solid #d8d8d8;
            border-radius: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
          "
        ></drop-down>
      </div>
    </div>
    <stat-analytics :statsData="statsData"></stat-analytics>
    <hr />
    <stat-chart :chartData="chartData" :dateFromTo="dateFromTo"></stat-chart>
    <section class="tables">
      <products-tables
        title="Top Products Added to Waitlist"
        :data="firstTableData"
        style="width: 100%"
        key="0"
      ></products-tables>
      <products-tables
        title="Most Recent Products Added to Waitlist"
        :data="secondTableData"
        style="width: 100%"
        key="1"
      ></products-tables>
    </section>
  </div>
</template>

<script>
import GettingStarted from "@/components/DashBoardComponents/GettingStarted/GettingStarted.vue";
import StatAnalytics from "@/components/DashBoardComponents/StatAnalytics/StatAnalytics.vue";
import ProductsTables from "@/components/DashBoardComponents/ProductsTables/ProductsTables.vue";
import StatChart from "@/components/DashBoardComponents/StatChart/StatChart.vue";
import DropDown from "primevue/dropdown";

import dashboardService from "@/services/dashboardService";
import settingsService from "@/services/settingsService";

export default {
  emits: ["routeName"],

  components: {
    GettingStarted,
    StatAnalytics,
    ProductsTables,
    StatChart,
    DropDown,
  },
  methods: {
    getStatistics(date = {}) {
      dashboardService.getStatistics(date).then((data) => {
        if (data.status === "success") {
          this.statsData = data.payload;
        }
      });
    },
    getTopProducts(date = {}) {
      dashboardService.getTopProducts(date).then((data) => {
        if (data.status === "success") {
          this.firstTableData = data.payload.data;
        }
      });
    },
    getRecentProducts(date = {}) {
      dashboardService.getRecentProducts(date).then((data) => {
        if (data.status === "success") {
          this.secondTableData = data.payload.data;
        }
      });
    },
    getChartsData(date = {}) {
      dashboardService.getChartsData(date).then((data) => {
        if (data.status === "success") {
          this.chartData = data.payload;
        }
      });
    },
    timeFilterChange(event) {
      if (event.value === "all_time") {
        this.dateFromTo = {};
        return;
      }
      let toDate = new Date();
      let fromDate = this.subtractDays(new Date(), event.value);
      let toUnix = Math.floor(toDate.getTime() / 1000);
      let fromUnix = Math.floor(fromDate.getTime() / 1000);
      this.dateFromTo = {
        from_date: `${fromUnix}`,
        to_date: `${toUnix}`,
        days: `${event.value}`,
      };
    },
    subtractDays(date, days) {
      date.setDate(date.getDate() - days);
      return date;
    },
  },
  data() {
    return {
      settingsWarning: false,
      dateFromTo: {},
      statsData: { totalCustomers: 0, totalProducts: 0, totalOrderAmount: 0 },
      firstTableData: [],
      secondTableData: [],
      chartData: [1, 2, 3],
      selectedTime: "all_time",
      times: [
        { name: "Last 7 days", value: 7 },
        { name: "Last 30 days", value: 30 },
        { name: "Last 90 days", value: 90 },
        { name: "All time", value: "all_time" },
      ],
    };
  },
  watch: {
    dateFromTo(value) {
      this.getStatistics(value);
      this.getRecentProducts(value);
      this.getTopProducts(value);
      this.getChartsData(value);
    },
  },
  mounted() {
    this.$emit("routeName", "Dashboard");

    this.getStatistics();
    this.getRecentProducts();
    this.getTopProducts();
    this.getChartsData();
    //check for the settings if there no setting show some warning
    settingsService
      .getSettings()
      .then((data) =>
        data.payload === null
          ? (this.settingsWarning = true)
          : (this.settingsWarning = false)
      );
  },
};
</script>

<style lang="scss" scoped>
.main {
  padding: 33px 23px 37px 27px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 10px 13px -2px #6a73790d;
}

section.tables {
  margin-top: 70px;
  display: flex;
  gap: 21px;
}

.title-bar {
  margin-top: 27px;
  margin-top: 11px;
  display: flex;
  justify-content: space-between;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 950px) {
  section.tables {
    flex-direction: column;
  }
}
</style>
