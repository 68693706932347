<template>
  <div
    class="waitlist-search-bar"
    :style="{ display: title === 'Waitlist Widget' && 'none' }"
  >
    <p class="waitlist-left">
      {{ title }}
    </p>
    <!-- Conditional Rendering -->
    <!-- Requests -->
    <div
      class="waitlist-right"
      v-if="title === 'Requests' || title === 'Customers on Waitlist'"
    >
      <base-button class="flat" @click="showExportModal">
        Export CSV
      </base-button>
      <base-button class="flat" @click="showImportModal">Import</base-button>
      <base-button @click="showAddCustomerModal">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.125 6.125H7.875V0.875C7.875 0.392 7.48387 0 7 0C6.51613 0 6.125 0.392 6.125 0.875V6.125H0.875C0.391125 6.125 0 6.517 0 7C0 7.483 0.391125 7.875 0.875 7.875H6.125V13.125C6.125 13.608 6.51613 14 7 14C7.48387 14 7.875 13.608 7.875 13.125V7.875H13.125C13.6089 7.875 14 7.483 14 7C14 6.517 13.6089 6.125 13.125 6.125Z"
            fill="#202223"
          />
        </svg>
        Add Customer
      </base-button>
    </div>
    <!--Notification Email -->
    <div
      class="waitlist-right-email-template"
      v-if="title === 'Notification Email'"
    >
      <base-button class="red-text flat small" @click="resetNotificationEmail">
        Reset
      </base-button>
      <base-button class="flat small" @click="showTestEmailModal"
        >Send Test Email</base-button
      >
      <base-button class="small" @click="saveNotificationEmail"
        >Save</base-button
      >
    </div>

    <!-- Add Customer Modal -->
    <add-customer-modal
      v-if="addCustomerModalVisible"
      @hide-modal="hideAddCustomerModal"
      @submit-customer="submitCustomer"
    ></add-customer-modal>
    <!-- Export Data Modal -->
    <export-modal
      v-if="exportModalVisible"
      @hide-modal="hideExportModal"
      @submit-email-export="submitExportEmail"
    ></export-modal>
    <!-- Import Data Modal -->
    <import-modal
      v-if="importModalVisible"
      @hide-modal="hideImportModal"
      @submit-email-import="submitImportData"
    ></import-modal>
    <!-- Send Test Email Modal -->
    <send-test-email-modal
      v-if="testEmailModalVisible"
      @hide-modal="hideTestEmailModal"
      @send-email="submitTestEmailData"
    ></send-test-email-modal>
    <!-- Alert Message -->
    <transition name="fade">
      <success-message v-show="showAlert"> Done! </success-message>
    </transition>
  </div>
</template>

<script>
import AddCustomerModal from "./AddCustomerModal.vue";
import ExportModal from "./ExportModal.vue";
import ImportModal from "./ImportModal.vue";
import SendTestEmailModal from "./SendTestEmailModal.vue";

export default {
  components: {
    AddCustomerModal,
    ExportModal,
    ImportModal,
    SendTestEmailModal,
  },
  data() {
    return {
      addCustomerModalVisible: false,
      exportModalVisible: false,
      importModalVisible: false,
      testEmailModalVisible: false,
      showAlert: false,
    };
  },
  props: ["title"],
  methods: {
    saveNotificationEmail() {
      this.$eventBus.$emit("saveNotificationEmail");
    },
    resetNotificationEmail() {
      this.$eventBus.$emit("resetNotificationEmail");
    },
    showAddCustomerModal() {
      this.addCustomerModalVisible = true;
    },
    hideAddCustomerModal() {
      this.addCustomerModalVisible = false;
    },
    submitCustomer() {
      this.showAlert = true;
      setTimeout(this.hideAlert, 1500);
      this.hideAddCustomerModal();
    },

    showExportModal() {
      this.exportModalVisible = true;
    },

    hideExportModal() {
      this.exportModalVisible = false;
    },
    submitExportEmail() {
      this.showAlert = true;
      setTimeout(this.hideAlert, 1500);
      this.hideExportModal();
    },

    showImportModal() {
      this.importModalVisible = true;
    },

    hideImportModal() {
      this.importModalVisible = false;
    },
    submitImportData() {
      this.showAlert = true;
      setTimeout(this.hideAlert, 1500);
      this.hideImportModal();
    },

    showTestEmailModal() {
      this.testEmailModalVisible = true;
    },
    hideTestEmailModal() {
      this.testEmailModalVisible = false;
    },
    submitTestEmailData() {
      this.showAlert = true;
      setTimeout(this.hideAlert, 1500);
      this.hideTestEmailModal();
    },
    hideAlert() {
      this.showAlert = false;
    },
  },
};
</script>

<style scoped>
.waitlist-search-bar {
  display: flex;
  justify-content: space-between;
  height: 76px;
}

.waitlist-left {
  display: flex;
  align-items: center;
  color: #202223;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}

.waitlist-right {
  display: flex;
  align-items: center;
  gap: 12px;
}

.waitlist-right-email-template {
  display: flex;
  align-items: center;
  gap: 8px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
@media only screen and (max-width: 610px) {
  .waitlist-search-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100px;
  }
}
</style>
