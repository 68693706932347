<template>
  <div class="getting-started" @click="closeDropDown">
    <div class="title">
      <h4>Getting started with Waitlist</h4>
      <div class="three-dots" @click.stop="toggleDropDown">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
      <drop-down v-if="showDropDown"></drop-down>
    </div>
    <section id="getting-started">
      <nav ref="nav">
        <button class="active" @click="setCurrentTab($event, 'view-button')">
          <IconViewButtonSvg />
          <span>View button</span>
        </button>
        <button @click="setCurrentTab($event, 'add-request')">
          <IconAddRequestSvg />
          <span>Add Request</span>
        </button>
        <button @click="setCurrentTab($event, 'send-notification')">
          <IconSendNotificationSvg />
          <span>Send a notification</span>
        </button>
        <button @click="setCurrentTab($event, 'customize-widgets')">
          <IconCustomizeWidgetsSvg />
          <span>Customize widgets</span>
        </button>
      </nav>
      <keep-alive>
        <component :is="currentTab" class="tab-content"></component>
      </keep-alive>
    </section>
  </div>
</template>

<script>
import ViewButton from "./components/ViewButton.vue";
import AddRequest from "./components/AddRequest.vue";
import SendNotification from "./components/SendNotification.vue";
import CustomizeWidgets from "./components/CustomizeWidgets.vue";
import DropDown from "./components/DropDown.vue";

import IconViewButtonSvg from "./components/icons/IconViewButtonSvg.vue";
import IconAddRequestSvg from "./components/icons/IconAddRequestSvg.vue";
import IconSendNotificationSvg from "./components/icons/IconSendNotificationSvg.vue";
import IconCustomizeWidgetsSvg from "./components/icons/IconCustomizeWidgetsSvg.vue";

export default {
  components: {
    "view-button": ViewButton,
    "add-request": AddRequest,
    "send-notification": SendNotification,
    "customize-widgets": CustomizeWidgets,
    DropDown,
    IconViewButtonSvg,
    IconAddRequestSvg,
    IconSendNotificationSvg,
    IconCustomizeWidgetsSvg,
  },
  data() {
    return {
      showDropDown: false,
      currentTab: "view-button",
    };
  },
  methods: {
    setCurrentTab(event, cmp) {
      this.currentTab = cmp;
      this.$refs.nav.childNodes.forEach((element) => {
        element.classList.remove("active");
      });
      event.currentTarget.classList.add("active");
    },
    toggleDropDown() {
      this.showDropDown = !this.showDropDown;
    },
    closeDropDown() {
      if (this.showDropDown) {
        this.showDropDown = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.getting-started {
  padding-bottom: 60px;
  border: 1px solid #edeeef;
  border-radius: 12px;
}
section {
  display: flex;
}

section nav {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #cecece;
}

section nav button {
  cursor: pointer;
  display: flex;
  outline: none;
  align-items: center;
  padding: 0px 20px;
  gap: 15px;
  height: 60px;
  width: 241px;
  background-color: #fff;
  border: 1px solid #cecece;
  border-left-width: 0;
  border-right-width: 0;
  color: #6d6d6d;
}

section nav button.active {
  width: 101%;
  box-shadow: inset 4px 0 0 0 #ffb054;
  color: #202223;
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: #cecece;
}

section nav button.active:last-of-type {
  border-bottom-width: 1px;
}

:deep(section nav button.active path) {
  fill: #202223;
}

.title {
  position: relative;
  padding: 30px 30px 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}
.three-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  gap: 3px;
}

.dot {
  width: 4px;
  height: 4px;
  background-color: #6d6d6d;
  border-radius: 50%;
}
.tab-content {
  padding: 30px 50px 10px;
  width: 100%;
  border-width: 1px 0px 1px 0px;
  border-style: solid;
  border-color: #cecece;
}
</style>
