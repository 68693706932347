<template>
  <div class="exclude-title-bar">
    <div class="input-container">
      <i class="fa fa-search icon"></i>
      <input
        v-model="filters.product_name.value"
        @input="onFilter"
        type="text"
        placeholder="Search by product name"
      />
    </div>
    <div
      style="
        display: flex;
        gap: 15px;
        padding-left: 20px;
        border-left: 1px solid rgb(229, 231, 235);
        margin-left: auto;
      "
    >
      <base-button @click="showExcludeProductModal">
        <i class="pi pi-ban" style="font-size: 16px"></i>
        <span> Exclude Product </span>
      </base-button>
      <base-button
        class="flat"
        @click="refreshTable"
        v-tooltip="'Refresh Table'"
      >
        <i class="pi pi-refresh" style="font-size: 14px"></i>
      </base-button>
    </div>
  </div>
  <hr />
  <DataTable
    class="datatable"
    :loading="loading"
    :lazy="true"
    :totalRecords="total"
    @page="onPage($event)"
    :value="excludesData"
    v-model:selection="selectedProducts"
    dataKey="product_variant"
    :paginator="total > 10"
    :rows="10"
    paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    responsiveLayout="scroll"
    :sortField="'product_name'"
    :sortOrder="-1"
    ref="dataTable"
  >
    <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
    <Column field="product_name" header="Product Name">
      <template #body="{ data }">
        <div style="display: flex; flex-direction: column">
          <span>{{ data.product_name }}</span>
          <span>{{ data.variant_value }}</span>
        </div>
      </template>
    </Column>

    <template #empty>
      <p>Excluded products list is empty</p>
    </template>
  </DataTable>
  <div class="deleteBar" ref="deleteBar" v-if="selectedProducts.length > 0">
    <button @click="deleteSelected">Delete Selected</button>
    <p>{{ selectedProducts?.length }} selected</p>
  </div>
  <!-- Add Customer Modal -->
  <exclude-product-modal
    v-if="excludeProductModalVisible"
    @hide-modal="hideExcludeProductModal"
    @exclude-product="submitExcludedProduct"
  ></exclude-product-modal>
  <!-- Alert Message -->
  <transition name="fade">
    <success-message v-show="showAlert"> Done! </success-message>
  </transition>
</template>

<script>
import tablesServices from "@/services/tablesServices";
import ExcludeProductModal from "../ExcludeProductModal.vue";

export default {
  mounted() {
    this.loadLazyData();
  },
  data() {
    return {
      showAlert: false,
      excludeProductModalVisible: false,
      excludesData: [],
      lazyParams: {
        first: 0,
        sortField: "product_name",
        sortOrder: -1,
        filters: this.filters,
      },
      filters: {
        product_name: { value: "", matchMode: "contains" },
      },
      total: 0,
      error: false,
      loading: true,
      selectedProducts: [],
    };
  },
  methods: {
    showExcludeProductModal() {
      this.excludeProductModalVisible = true;
    },
    hideExcludeProductModal() {
      this.excludeProductModalVisible = false;
    },
    submitExcludedProduct() {
      this.showAlert = true;
      setTimeout(this.hideAlert, 1500);
      this.hideExcludeProductModal();
    },
    hideAlert() {
      this.showAlert = false;
    },
    refreshTable() {
      this.excludesData = [];
      this.loadLazyData();
    },
    loadLazyData() {
      tablesServices.getAllExcludedProducts(this.lazyParams).then((data) => {
        this.loading = false;
        if (data.status === "success") {
          this.total = data.payload.total;
          this.excludesData = data.payload.data;
        } else {
          this.error = true;
        }
      });
    },
    onPage(data) {
      this.lazyParams = data;
      this.loadLazyData();
    },
    onFilter() {
      this.lazyParams.filters = this.filters;
      this.loadLazyData();
    },
    deleteSelected() {
      this.$confirm.require({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          tablesServices.deleteSelectedRecordsByVariantId(
            this.selectedProducts,
            this,
            "selectedProducts",
            this.loadLazyData
          );
        },
        reject: () => {
          //callback to execute when user rejects the action
          this.selectedProducts = [];
        },
      });
    },
  },
  components: { ExcludeProductModal },
};
</script>

<style scoped lang="scss">
.exclude-title-bar {
  padding: 13px 30px;
  display: flex;
  gap: 12px;
}

//style search input
.input-container {
  flex-grow: 1;
}

.input-container i {
  position: absolute;
  font-size: 15px;
}

.exclude-title-bar input {
  width: 100%;
  height: 45px;
  padding: 8px 15px 8px 48px;
  background: #fbfbfb;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
}

.exclude-title-bar input:focus {
  outline: #6d6d6d;
}

.icon {
  padding: 20px;
  padding-top: 14px;
  min-width: 40px;
  color: #6d6d6d;
}
//Style delete bar
.deleteBar {
  position: absolute;
  padding-left: 68px;
  top: 71px;
  right: 0;
  width: 100%;
  height: 65px;
  display: flex;
  gap: 24px;
  align-items: center;
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.deleteBar button {
  padding: 5.5px 21px;
  cursor: pointer;
  border: 1px solid #cecece;
  border-radius: 8px;
  outline: none;
  color: #c83e25;
}

.deleteBar button:hover {
  background-color: #f6f6f6;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
