<template>
  <div class="main">
    <h6>Add your first request</h6>
    <p>
      Follow these steps to add a request:<br />
      1. Visit an <span>out-of-stock product page</span><br />
      2. Click the “Notify me” button<br />
      3. Enter your email address<br />
      4. Submit your request<br /><br />

      If subscription form needs changes, go to <span>customization page.</span
      ><br />
    </p>
    <router-link to="/reports">
      <base-button class="add-req-btn">Add request</base-button>
    </router-link>
  </div>
</template>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 9px;
}
p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 26px;
}
span {
  color: #1575bf;
  text-decoration: underline;
}
.add-req-btn {
  margin-bottom: 22px;
}
</style>
