<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8766 1.12262L14.8781 4.12476L15.3787 3.62407C16.2074 2.79519 16.2074 1.45148 15.3787 0.622594C14.5497 -0.206554 13.2055 -0.206555 12.3766 0.622593L11.8766 1.12262Z"
      fill="#6D6D6D"
    />
    <path
      d="M13.5001 5.50002L10.4986 2.49788L0.974293 12.0227L0 15.9994L3.97577 15.0248L13.5001 5.50002Z"
      fill="#6D6D6D"
    />
  </svg>
</template>
