<template>
  <div>
    <VueApexChart
      ref="chartRef"
      height="312"
      :series="dataChart"
      :options="chartOptions"
    ></VueApexChart>
  </div>
</template>

<script>
import VueApexChart from "vue3-apexcharts";
export default {
  props: ["chartData"],
  watch: {
    chartData(value) {
      let deliveredObj = { name: "Delivered", data: [] };
      let openedObj = { name: "Opened", data: [] };
      let clickedObj = { name: "Clicked", data: [] };
      let datesArr = [];

      value.forEach((element) => {
        deliveredObj.data.push(element.DeliveredEmails);
        openedObj.data.push(element.ClickedEmails);
        clickedObj.data.push(element.OpenedEmails);
        datesArr.push(element.date);
      });

      this.dataChart = [deliveredObj, openedObj, clickedObj];
      this.chartOptions.xaxis.categories = datesArr;
      this.chartOptions.xaxis.tickAmount = datesArr.length;
      this.$refs.chartRef.updateOptions(this.chartOptions);
    },
  },

  components: { VueApexChart },
  data() {
    return {
      dataChart: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          fontFamily: "Plus Jakarta Sans",
          background: "#f0f0f",
          height: 312,
          type: "line",
        },

        colors: ["#EE2F2F", "#EE962F", "#07D09E"],

        dataLabels: {
          enabled: false,
        },

        stroke: {
          curve: "smooth",
          width: 2,
        },

        // y-axis
        yaxis: {
          type: "numeric",
          axisBorder: {
            show: true,
            color: "#f0f0f0",
            height: "100%",
            width: 1,
          },
        },

        // x-axis
        xaxis: {
          type: "category",
          categories: [],
          axisBorder: {
            show: true,
            color: "#f0f0f0",
            height: 0,
            width: "100%",
          },
          tickAmount: 0,
        },

        // Chart gridlines
        grid: {
          show: true,
          borderColor: "#f0f0f0",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },

        //chart tooltip
        tooltip: {
          enabled: true,
        },

        // chart Title
        title: {
          text: "Email Activity",
          align: "left",
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: "Plus Jakarta Sans",
            color: "#202223",
          },
        },

        //legend
        legend: {
          show: true,
          showForSingleSeries: false,
          position: "top",
          horizontalAlign: "right",
          fontSize: "13px",
          fontFamily: "Plus Jakarta Sans",
          fontWeight: 400,
          labels: {
            colors: ["#6D6D6D"],
          },
          markers: {
            width: 15,
            height: 5,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 23,
            customHTML: undefined,
            onClick: undefined,
            offsetX: -4,
            offsetY: -2,
          },
          itemMargin: {
            horizontal: 19,
            vertical: 0,
          },
        },
      },
    };
  },
};
</script>

<style scoped>
div {
  margin-top: 27px;
}
</style>
