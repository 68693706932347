<template>
  <section>
    <nav>
      <ul>
        <li v-for="tab in navTabs" :key="tab.id">
          <button
            @click="setCurrentTab(tab.tabComponent)"
            :class="currentTab === tab.tabComponent ? 'active' : ''"
          >
            {{ tab.tabName }}
          </button>
        </li>
      </ul>
      <hr class="nav-hr" />
    </nav>
    <div class="tab-content">
      <p
        style="margin-top: 10px; margin-left: 25px; color: lightcoral"
        v-if="settingsWarning"
      >
        Please make sure to fill in your setting to make waitlist work properly.
        <router-link to="/settings">
          <button style="font-weight: 600; text-decoration: underline">
            Go to settings tab
          </button>
        </router-link>
      </p>
      <keep-alive>
        <component :is="currentTab" :setTab="setCurrentTab"></component>
      </keep-alive>
    </div>
  </section>
  <confirm-message v-if="leavePageConfirmShow" @confirm-message="messageEvent">
    <template #title>Unsaved changes</template>
    <template #body>
      If you leave this page, any unsaved changes will be lost
    </template>
  </confirm-message>
  <Toast />
</template>

<script>
import NotifyMe from "@/components/WidgetsTabComponents/NotifyMe/NotifyMe.vue";
import AdvanceTab from "@/components/WidgetsTabComponents/AdvanceTab/AdvanceTab.vue";
import SubscribeForm from "@/components/WidgetsTabComponents/SubscribeForm/SubscribeForm.vue";
import SubscriberMessage from "@/components/WidgetsTabComponents/SubscriberMessage/SubscriberMessage.vue";

import { waitListStoreFront } from "@/services/waitListStoreFront";
import settingsService from "@/services/settingsService";

export default {
  components: {
    NotifyMe,
    AdvanceTab,
    SubscribeForm,
    SubscriberMessage,
  },
  beforeRouteLeave(to, from, next) {
    if (this.store.unsavedChanges) {
      this.leavePageConfirmShow = true;
      this.clickedRoute = to;
      next(false);
    } else {
      next(true);
    }
  },
  data() {
    return {
      settingsWarning: false,
      store: waitListStoreFront(),
      clickedRoute: "",
      leavePageConfirmShow: false,

      currentTab: "notify-me",
      navTabs: [
        { id: 0, tabName: "“Notify me” Widgets", tabComponent: "notify-me" },
        { id: 1, tabName: "Subscriber Form", tabComponent: "subscribe-form" },
        {
          id: 2,
          tabName: "Subscriber Message",
          tabComponent: "subscriber-message",
        },
        { id: 3, tabName: "Advance", tabComponent: "advance-tab" },
      ],
    };
  },
  methods: {
    setCurrentTab(tab) {
      this.currentTab = tab;
    },
    messageEvent(value) {
      if (value) {
        this.store.unsavedChanges = false;
        this.$router.push(this.clickedRoute);
      } else {
        this.leavePageConfirmShow = false;
        this.clickedRoute = "";
      }
    },
  },
  mounted() {
    this.store.getWidgetData();
    this.$emit("routeName", "Widgets");
    //check for the settings if there no setting show some warning
    settingsService
      .getSettings()
      .then((data) =>
        data.payload === null
          ? (this.settingsWarning = true)
          : (this.settingsWarning = false)
      );
  },
  emits: ["routeName"],
};
</script>

<style scoped>
h6 {
  color: red;
}
section {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 10px 13px -2px #6a73790d;
  border: 1px solid #edeeef;
  border-radius: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

nav {
  display: flex;
  flex-direction: column;
  padding-left: 27px;
  padding-top: 22px;
  padding-right: 23px;
  height: 63px;
}

ul {
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  gap: 12px;
  height: 100%;
}

.nav-hr {
  border-bottom: 1px solid #cecece;
  margin-top: auto;
}

nav button {
  white-space: nowrap;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  height: 93%;
  color: #6d6d6d;
  outline: none;
  z-index: 1;
}

.active {
  box-shadow: 0 4px 0 #ffb054;
  color: #202223;
}
</style>
