<template>
  <div class="container-template">
    <header>
      <div>
        <h5>Customize Email Template</h5>
        <p class="left">Choose how to customize your email template.</p>
        <p
          class="left"
          style="margin-bottom: 20px; color: lightcoral"
          v-if="settingsWarning"
        >
          Please make sure to fill in your setting to set the sender email.
          <router-link to="/settings">
            <button style="font-weight: 600; text-decoration: underline">
              Go to settings tab
            </button>
          </router-link>
        </p>
      </div>
      <p class="right" v-if="unsavedChanges">Unsaved changes</p>
    </header>
    <div class="tabs-nav" ref="navBtns">
      <button
        class="tab-btn active"
        @click="navigateTab('customize-email-tab', $event)"
      >
        Customize Email Template
      </button>
      <button class="tab-btn" @click="navigateTab('html-tab', $event)">
        Html Template
      </button>
    </div>
    <!-- inputUpdated is used to control props value from customize tab -->
    <!-- htmlTabUpdate is used to control props value from html tab -->
    <!-- unsavedChanges is used to control form is touched or not and show confirmation message on page leaving-->
    <keep-alive>
      <component
        :is="currentTab"
        @input-updated="inputUpdatedParent"
        @htmlTabUpdate="htmlDataUpdated"
        @unsaved-changes="unsavedChangesHandle"
        :htmlCode="htmlCode"
        :resetEmailTemplate="resetEmailTemplate"
        :saveEmailTemplate="saveEmailTemplate"
        :successSubMessage="successSubMessage"
        :successSubSubject="successSubSubject"
      ></component>
    </keep-alive>
  </div>
  <success-message v-show="showAlert"> Saved Sucessfully! </success-message>
  <confirm-message v-if="leavePageConfirmShow" @confirm-message="messageEvent">
    <template #title>Unsaved changes</template>
    <template #body>
      If you leave this page, any unsaved changes will be lost
    </template>
  </confirm-message>
  <Toast />
</template>

<script>
import CustomizeEmailTab from "@/components/NotificationEmailComponents/CustomizeEmailTab/CustomizeEmailTab.vue";
import HtmlTab from "@/components/NotificationEmailComponents/HtmlTab/HtmlTab.vue";
import notificationEmailService from "@/services/notificationEmailService";
import settingsService from "@/services/settingsService";

export default {
  created() {
    this.$eventBus.$on("saveNotificationEmail", () => {
      this.saveEmailTemplate();
    });
    this.$eventBus.$on("resetNotificationEmail", () => {
      this.resetEmailTemplate();
    });
  },
  computed: {
    //the purpose of this property is to compute the html which will be stored for succes subscribtion email
    successSubscribtionHtml() {
      // if html is empty return
      if (this.htmlCode === "") return;
      let parser = new DOMParser();
      let dom = parser.parseFromString(this.htmlCode, "text/html");
      let body = dom.querySelector("#email-body");
      let title = dom.querySelector("#title");

      title.innerHTML = `<h4 style="font-size: 20px">Welcome to the {store_name},</h4>`;
      body.innerHTML = `<p style="font-size: 14px; text-align: left; margin-bottom: 20px">${this.successSubMessage}</p>`;

      return new XMLSerializer().serializeToString(dom);
    },
  },
  emits: ["routeName"],
  components: { CustomizeEmailTab, HtmlTab },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChanges) {
      this.leavePageConfirmShow = true;
      this.clickedRoute = to;
      next(false);
    } else {
      next(true);
    }
  },
  data() {
    return {
      settingsWarning: false,
      showAlert: false,
      unsavedChanges: false,
      clickedRoute: "",
      leavePageConfirmShow: false,
      currentTab: "customize-email-tab",
      htmlCode: "",
      successSubMessage: "",
      successSubSubject: "",
      defaultHtmlCode: `
      <!DOCTYPE html>
      <html
        lang="en"
        xmlns="http://www.w3.org/1999/xhtml"
        xmlns:v="urn:schemas-microsoft-com:vml"
        xmlns:o="urn:schemas-microsoft-com:office:office"
      >
        <head>
          <title>Waitlist Notification</title>
          <!--[if !mso]><!-->
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <!--<![endif]-->
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta charset="UTF-8" />
          <!-- Font-Family should be imported here -->
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap"
            rel="stylesheet"
          />
          <style type="text/css">
            #body * {
              margin: 0;
              padding: 0;
              box-sizing: border-box;
              -webkit-text-size-adjust: 100%;
              -ms-text-size-adjust: 100%;
            }
            #outlook a {
              padding: 0;
            }
            a:link,
            a:active,
            a:visited {
              border: 0;
              outline: none;
              text-decoration: none;
            }
            a {
              text-decoration: none;
            }
            table,
            td {
              border-collapse: collapse;
              /* mso-table-lspace: 0pt;
                    mso-table-rspace: 0pt; */
            }
            img {
              border: 0;
              max-width: 80%;
              height: 50px;
            }
            #body {
              padding: 0 10px;
            }
            #container {
              margin: 30px auto;
              padding: 25px 32px;
              max-width: 814px;
              color: #202223;
              font-family: "Montserrat", sans-serif;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              background: #ffffff;
              border: 1px solid #cecece;
              box-shadow: 0px 10px 13px -2px rgba(106, 115, 121, 0.05);
              border-radius: 12px;
            }
            #header {
              margin-bottom: 35px;
            }
            #header #logo-container {
              margin: 0 auto;
              padding-top: 12px;
              width: 233px;
              height: 74px;
              border: 1px dashed #999ea4;
              border-radius: 16px;
              margin-bottom: 59px;
            }
            #header #title h4 {
              font-weight: 600;
              font-size: 20px;
              line-height: 32px;
            }
            #header #title p {
              font-size: 16px;
            }
            #main-content {
              width: 100%;
              text-align: center;
              border-bottom: 1px solid #cecece;
              margin-bottom: 65px;
            }
            #img-container {
              padding: 20px;
              border: 1px solid #cecece;
              border-radius: 12px;
              margin-bottom: 20px;
            }
            #img-container img {
              max-width: 450px;
              max-height: 450px;
            }
            #product-details {
              font-weight: 600;
              font-size: 20px;
              line-height: 32px;
            }
            #btn-visit {
              margin-top: 65px;
              margin-bottom: 65px;
              cursor: pointer;
              height: 52px;
              width: 100%;
              border-radius: 8px;
              background: #1575bf;
              color: #fff;
              font-weight: 600;
              font-size: 18px;
              line-height: 20px;
              outline: none;
              border: none;
            }
            #social-icons {
              margin-bottom: 55px;
            }
            a#facebook img{
              padding: 10px;
            }
             a#pinterest img,
             a#linkedin img,
             a#telegram img{
              padding: 12px;
            }
            #footer p:first-child {
              margin-bottom: 19px;
            }
            #footer p:last-child {
              margin: 0 auto;
              color: #6d6d6d;
              max-width: 516px;
            }
          </style>
          <style type="text/css" media="only screen and (max-width:1300)">
            #container {
              margin: 25px auto;
              padding: 25px 28px;
            }
            #header {
              margin-bottom: 30px;
            }
            #header #logo-container {
              margin-bottom: 50px;
            }
            #main-content {
              margin-bottom: 55px;
            }
            #img-container img {
              width: 300px;
              height: 300px;
            }
            #btn-visit {
              margin-top: 55px;
              margin-bottom: 55px;
              height: 45px;
            }
            #social-icons {
              margin-bottom: 45px;
            }
          </style>
          <style type="text/css" edia="only screen and (max-width:900)">
            #container {
              margin: 20px auto;
              padding: 20px 23px;
              font-size: 12px;
              line-height: 20px;
            }
            #header {
              margin-bottom: 30px;
            }
            #header #logo-container {
              margin-bottom: 45px;
            }
            #header #title h4 {
              font-size: 18px;
              line-height: 26px;
            }
            #header #title p {
              font-size: 14px;
            }
            #main-content {
              margin-bottom: 45px;
            }
            #img-container img {
              width: 250px;
              height: 250px;
            }
            #product-details {
              font-size: 18px;
              line-height: 26px;
            }
            #btn-visit {
              margin-top: 45px;
              margin-bottom: 45px;
              font-size: 16px;
              line-height: 18px;
            }
            #social-icons {
              margin-bottom: 35px;
            }
          </style>
          <style type="text/css" media="only screen and (max-width:500px)">
            body {
              padding: 0 5px;
            }
            #container {
              font-size: 12px;
              margin: 15px auto;
              padding: 10px;
              font-size: 10px;
              line-height: 16px;
            }
            #header {
              margin-bottom: 30px;
            }
            #header #logo-container {
              margin-bottom: 40px;
            }
            #header #title h4 {
              font-size: 16px;
              line-height: 22px;
            }
            #header #title p {
              font-size: 12px;
            }
            #main-content {
              margin-bottom: 35px;
            }
            #img-container img {
              width: 200px;
              height: 200px;
            }
            #product-details {
              font-size: 16px;
              line-height: 22px;
            }
            #btn-visit {
              margin-top: 35px;
              margin-bottom: 35px;
              font-size: 14px;
              line-height: 14px;
            }
            #social-icons {
              margin-bottom: 25px;
            }
          </style>
          <style type="text/css">
            @media only screen and (min-width: 480px) {
              .mj-column-per-100 {
                width: 100% !important;
                max-width: 100%;
              }
              .mj-column-per-20 {
                width: 20% !important;
                max-width: 20%;
              }
              .mj-column-per-33-333333333333336 {
                width: 33.333333333333336% !important;
                max-width: 33.333333333333336%;
              }
            }
          </style>
          <style media="screen and (min-width:480px)">
            .moz-text-html .mj-column-per-100 {
              width: 100% !important;
              max-width: 100%;
            }
            .moz-text-html .mj-column-per-20 {
              width: 20% !important;
              max-width: 20%;
            }
            .moz-text-html .mj-column-per-33-333333333333336 {
              width: 33.333333333333336% !important;
              max-width: 33.333333333333336%;
            }
          </style>
          <style type="text/css">
            @media only screen and (max-width: 480px) {
              table.mj-full-width-mobile {
                width: 100% !important;
              }
              td.mj-full-width-mobile {
                width: auto !important;
              }
            }
          </style>
        </head>
        <body
          id="body"
          style="word-spacing: normal; font-family: 'Montserrat', sans-serif"
        >
          <div id="container">
            <div id="header">
              <div id="logo-container" style="text-align: center">
                @if (isset($values[0]) && $values[0]['logo_image'])
                <img src="{{ $values[0]['logo_image'] }}" alt="store-logo" />
                @elseif (isset($values['logo_image']) && $values['logo_image'])
                <img src="{{ $values['logo_image'] }}" alt="store-logo" />
                @elseif (isset($values[0]))
                <p>{{ $values[0]['store_name'] }}</p>
                @elseif (isset($values['store_name']))
                <p>{{ $values['store_name'] }}</p>
                @endif
              </div>
              <div id="title">
                <h4 style="font-size: 20px">These items are available now!</h4>
                <p style="font-size: 16px">
                  Get it now before it goes out of stock again!
                </p>
                <p id="discount" style="display: none;font-size: 16px">
                  Use code 1234 for 10% OFF discount
                </p>
              </div>
            </div>
            <div id="email-body">
              @foreach ($values as $value)
              <table id="main-content">
                <tr>
                  <td colspan="2">
                    <div id="img-container">
                      <img src="{{ $value['variant_image'] }}" alt="" />
                    </div>
                  </td>
                </tr>
                <tr id="product-details">
                  <td style="text-align: left">
                    <p>{{ $value['products_name'] }}</p>
                  </td>
                  <td style="text-align: right">
                    <p id="product_price">$ {{ $value['variant_price'] }}</p>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <a
                      href="{{ $value['storeUri'] }}/{{ $value['product_id'] }}"
                      target="_blank"
                    >
                      <button id="btn-visit" style="background-color: #1575bf">
                        View item
                      </button>
                    </a>
                  </td>
                </tr>
              </table>
              @endforeach
            </div>
            <div style="text-align: center" id="footer">
              <p>Follow us on:</p>
              <!-- Social media links should be inserted here -->
              <div id="social-icons">
                <a href="" target="_blank" id="instagram" style="display: none">
                  <img
                    src="https://media.publit.io/file/63dbd8a9347dc_Instagram.png"
                    alt="instagram"
                  />
                </a>
                <a href="" target="_blank" id="twitter" style="display: none">
                  <img
                    src="https://media.publit.io/file/63dbd8e315f70_Twitter.png"
                    alt="twitter"
                  />
                </a>
                <a href="" target="_blank" id="youtube" style="display: none">
                  <img
                    src="https://media.publit.io/file/63dbd7591a287_Youtube.png"
                    alt="youtube"
                  />
                </a>
                <a href="" target="_blank" id="facebook" style="display: none">
                  <img
                    src="https://media.publit.io/file/facebook-p5.png"
                    alt="facebook"
                  />
                </a>
                <a href="" target="_blank" id="pinterest" style="display: none">
                  <img
                    src="https://media.publit.io/file/pinterest-N.png"
                    alt="pinterest"
                  />
                </a>
                <a href="" target="_blank" id="linkedin" style="display: none">
                  <img
                    src="https://media.publit.io/file/linkedin-p.png"
                    alt="linkedin"
                  />
                </a>
                <a href="" target="_blank" id="telegram" style="display: none">
                  <img
                    src="https://media.publit.io/file/telegram-T.png"
                    alt="telegram"
                  />
                </a>
              </div>
              <p>
                You are receiving this email because you requested a back in stock
                notification on @if (isset($values[0])) {{ $values[0]['store_name'] }}
                @elseif (isset($values['store_name'])) {{ $values['store_name'] }}
                @else Lexmodo Waxth Theme @endif.
              </p>
            </div>
          </div>
        </body>
      </html>
`,
    };
  },
  mounted() {
    this.$emit("routeName", "Notification Email");
    //check for the settings if there no setting show some warning
    settingsService
      .getSettings()
      .then((data) =>
        data.payload === null
          ? (this.settingsWarning = true)
          : (this.settingsWarning = false)
      );

    notificationEmailService.getSuccessEmailTemplate().then((result) => {
      if (result.status === "success") {
        this.successSubSubject = result.payload[0].subscriber_form_subject;

        let successSubHtml = result.payload[0].subscriber_form_mail.trim();
        let parser = new DOMParser();
        let dom = parser.parseFromString(successSubHtml, "text/html");
        this.successSubMessage = dom
          .querySelector("#email-body")
          .querySelector("p")
          ?.textContent.trim();
      }
    });

    notificationEmailService
      .getEmailTemplate()
      .then((result) => {
        if (result.status === "success") {
          this.htmlCode = result.payload;
          this.unsavedChanges = false;
        } else {
          console.log("response error");
        }
      })
      .catch((err) => console.log("error", err));
  },

  methods: {
    navigateTab(tabName, event) {
      this.$refs.navBtns.childNodes.forEach((element) => {
        element.classList.remove("active");
      });
      event.currentTarget.classList.add("active");
      this.currentTab = tabName;
    },
    //function that reset html code to default template
    resetEmailTemplate() {
      this.unsavedChanges = true;
      this.htmlCode = this.defaultHtmlCode;
    },
    // this function is responsible for changing email template when a change happen in html tab
    htmlDataUpdated(value) {
      this.htmlCode = value;
    },
    // this function is responsible for changing email template when a change happen in Customize tab
    inputUpdatedParent(value, inputName) {
      let parser = new DOMParser();
      let dom = parser.parseFromString(this.htmlCode, "text/html");

      //if html code is empty return
      if (!this.htmlCode) {
        return;
      }

      if (inputName === "buttonColor") {
        if (dom.querySelector("#btn-visit"))
          dom.querySelector("#btn-visit").style.backgroundColor = value;
        this.htmlCode = new XMLSerializer()
          .serializeToString(dom)
          .replace(/&amp;&amp;/g, "&&")
          .replace(/&amp;/g, "&");
      }

      if (inputName === "instockMailSubject") {
        if (dom.querySelector("#title").querySelector("h4"))
          dom.querySelector("#title").querySelector("h4").textContent = value;
        this.htmlCode = new XMLSerializer()
          .serializeToString(dom)
          .replace(/&amp;&amp;/g, "&&")
          .replace(/&amp;/g, "&");
      }

      if (inputName === "emailDescription") {
        if (dom.querySelector("#title").querySelector("p"))
          dom.querySelector("#title").querySelector("p").textContent = value;
        this.htmlCode = new XMLSerializer()
          .serializeToString(dom)
          .replace(/&amp;&amp;/g, "&&")
          .replace(/&amp;/g, "&");
      }

      if (inputName === "fontFamily") {
        if (dom.querySelector("#body"))
          dom.querySelector("#body").style.fontFamily = value;
        this.htmlCode = new XMLSerializer()
          .serializeToString(dom)
          .replace(/&amp;&amp;/g, "&&")
          .replace(/&amp;/g, "&");
      }

      if (inputName === "emailTitleFontSize") {
        if (dom.querySelector("#title").querySelector("h4"))
          dom
            .querySelector("#title")
            .querySelector("h4").style.fontSize = `${value}px`;
        this.htmlCode = new XMLSerializer()
          .serializeToString(dom)
          .replace(/&amp;&amp;/g, "&&")
          .replace(/&amp;/g, "&");
      }

      if (inputName === "emailDescriptionFontSize") {
        if (dom.querySelector("#title").querySelector("p"))
          dom
            .querySelector("#title")
            .querySelector("p").style.fontSize = `${value}px`;
        this.htmlCode = new XMLSerializer()
          .serializeToString(dom)
          .replace(/&amp;&amp;/g, "&&")
          .replace(/&amp;/g, "&");
      }

      if (inputName === "visitProductLabel") {
        if (dom.querySelector("#btn-visit"))
          dom.querySelector("#btn-visit").textContent = value;
        this.htmlCode = new XMLSerializer()
          .serializeToString(dom)
          .replace(/&amp;&amp;/g, "&&")
          .replace(/&amp;/g, "&");
      }
      if (inputName === "socialNetworkTitle") {
        if (dom.querySelector("#footer"))
          dom.querySelector("#footer").querySelector("p").textContent = value;
        this.htmlCode = new XMLSerializer()
          .serializeToString(dom)
          .replace(/&amp;&amp;/g, "&&")
          .replace(/&amp;/g, "&");
      }
      if (
        inputName === "youtube" ||
        inputName === "instagram" ||
        inputName === "twitter" ||
        inputName === "telegram" ||
        inputName === "pinterest" ||
        inputName === "linkedin" ||
        inputName === "facebook"
      ) {
        if (dom.querySelector(`#${inputName}`)) {
          let anchor = dom.querySelector(`#${inputName}`);
          if (value.trim()) {
            anchor.style.display = "inline";
            anchor.href = value;
          } else {
            anchor.style.display = "none";
            anchor.href = "";
          }
          this.htmlCode = new XMLSerializer()
            .serializeToString(dom)
            .replace(/&amp;&amp;/g, "&&")
            .replace(/&amp;/g, "&");
        }
      }
      if (inputName === "productPriceVisible") {
        if (dom.querySelector("#product_price")) {
          if (!value) {
            dom.querySelector("#product_price").style.display = "none";
          } else {
            dom.querySelector("#product_price").style.display = "block";
          }
          this.htmlCode = new XMLSerializer()
            .serializeToString(dom)
            .replace(/&amp;&amp;/g, "&&")
            .replace(/&amp;/g, "&");
        }
      }
      if (inputName === "discountCodeVisible") {
        if (dom.querySelector("#discount")) {
          if (!value) {
            dom.querySelector("#discount").style.display = "none";
          } else {
            dom.querySelector("#discount").style.display = "block";
          }
          this.htmlCode = new XMLSerializer()
            .serializeToString(dom)
            .replace(/&amp;&amp;/g, "&&")
            .replace(/&amp;/g, "&");
        }
      }
      if (inputName === "discountDescription") {
        if (dom.querySelector("#discount")) {
          dom.querySelector("#discount").textContent = value;
          this.htmlCode = new XMLSerializer()
            .serializeToString(dom)
            .replace(/&amp;&amp;/g, "&&")
            .replace(/&amp;/g, "&");
        }
      }
      if (inputName === "discountFontSize") {
        if (dom.querySelector("#discount")) {
          dom.querySelector("#discount").style.fontSize = `${value}px`;
          this.htmlCode = new XMLSerializer()
            .serializeToString(dom)
            .replace(/&amp;&amp;/g, "&&")
            .replace(/&amp;/g, "&");
        }
      }
      if (inputName === "succesSubscribtionMessage") {
        this.successSubMessage = value;
      }
      if (inputName === "succesSubscribtionSubject") {
        this.successSubSubject = value;
      }
    },
    saveEmailTemplate() {
      if (this.unsavedChanges) {
        notificationEmailService
          .saveSuccessEmail({
            subscriber_form_subject: this.successSubSubject,
            subscriber_form_mail: this.successSubscribtionHtml,
          })
          .then(() => {
            this.unsavedChanges = false;
          })
          .catch((err) => console.log(err));
        notificationEmailService
          .saveEmailTemplate({ mailTemplate: this.htmlCode })
          .then(() => {
            this.unsavedChanges = false;
            this.showAlert = true;
            setTimeout(this.hideAlert, 1500);
          })
          .catch((err) => console.log(err));
      } else {
        this.$toast.removeAllGroups();
        this.$toast.add({
          severity: "info",
          summary: "Changes up to date",
          detail: "Please make some changes to be saved",
          life: 1500,
        });
      }
    },
    unsavedChangesHandle(payload) {
      this.unsavedChanges = payload;
    },
    // This function is used to check whether the user want to leave page or want to stay.
    // the value is a boolean value which will be recieved as payload from emitted event "confirm-message"
    messageEvent(value) {
      if (value) {
        this.unsavedChanges = false;
        this.$router.push(this.clickedRoute);
      } else {
        this.leavePageConfirmShow = false;
        this.clickedRoute = "";
      }
    },
    hideAlert() {
      this.showAlert = false;
    },
  },
};
</script>

<style scoped>
.container-template {
  width: 100%;
  padding: 0 25px;
  padding-top: 31px;
  background-color: #fff;
  font-weight: 500;
  line-height: 24px;
  font-size: 14px;
  color: #202223;
  border-radius: 12px;
  box-shadow: 0px 10px 13px -2px #6a73790d;
}

header {
  display: flex;
  justify-content: space-between;
}

header h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}
header p.left {
  font-weight: 500;
  color: #6d6d6d;
}

header p.right {
  font-weight: 500;
  align-self: flex-end;
}
/* Email template navbar */
.tabs-nav {
  padding-top: 29px;
  margin-bottom: 21px;
  border-bottom: 1px solid #cecece;
}

.tab-btn {
  cursor: pointer;
  color: #6d6d6d;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  border: none;
  background-color: #fff;
  padding: 12px 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  outline: none;
}

.active {
  color: #202223;
  box-shadow: inset 0 -3px 0 0 #ffb054;
}

@media only screen and (max-width: 700px) {
  .container {
    padding: 0 15px;
  }
}
</style>
