import axios from "axios";

export default {
  getHeader() {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("auth_waitlist")}`,
    };
  },
  async getEmailTemplate() {
    return axios
      .get("https://waitlist-api.numinix.com/api/preview-template", {
        headers: this.getHeader(),
      })
      .then((res) => res.data);
  },
  async getSuccessEmailTemplate() {
    return axios
      .get("https://waitlist-api.numinix.com/api/preview-sub-template", {
        headers: this.getHeader(),
      })
      .then((res) => res.data);
  },
  async saveEmailTemplate(sentData) {
    return axios
      .post("https://waitlist-api.numinix.com/api/save-template", sentData, {
        headers: this.getHeader(),
      })
      .then((res) => res.data);
  },
  async saveSuccessEmail(sentData) {
    return axios.post(
      "https://waitlist-api.numinix.com/api/save-sub-template",
      sentData,
      { headers: this.getHeader() }
    );
  },
  async sendTestEmail(sentData) {
    return axios
      .post(
        "https://waitlist-api.numinix.com/api/mail-test-template",
        sentData,
        {
          headers: this.getHeader(),
        }
      )
      .then((res) => res.data);
  },
};
