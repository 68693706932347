<template>
  <svg
    width="55"
    height="56"
    viewBox="0 0 55 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="55" height="55" rx="12" fill="#09A59A" />
    <path
      d="M27.3312 21.8799C25.0917 21.8799 23.2695 23.7263 23.2695 25.9957C23.2695 28.265 25.0917 30.1111 27.3312 30.1111C29.5706 30.1111 31.3925 28.265 31.3925 25.9957C31.3925 23.7263 29.5706 21.8799 27.3312 21.8799Z"
      fill="#E3FFF8"
    />
    <path
      d="M31.5585 41.811C31.1982 40.4485 29.7919 39.6673 28.3869 39.777C28.038 39.8043 27.6856 39.8181 27.33 39.8181C19.8089 39.8181 13.6901 33.6168 13.6901 25.9951C13.6901 18.3737 19.8089 12.1734 27.33 12.1734C34.8513 12.1734 40.9711 18.3737 40.9711 25.9951C40.9711 28.1902 40.4636 30.267 39.5611 32.1119C38.7972 33.6733 39.4315 35.7762 41.109 36.2316C42.0736 36.4935 43.111 36.1206 43.5967 35.2472C45.15 32.4538 45.9776 29.2896 45.9776 25.9957C45.9776 20.9484 44.0378 16.2033 40.516 12.6346C36.9939 9.06546 32.3112 7.1001 27.3306 7.1001C22.3496 7.1001 17.667 9.06546 14.1452 12.6346C10.6231 16.2033 8.68359 20.9484 8.68359 25.9957C8.68359 31.0428 10.6231 35.7878 14.1452 39.3569C17.667 42.9257 22.3496 44.8914 27.3306 44.8914C28.1424 44.8914 28.9461 44.8388 29.7382 44.7355C31.1182 44.5555 31.9143 43.1563 31.5585 41.811Z"
      fill="#E3FFF8"
    />
    <path
      d="M38.4897 25.995C38.4897 19.7612 33.4839 14.689 27.3311 14.689C21.1794 14.689 16.1738 19.7612 16.1738 25.995C16.1738 32.2298 21.1794 37.3024 27.3311 37.3024C27.8383 37.3024 28.3379 37.268 28.8275 37.2012C29.6324 37.0914 30.0953 36.2745 29.8876 35.4892C29.5959 34.3857 30.608 33.3802 31.7095 33.6791L33.3895 34.1351C34.2006 34.3553 35.0832 34.1638 35.6401 33.5344C37.4114 31.5323 38.4897 28.8889 38.4897 25.995ZM27.3318 32.6268C23.7233 32.6268 20.7876 29.6519 20.7876 25.9957C20.7876 22.3391 23.7233 19.3642 27.3318 19.3642C30.9402 19.3642 33.8757 22.3391 33.8757 25.9957C33.8757 29.6519 30.9399 32.6268 27.3318 32.6268Z"
      fill="#E3FFF8"
    />
    <path
      d="M37.3592 46.6818C37.847 46.1875 38.6447 46.1875 39.1325 46.6818L41.0171 48.5915C41.8002 49.3851 43.0811 49.3851 43.8642 48.5915L44.6466 47.7987C45.4152 47.0198 45.4152 45.7679 44.6466 44.9891L42.7228 43.0397C42.2324 42.5426 42.2324 41.7437 42.723 41.2468C43.4093 40.5516 43.092 39.3746 42.1492 39.1187L36.0833 37.472C34.5975 37.0686 33.2322 38.425 33.6258 39.9134L35.2684 46.1254C35.5149 47.0578 36.6819 47.3683 37.3592 46.6818Z"
      fill="white"
    />
  </svg>
</template>
